import {
  gql,
  useMutation,
  Reference,
  StoreObject,
} from "@apollo/client";
import { GET_COLLECTIONS } from "./useGetCollections";

interface deleteCollectionInput {
  variables: {
    input: {
      where: {
        id: string;
      };
    };
  };
}

const DELETE_COLLECTION = gql`
  mutation DeleteCollection($input: deleteCollectionInput) {
    deleteCollection(input: $input) {
      collection {
        id
      }
    }
  }
`;

export const useDeleteCollection = (): ((
  deleteCollection: deleteCollectionInput
) => any) => {
  const [deleteCollection, { loading: mutationLoading, error: mutationError }] =
    useMutation(DELETE_COLLECTION, {
      update(cache, { data: { deleteCollection } }) {
        cache.modify({
          fields: {
            collections(existingCollections = [], { readField }) {
              return existingCollections.filter(
                (collection: any) =>
                  deleteCollection.collection.id !== readField("id", collection)
              );
            },
          },
        });
      },
    });
  return deleteCollection;
};

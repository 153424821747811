
import { CONSTANT } from "../../static/constants";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import PropTypes, { InferProps } from "prop-types";

import Modal from "./Modal";
import InputField from "../shared/InputField";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
function EditExhibition(props: InferProps<typeof EditExhibition.propTypes>) {
  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  // validation
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset()
  }, [props.exhibition?.name])

  const UpdateExhibition = async (data: any) => {
    if (isEmpty(errors)) {
      reset();
      let exhibition = {
        id: props.exhibition.id,
        name: data.name,
      };
      props.onCloseEditModal(exhibition);
    }
  };

  const onCancelClick = () => {
    reset();
    props.onCloseEditModal(null);
  };


  return (
    <Modal
      title={CONSTANT.EXHIBITION_EDIT}
      onCancel={onCancelClick}
      formId="edit-exhibition"
      isOpen={props.isOpen}
    >
      <form onSubmit={handleSubmit(UpdateExhibition)} action="#" method="POST" id="edit-exhibition">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-12">
            <InputField
              name={'name'}
              label={CONSTANT.EXHIBITION_NAME}
              placeholder={CONSTANT.EXHIBITION_NAME}
              register={register}
              errors={errors?.name}
              defaultValue={props.exhibition?.name}

            />
          </div>
        </div>
      </form>
    </Modal>
  );
}

EditExhibition.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseEditModal: PropTypes.func.isRequired,
  exhibition: PropTypes.any,
};

export default EditExhibition;

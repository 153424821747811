import React from "react";

interface Props {
  children: any;
}

const MainContainer: React.FC<Props> = (props) => {
  const { children } = props;
  return (
    <main className="mx-auto mb-32 max-w-7xl mt-5 sm:mt-8 md:px-4 lg:px-2 ">
      {children}
    </main>
  );
};

export default MainContainer
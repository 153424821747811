import { gql, useMutation } from "@apollo/client";
import { GET_EXHIBITION_DETAILS } from "./useGetExhibitionDetails";
interface updateExhibitionPairInput {
  variables: {
    input: {
      data: {
        artwork?: string | number | null;
        artworkCollection?: string | number | null;
        frame?: string;
      };
      where: {
        id: string;
      };
    };
  };
}

export const UPDATE_EXHIBITION_PAIR = gql`
  mutation UpdateExhibitionPair($input: updateExhibitionPairInput!) {
    updateExhibitionPair(input: $input) {
      exhibitionPair {
        id
        position
        # artwork{
        #   id
        #   title
        #   thumbImageUrl
        # }
        frame {
          id
          title: name
        }
        # collection{
        #   id
        #   name
        # }
      }
    }
  }
`;

export const useUpdateExhibitionPair = (): ((
  updateExhibitionPair: updateExhibitionPairInput
) => any) => {
  const [updateExhibitionResult] = useMutation(UPDATE_EXHIBITION_PAIR, {
    refetchQueries: [GET_EXHIBITION_DETAILS, "GetExhibitionDetails"],
    // update(cache, { data: { updateExhibitionPair } }) {
    //   cache.modify({
    //     fields: {
    //         exhibitionPairs(existingExhibitions = []) {
    //         return updateExhibitionPair.exhibitionPair;
    //       },
    //     },
    //   });
    // },
  });

  return updateExhibitionResult;
};

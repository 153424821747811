// Get an account option by name. Returns undefinied if not found.
export function getAccountOption(account, optionName) {
    return account?.account_options?.find((o) => o.option?.name === optionName)
}

// Get an option limit for this account
export function getAccountOptionLimit(account, optionName) {
    if (!account) return false
    const accountOption = getAccountOption(account, optionName)
    // if account option isn't found, throw an error
    if (!accountOption) {
        throw new Error('Account option not found')
    }
    // return the account option limit if it exists, otherwise return plan option limit
    return accountOption.limit !== null ? accountOption.limit : accountOption.plan_option?.limit
}

// Get an option consumed amount for this account
export function getAccountOptionConsumed(account, optionName) {
    const accountOption = getAccountOption(account, optionName)
    // if account option isn't found, return null
    if (!accountOption) return null
    // if account option is found and has a limit value, return the limit
    return accountOption.consumed
}

export function getAccountPlan(account) {
    return account?.subscription?.plan?.name
}
import { useState, useEffect } from "react";
import SnackBar from "../components/shared/Snackbar";

import { createContext, Dispatch, SetStateAction } from "react";
import GeneralToast from "../components/shared/GeneralToast";

export type SelectedPlan = {
  currentPeriodEnd: string;
  plan: string;
  planId:string;
  subscriptionStatus: string;
  daysLeft: number;
  subsCancelAtEnd:boolean,

};

interface ToastContextType {
  generalToast: string;
  setGeneralToast: Dispatch<SetStateAction<string>>;
  selectedPlan: SelectedPlan;
  setSelectedPlan: Dispatch<SetStateAction<SelectedPlan>>;
}

const DefaultValue = {
  generalToast: "",
  setGeneralToast: () => {},
  selectedPlan: {
    currentPeriodEnd: "",
    plan: "",
    planId:"",
    subscriptionStatus: "",
    subsCancelAtEnd: false,
    daysLeft: 0,
  },
  setSelectedPlan: () => {},
};

export const generalToastContext =
  createContext<ToastContextType>(DefaultValue);

interface GeneralToastProps {
  children: React.ReactNode;
}

export const GeneralToastContext = ({ children }: GeneralToastProps) => {
  const [generalToast, setGeneralToast] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan>(
    DefaultValue.selectedPlan
  );

  return (
    <generalToastContext.Provider
      value={{ generalToast, setGeneralToast, selectedPlan, setSelectedPlan }}
    >
      {children}
      <GeneralToast />
    </generalToastContext.Provider>
  );
};

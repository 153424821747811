import React from "react";
import { useGetAccount } from "../../hooks/user/useGetAccount";
import {
  getAccountOption,
  getAccountOptionLimit,
  getAccountOptionConsumed,
  getAccountPlan,
} from "../../util/account";
import { calcPercentage } from "../../util/numbers";
import { formatBytes } from "../../util/strings";

interface Props {
  accountId: string;
}

const AccountUsage: React.FC<Props> = ({ accountId }) => {
  const {
    loading: loadingStats,
    error: errorStats,
    account,
    refetch: refetchAccount,
  } = useGetAccount(accountId);

  if (loadingStats) {
    return (
      <div className="max-w-full bg-white p-6 mt-20 rounded overflow-hidden shadow-md">
        Loading...
      </div>
    );
  }
  if (errorStats) {
    return <div>Error: {errorStats.message} </div>;
  }

  const storageLimit = getAccountOptionLimit(account, "storage");
  const storageConsumed = getAccountOptionConsumed(account, "storage");

  const storageLimitFormatted = formatBytes(storageLimit);

  const percent = calcPercentage(storageConsumed, storageLimit);
  const isUserConsumedMore = storageConsumed > storageLimit;
  return (
    <>
      {account && (
        <div className="max-w-full bg-white p-6 mt-20 rounded overflow-hidden shadow-md">
          <div className="">
            <div className="font-semibold text-lg mb-2">Account usage</div>
            <p className="text-gray-700 text-base mb-3">
              Your plan allows up to{" "}
              {storageLimitFormatted} of storage.
            </p>
            {isUserConsumedMore && (
              <p
                className={`  ${
                  !isUserConsumedMore ? "text-gray-700" : "text-[#B94931]"
                }  text-base mb-3`}
              >
                You're {formatBytes(storageConsumed ?? 0 - storageLimit)} over
                the limit. You'll need to delete artworks before uploading new
                ones.
              </p>
            )}
            <div className="flex gap-4">
              <div className="w-1/2 bg-gray-200 h-[30px] flex rounded-md overflow-hidden">
                <div
                  className={`${
                    !isUserConsumedMore ? "bg-indigo-600" : "bg-[#B94931]"
                  } text-indigo-100 text-xs font-medium text-center p-0.5 py-2 leading-none rounded-l-md`}
                  style={{
                    width: `${percent}%`,
                  }}
                >
                  &nbsp;
                  {percent > 10 && !isUserConsumedMore && `${percent}%`}
                </div>
              </div>
              <p
                className={` ${
                  !isUserConsumedMore ? "text-gray-700" : "text-[#B94931]"
                } text-xl mb-3`}
              >
                {storageConsumed &&
                  `${formatBytes(storageConsumed)} / ${storageLimitFormatted}`}
              </p>
            </div>
            {account.framesLimit > 0 && (
              <>
                <p className="text-gray-700 text-base mb-3">
                  Your free account allows up to {account.framesLimit} frames.
                </p>
                <p className="text-gray-700 text-base mb-3">
                  {account.storageUsed &&
                    `${account.framesUsed} of ${account.framesLimit} frames used`}
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AccountUsage;

import React from "react";
import { CONSTANT } from "../../static/constants";
import Button from "../shared/Button";
type Props = {
  onConfirm?: Function;
  onCancel?: Function;
  cancelText?: string;
  confirmText?: string;
  formId?: string;
  isConfirm?: boolean;
  // All other props
  [x: string]: any;
};

const ModalActions: React.FC<Props> = (props) => {
  const {
    isConfirm = false,
    formId = "",
    confirmText = CONSTANT.SAVE,
    cancelText = CONSTANT.CANCEL,
    onCancel = () => {},
    onConfirm,
    ...rest
  } = props;

  return (
    <div
      className="absolute bottom-0 left-0 right-0 bg-gray-50 px-4 py-3 px-6 flex flex-row-reverse"
      {...rest}
    >
      {(onConfirm || formId) && (
        <span className="ml-4">
          {isConfirm ? (
            <Button
              primaryBlack
              type="submit"
              onClick={onConfirm}
              form={formId}
            >
              {confirmText}
            </Button>
          ) : (
            <Button primary type="submit" onClick={onConfirm} form={formId}>
              {confirmText}
            </Button>
          )}
        </span>
      )}
      <span className="">
        <Button secondary onClick={onCancel}>
          {cancelText}
        </Button>
      </span>
    </div>
  );
};

export default ModalActions;

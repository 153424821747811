import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/loader";
import { useLocation } from "react-router-dom";

export default function Payment(): JSX.Element {
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const myParam = params.get("session_id");

  useEffect(() => {
    const handleCreation = async () => {
      try {
        let resp = await fetch(
          `${process.env.REACT_APP_STRAPI_API_URL}/subscriptions/create-from-stripe-session/${myParam}`,
          {
            method: "POST",
            credentials: "include",
          }
        );
        const data = await resp.json();
        if (data.status === "trialing") {
          window.location.replace("/");
        } else {
          setError("There was a problem");
        }
      } catch (e) {
        console.error(e);
        setError("An error occurred. Please try again later.");
      } finally {
        setLoader(false);
      }
    };
    if (myParam) {
      handleCreation();
    }
  }, [myParam]);

  return (
    <>
      {error && <div className="error-box">{error}</div>}
      <Loader isLoading={loader} />
    </>
  );
}

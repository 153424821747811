import React from 'react';
import Spinner from './Spinner';

const baseClassName = 'w-auto inline-flex justify-center items-center rounded-md shadow-sm px-4 py-2 font-semibold';
const primaryClassName = 'bg-indigo-700 hover:bg-indigo-600 text-white';
const primaryBlackClassName = 'bg-gray-700 hover:bg-gray-600 text-white';
const secondaryClassName = 'bg-gray-100 hover:bg-gray-200 text-gray-700 border border-gray-300';
const pushClassName = 'hover:bg-gray-100 text-gray-700 border border-gray-300 px-2 py-2';
const disabledClassName = 'text-gray-300 border border-gray-300 px-2 py-2';

type Props = {
	primary?: boolean;
	primaryBlack?: boolean;
	secondary?: boolean;
	push?: boolean;
	loading?: boolean;
	disabled?: boolean;
	// All other props
	[x: string]: any;
	
};

const Button: React.FC<Props> = (props) => {
	const { primary, primaryBlack, secondary, disabled, push, loading, className = '', children, ...rest } = props;
	let classes = baseClassName;
	if (disabled) {
		classes += ` ${disabledClassName}`;
	} else if (primaryBlack) {
		classes += ` ${primaryBlackClassName}`;
	} else if (secondary) {
		classes += ` ${secondaryClassName}`;
	} else if (push) {
		classes += ` ${pushClassName}`;
	} else {
		// default to primary
		classes += ` ${primaryClassName}`;
	}

	classes += ` ${className}`;
	return (
		<button disabled={disabled} type="button" className={classes} {...rest}>
			{loading ? <Spinner className="w-5 h-5" /> : children}
		</button>
	);
};

export default Button;

export const shortenAddress = (address: string) => {
  if (!address) {
    return "";
  }
  return address.replace(/^(.{2}).*(.{3})$/, "$1...$2");
};

export const shortenUsername = (username: string) => {
  if (!username) {
    return "";
  }
  return username.substr(0, 5) + "...";
};

export const isAddress = (value: string) => {
  if (!value) {
    return false;
  }
  const ethRegex = /^0x[a-fA-F0-9]{40}$/;
  return ethRegex.test(value)
};

export const formatBytes = (bytesIn: any, decimals = 2) => {
  const bytes = parseInt(String(bytesIn), 10);
  if (bytes === 0) return '--';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
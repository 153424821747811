import { XIcon } from "@heroicons/react/solid";
import React, { ReactNode, useState, useCallback } from "react";
import DefaultButton from "./DefaultButton";
import StartBillingDialog from "./StartBillingDialog";

interface TopBarProps {
  daysLeft: number;
  isCancelTopbarType?: boolean;
}

const TopBar: React.FC<TopBarProps> = ({
  daysLeft,
  isCancelTopbarType = false,
}) => {
  const [showBar, setShowBar] = useState<boolean>(true);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => setShowBar(false), []);
  const dialogClose = useCallback(() => setIsDialogOpen(false), []);
  const startPlanClick = useCallback(() => setIsDialogOpen(true), []);
  const reactivatePlanClick = useCallback(() => {
    window.open(process.env.REACT_APP_UPDATE_SUBSCRIPTION_LINK ?? "", "_blank");
  }, []);

  const bannerText = useCallback(
    (element: ReactNode, daysLeft: number) => (
      <span>
        {`You have ${daysLeft} days left on your trial.`} {element}{" "}
        {`to remove the banner on the frames.`}
      </span>
    ),
    []
  );

  const bannerCancelationText = useCallback(
    (element: ReactNode, daysLeft: number) => (
      <span>
        {`Your subscription will end in ${daysLeft} days.`} {element}{" "}
        {`before it expires to continue using Zeroframe.`}
      </span>
    ),
    []
  );

  return (
    <>
      {showBar && (
        <>
          <div
            className={`bg-[${isCancelTopbarType ? "#B94931" : "#4C4CDC"}] z-[999] text-[#fff] leading-none relative top-0 left-0 w-full p-4 sm:p-0 h-18 sm:h-12 flex justify-between items-center`}
          >
            <span className="flex-grow text-center">
              {isCancelTopbarType
                ? bannerCancelationText(
                    <DefaultButton
                      onClick={reactivatePlanClick}
                      isLink={true}
                      text="Reactivate your plan"
                    />,
                    daysLeft
                  )
                : bannerText(
                    <DefaultButton
                      onClick={startPlanClick}
                      isLink={true}
                      text="Start your plan"
                    />,
                    daysLeft
                  )}
            </span>
            <XIcon
              onClick={handleClose}
              className="h-8 w-8 sm:h-6 sm:w-6 text-white   cursor-pointer pr-2"
            />
          </div>
          <StartBillingDialog isOpen={isDialogOpen} onClose={dialogClose} />
        </>
      )}
    </>
  );
};

export default TopBar;

import { ExclamationCircleIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import PropTypes, { InferProps } from "prop-types";
function InputField({
  defaultValue,
  errors,
  placeholder,
  register,
  name,
  label,
  suffix,
  maxLength = "none",
  type = "text",
  registerOptions = {},
  disabled = false,
  className = undefined,
}: InferProps<typeof InputField.propTypes>) {
  return (
    <div>
      <label
        htmlFor={name || ""}
        className="block text-sm font-bold text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 relative">
        <div className="block w-full">
          <input
            id={name}
            {...register(name, registerOptions)}
            placeholder={placeholder}
            type={type}
            defaultValue={defaultValue}
            disabled={disabled}
            maxLength={maxLength}
            className={
              className
                ? className
                : clsx(
                  errors?.message == null
                    ? "focus:ring-primary-500 focus:border-primary-500 border-gray-300 "
                    : " pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ",
                  "shadow-sm text-sm rounded-md",
                  suffix ? 'w-1/2 mr-2 inline' : 'block w-full',
                )
            }
          />
          {suffix && <span className="inline text-gray-500 text-sm">{suffix}</span>}
        </div>
        {errors?.message && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {errors?.message && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errors?.message}
        </p>
      )}
    </div>
  );
}

InputField.propTypes = {
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  errors: PropTypes.any,
  placeholder: PropTypes.string,
  register: () => void {},
  name: PropTypes.string,
  registerOptions: PropTypes.any,
  label: PropTypes.string,
  suffix: PropTypes.string,
  maxLength: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default InputField;

import {gql, useMutation } from "@apollo/client";
import { GET_COLLECTION_DETAILS } from "./useGetCollectionDetails";

interface ArtwroksPosition {
  artworkId: number;
  position: number;
}
interface setCollectionArtworkOrderInput {
  variables: {
    artworks: ArtwroksPosition[];
    collectionId?: number;
  };
}

export const UPDATE_COLLECTION_ARTOWRK = gql`
  mutation SetCollectionArtowrksOrder(
    $collectionId: ID
    $artworks: [ArtwroksPosition]
  ) {
    setCollectionArtworksOrder(
      collectionId: $collectionId
      artworks: $artworks
    ) {
      name
      interval
      artworks {
        id
        position
        artwork {
          creatorAddress
          mediaType
          thumbImageUrl
          display
          id
          title
          creatorUsername
          source
          mimeType
          fileSize
        }
      }
    }
  }
`;

export const useSetCollectionArtworksOrder = (): ((
  createCollectionArtwork: setCollectionArtworkOrderInput
) => any) => {
  const [createCollectionArtwork] = useMutation(UPDATE_COLLECTION_ARTOWRK, {
    refetchQueries: [GET_COLLECTION_DETAILS, "GetCollectionDetails"],
    // update(cache, { data: { createCollection } }) {
    //   cache.modify({
    //     fields: {
    //       collections(existingCollections = []) {
    //         console.log(existingCollections, "eeeeeeeeee");
    //         console.log(createCollection, "ccccccccccc");
    //         const newCollectionRef = cache.writeFragment({
    //           data: createCollection,
    //           fragment: gql`
    //             fragment NewCollection on Collection {
    //               id
    //               interval
    //               name
    //               artworks
    //             }
    //           `,
    //         });
    //         console.log(newCollectionRef, "nnnnnnnnnnnn");
    //         return [...existingCollections, newCollectionRef];
    //       },
    //     },
    //   });
    // },
  });

  return createCollectionArtwork;
};

import { useState, useEffect } from 'react'
import SnackBar from '../components/shared/Snackbar';
import { getNftSyncRequest, getPendingNftSyncRequest, cancelPendingNftSyncRequest, createNftSyncRequest } from '../hooks/artworks/syncNFT';
import { SYNC_NFTS_CONSTANT } from '../static/Enums';
import { useInterval } from '../util/use-interval';
import { nftSyncBackgroundContext } from './nft-sync-background-context';

interface NftSyncBackgroundContextProviderProps {
    children: React.ReactNode;
}

export const NftSyncBackgroundContextProvider = ({ children }: NftSyncBackgroundContextProviderProps) => {
    const [syncLoader, setSyncLoader] = useState<string>('')
    const [nftSyncRequest, setNftSyncRequest] = useState<any>(null)
    const [isPolling, setIsPolling] = useState<boolean>(false)

    // if isPolling is true, then this interval will poll for the status of the sync request
    // TODO: add additional details to the snackbar to show the progress of the sync
    useInterval(async () => {
        try {
            const syncReq = await getNftSyncRequest(nftSyncRequest.id);
            setNftSyncRequest(syncReq);
            if (syncReq.status === 'complete') {
                setIsPolling(false);
                setSyncLoader(SYNC_NFTS_CONSTANT.SYNC_DONE);
            }
            if (syncReq.status === 'cancelled') {
                setIsPolling(false);
                setSyncLoader(SYNC_NFTS_CONSTANT.SYNC_CANCELLED);
            }
        } catch (e) {
            console.error(e);
            setIsPolling(false);
            setSyncLoader(SYNC_NFTS_CONSTANT.SYNC_FAILED);
        }
    }, isPolling ? 4000 : null)

    // on load, check if there is a pending sync request, if so begin polling
	useEffect(() => {
		// get pending nft sync requests
		getPendingNftSyncRequest()
            .then((res) => {
                if (res.length > 0) {
                    setNftSyncRequest(res[0]);
                    setSyncLoader(SYNC_NFTS_CONSTANT.SYNC_PENDING)
                    setIsPolling(true);
                }
            })
            .catch((err) => {
                console.log(err)
            })
	}, []);

    // Initiate a new sync request
    const triggerSync = async () => {
        setSyncLoader(SYNC_NFTS_CONSTANT.SYNC_PENDING)
        let syncReq = await createNftSyncRequest();
        setNftSyncRequest(syncReq);
        setIsPolling(true);
    }

    // Cancel a pending sync request
    const cancelSync = async () => {
        console.log('cancelSync', nftSyncRequest?.id)
        if (nftSyncRequest?.id) {
            try {
                cancelPendingNftSyncRequest(nftSyncRequest.id)
                setSyncLoader(SYNC_NFTS_CONSTANT.SYNC_CANCELLED)
            } catch (e) {
                console.error(e)
            }
        }
    }

    return (
        <nftSyncBackgroundContext.Provider value={{ syncLoader, setSyncLoader, triggerSync, cancelSync, nftSyncRequest }}>
            {children}
            <SnackBar />
        </nftSyncBackgroundContext.Provider>
    )
}
import { gql, useMutation } from "@apollo/client";
import { GET_EXHIBITION_PIAR } from "./useGetExhibitionPair";
import { GET_EXHIBITION_DETAILS } from "./useGetExhibitionDetails";

interface createExhibitionPairInput {
  variables: {
    input: {
      data: {
        position?: number;
        artwork?: number | null;
        artworkCollection?: number | null;
        frame?: number | null;
        exhibition?: number | null;
      };
    };
  };
}

export const CREATE_PAIR = gql`
  mutation CreatePair($input: createExhibitionPairInput!) {
    createExhibitionPair(input: $input) {
      exhibitionPair {
        id
        position
        artwork {
          id
          title
        }
        artworkCollection {
          id
          name
        }
        frame {
          id
          name
        }
      }
    }
  }
`;

export const useCreatePair = (): ((
  createPair: createExhibitionPairInput
) => any) => {
  const [creatExhibitionResult] = useMutation(CREATE_PAIR, {
    // refetchQueries: [GET_EXHIBITION_PIAR, "GetExhibitionPair"],
    refetchQueries: [GET_EXHIBITION_DETAILS, "GetExhibitionDetails"],
  });

  return creatExhibitionResult;
};

import { useQuery, gql } from "@apollo/client";

export const GET_ACCOUNT = gql`
  query GetAccount($id: ID!) {
    account(id: $id) {
      id
      test
      subscription {
        id
        plan {
          id
          name
        }
      }
      account_options {
        id
        limit
        consumed
        plan_option {
          id
          limit
        }
        option {
          id
          name
        }
      }
    }
  }
`;

export const useGetAccount = (id: any) => {
  const { loading, error, data, refetch } = useQuery(GET_ACCOUNT, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });
  return {
    loading,
    error,
    account: data?.account,
    refetch,
  };
};

import { gql, useMutation } from "@apollo/client";

interface updateFrameInput {
  variables: {
    input: {
      data: {
        pairingCode: string;
      };
      where: {
        id: string;
      };
    };
  };
}

export const PAIR_FRAME = gql`
  mutation UpdateFrame($input: updateFrameInput!) {
    updateFrame(input: $input) {
      frame {
        id
        pairingCode
        artwork {
          id
          thumbImageUrl
        }
        optimizeMedia
        artworkCollection {
          id
          artworks {
            artwork {
              id
              thumbImageUrl
            }
          }
        }
      }
    }
  }
`;

export const usePairFrame = (): ((updateFrame: updateFrameInput) => any) => {
  const [updateFrameResult, { error }] = useMutation(PAIR_FRAME, {
    update(cache, { data: { updateFrame } }) {
      cache.modify({
        fields: {
          frames(existingFrames = []) {
            return updateFrame.frame;
            //   const newFrameRef = cache.writeFragment({
            //     data: updateFrame,
            //     fragment: gql`
            //       fragment UpdateFrame on Frames {
            //         id
            //         interval
            //       }
            //     `,
            //   });
            //   return [...existingFrames, newFrameRef];
          },
        },
      });
    },
  });
  return updateFrameResult;
};

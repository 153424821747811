
export async function post(url, data) {

    const res = await fetch(`${process.env.REACT_APP_STRAPI_API_URL}/${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: "include",
    })
  
    const json = await res.json()
  
    if (json.errors) {
      console.error(json.errors)
      throw new Error('Failed to fetch API')
    }
  
    return json;
  }
  
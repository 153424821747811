import PropTypes, { InferProps } from "prop-types";
function Alerts({
    className,
    icon,
    text = ''
}: InferProps<typeof Alerts.propTypes>) {
    return (
        <div className={`my-4 px-4 py-3 border-0 rounded flex relative mb-4 ${className }`}>
            <span className="text-xl inline-block float-left mr-5 align-middle">
                {icon}
            </span> 
            <span className="inline-block align-middle mr-4">
                {text}
            </span>
        </div>
    );
}
Alerts.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.element,
    text: PropTypes.string,

};

export default Alerts;

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useState } from "react";
import PropTypes, { InferProps } from "prop-types";

function FrameDropDown(props: InferProps<typeof FrameDropDown.propType>) {
  const defaultOption = props?.default && props.options.find(option => option.value === props.default);
  const [selected, setSeleted] = useState(defaultOption?.title);

  useEffect(() => {
    setSeleted(defaultOption?.title);
  }, [defaultOption]);

  useEffect(() => {
    if (props.type === "rotation") {
      props.options.map((option) => {
        if (option.value === props.default) {
          setSeleted(option.title);
        }
      });
    }
  }, []);
  return (
    <Menu as="div" className="inline-block text-left">
      <div>
        <Menu.Button
          className={
            props.width +
            " inline-flex justify-between rounded-md border border-gray-300 shadow-sm px-4 pr-1 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          }
        >
          {selected ? selected : "Select"}
          <ChevronDownIcon className=" ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <div className="absolute right-16 md:right-auto  z-50">
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right z-10 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="max-h-60 overflow-scroll">
              {props.options.map((option: any, i) => (
                <Menu.Item key={i}>
                  <a
                    onClick={() => {
                      setSeleted(option.title);
                      props.onSelect(option.value);
                    }}
                    className="block px-4 py-2 text-sm cursor-pointer hover:bg-table-header-bg"
                  >
                    {option.title}
                  </a>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  );
}

FrameDropDown.propType = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  default: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
};

export default FrameDropDown;

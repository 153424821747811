import { useEffect, useState } from "react";
import { useAuth } from "../../authn/authn";
import { useGetAccount } from "../user/useGetAccount";

const useAccountLimitation = (counter = -1) => {
  const authn = useAuth();
  const [frameLimit, setFrameLimit] = useState(0);
  const [frameConsumed, setFrameConsumed] = useState(0);
  const [storageLimit, setStorageLimit] = useState(0);
  const [storageConsumed, setStorageConsumed] = useState(0);
  const { account, refetch } = useGetAccount(authn.getAccountId());
  // set the frame and storage limitation based on plan type
  useEffect(() => {
    if (account?.account_options) {
      const accountOption = account?.account_options;
      const frameOption = accountOption.find(
        (item: any) => item?.option?.name === "frames"
      );
      const storageOption = accountOption.find(
        (item: any) => item?.option?.name === "storage"
      );
      setFrameLimit(frameOption.limit ?? frameOption?.plan_option.limit);
      setFrameConsumed(frameOption.consumed ?? 0);
      setStorageLimit(storageOption.limit ?? storageOption?.plan_option.limit);
      setStorageConsumed(storageOption.consumed ?? 0);
    }
  }, [account]);

  useEffect(() => {   
    if (counter >= 0) {
      refetch();
    }
  }, [counter]);

  return {
    frameLimit,
    storageLimit,
    storageConsumed,
    frameConsumed,
  };
};

export default useAccountLimitation;

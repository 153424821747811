import PropTypes, { InferProps } from "prop-types";

function Image({
    title,
    src,
    className
}: InferProps<typeof Image.propTypes>) {
    return (
        <img
            src={src || ''}
            alt={title || ''}
            className={`${className} `}
        />
    );
}

Image.propTypes = {
    title: PropTypes.string,
    src: PropTypes.string,
    className: PropTypes.string
};

export default Image;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InferProps } from 'prop-types';
import { useAuth } from '../../authn/authn';
import Loader from '../../components/shared/loader';

import analytics from '../../services/analytics'

function Logout(props: InferProps<typeof Logout.propTypes>) {
  let auth = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    async function logout() {
      await auth.signout();
      analytics.track('user: signed out')
      analytics.flush()
      analytics.reset()
      window.location.href = "/";
    }
    logout()
  }, []);

  return <Loader isLoading/>;
}

Logout.propTypes = {};

export default Logout;

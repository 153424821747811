import {
  DropdownOption,
  PlaqueDropdownOption,
} from "../components/model/DropdownOption";

export const display: DropdownOption[] = [
  { id: 1, title: "fit" },
  { id: 2, title: "fill" },
];

export const transition: PlaqueDropdownOption[] = [
  { id: 1, title: "Fade to black", value: "fadeoutin" },
  { id: 2, title: "Crossfade", value: "crossfade" },
];

export const plaqueDisplay: PlaqueDropdownOption[] = [
  { id: 1, title: "None", value: "none" },
  { id: 2, title: "Art info", value: "info" },
  { id: 3, title: "Art info & QR", value: "info_qr" },
  { id: 4, title: "QR only", value: "qr_only" },
];

export const plaqueSizes: PlaqueDropdownOption[] = [
  { id: 1, title: "Small", value: "sm" },
  { id: 2, title: "Medium", value: "md" },
  { id: 3, title: "Large", value: "lg" },
];

export const artworkCollection: DropdownOption[] = [
  { id: 1, title: "Artwork" },
  { id: 2, title: "Collection" },
];

export const rotationDisplay: PlaqueDropdownOption[] = [
  { id: 1, title: "0\u00B0", value: 0 },
  { id: 2, title: "90\u00B0", value: 90 },
  { id: 3, title: "180\u00B0", value: 180 },
  { id: 4, title: "270\u00B0", value: 270 },
];

export const SYNC_NFTS_CONSTANT = {
  SYNC_DEFAULT: "default",
  SYNC_PENDING: "pending",
  SYNC_DONE: "complete",
  SYNC_FAILED: "failed",
  SYNC_CANCELLED: "cancelled",
};

export const GENERAL_TOAST_CONSTANT = {
  DEFAULT: "default",
  DOWNGRADING: "downgrad",
  CANCELLING: "cancelling",
  UPGRADE: "upgrade",
  SUCCESS: "success",
  WELCOME: "welcome",
  TRAIL_END: "tailEnd",
};

interface Frequencies {
  label: string;
  priceSuffix: string;
  value: string;
}

export interface Feature {
  isNotAvailable?: boolean;
  isInfoAvailable?: boolean;
  description: string;
}

interface price{
  priceLabel:string;
  priceId:string
}
export interface PricingTier {
  name: string;
  id: string;
  href: string;
  price: { monthly: price; annually: price };
  description: string;
  features: Feature[];
  mostPopular: boolean;
  paymentLink?: string;
  numberOfFrame: number;
  display?: boolean;
}
interface Pricing {
  tiers: PricingTier[];
  frequencies: { [key: string]: Frequencies };
}
const envData = process.env;

export const pricing: Pricing = {
  frequencies: {
    monthly: { value: "monthly", label: "Monthly", priceSuffix: "/month" },
    annually: { value: "annually", label: "Annually", priceSuffix: "/year" },
  },
  tiers: [
    {
      name: "Collector",
      id: envData?.REACT_APP_COLLECTOR_PRD_ID ?? "",
      href: "#",
      price: {
        monthly: {
          priceLabel: "$9",
          priceId: envData?.REACT_APP_COLLECTOR_MONTHLY_PRICE_ID ?? "",
        },
        annually: {
          priceLabel: "$9",
          priceId: envData?.REACT_APP_COLLECTOR_ANNUAL_PRICE_ID ?? "",
        },
      },
      description: "The essentials for collectors to display their collection.",
      features: [
        { description: "1 frame" },
        { description: "500Mb of storage" },
        { description: "Create collections" },
        // { description: "Connect your wallet" },
        {
          isNotAvailable: true,
          isInfoAvailable: true,
          description: "Non-commercial use",
        },
      ],
      mostPopular: false,
      display: true,
      numberOfFrame: 1,
      paymentLink: envData?.REACT_APP_COLLECTOR_PAYMENT_LINK,
    },
    {
      name: "Showcase",
      id: envData?.REACT_APP_SHOWCASE_PRD_ID ?? "",
      href: "#",
      price:  {
        monthly: {
          priceLabel: "$99",
          priceId: envData?.REACT_APP_SHOWCASE_MONTHLY_PRICE_ID ?? "",
        },
        annually: {
          priceLabel: "",
          priceId: envData?.REACT_APP_SHOWCASE_ANNUAL_PRICE_ID ?? "",
        }
      },
      description: "Manage artwork on multiple displays.",
      features: [
        { description: "Up to 10 frames" },
        { description: "5Gb of storage" },
        { description: "Create collections" },
        { description: "Display QR codes" },
        {
          isNotAvailable: true,
          isInfoAvailable: true,
          description: "Non-commercial use",
        },
      ],
      mostPopular: false,
      numberOfFrame: 10,
      display: true,
      paymentLink: envData?.REACT_APP_SHOWCASE_PAYMENT_LINK,
    },
    {
      name: "Gallery",
      id: envData?.REACT_APP_GALLERY_PRD_ID ?? "",
      href: "#",
      price:  {
        monthly: {
          priceLabel: "$499",
          priceId: envData?.REACT_APP_GALLERY_MONTHLY_PRICE_ID ?? "",
        },
        annually: {
          priceLabel: "",
          priceId: envData?.REACT_APP_GALLERY_ANNUAL_PRICE_ID ?? "",
        },
      },
      description:
        "All the tools a gallery needs to manage fleets of displays.",
      features: [
        { description: "Up to 50 frames" },
        { description: "10Gb of storage" },
        { description: "Create collections" },
        { description: "Display QR codes" },
        { description: "Curate exhibitions" },
        { isInfoAvailable: true, description: "Commercial use" },
      ],
      mostPopular: true,
      numberOfFrame: 50,
      display: true,
      paymentLink: envData?.REACT_APP_GALLERY_PAYMENT_LINK,
    },
    {
      name: "Custom",
      id: "custom",
      href: "#",
      price: {
        monthly: {
          priceLabel: "",
          priceId: "",
        },
        annually: {
          priceLabel: "",
          priceId: "",
        }
      },
      description: "Need a custom solution? Drop us a line",
      features: [
        { description: "White-labeling" },
        { description: "Custom integrations" },
        { description: "Advanced analytics" },
        { description: "Dedicated support" },
      ],
      mostPopular: false,
      numberOfFrame: 9999,
      paymentLink: envData?.REACT_APP_GALLERY_PAYMENT_LINK,
      display: false,
    },
  ],
};

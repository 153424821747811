import { ArrowSmUpIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, memo } from "react";
import clsx from "clsx";

import Dropdown from "../../components/shared/Dropdown";
import { DropdownOption } from "../../components/model/DropdownOption";
import { Wallet } from "../../components/model/Wallet";
import { shortenAddress } from "../../util/strings";
import { CONSTANT } from "../../static/constants";
import { display } from "../../static/Enums";
import Spinner from "../../components/shared/Spinner";
interface Props {
  data: Wallet;
  // pushWalletToFrame: (artworkId: string, artworkUrl: string) => () => void;
  // updateDisplay: (id: string) => (selected: DropdownOption) => void;
  onOpenDeleteModal: (id: string) => () => void;
  walletLoading: boolean;
}

const WalletsTableRow: React.FC<Props> = (props) => {
  const { data, onOpenDeleteModal } = props;

  return (
    <tr className="w-full h-16">
      <td className="px-6  ">
        <div className="text-sm text-table-cell-color ">
          {data?.publicAddress}
        </div>
      </td>
      <td className="pl-6 pr-6">
        <div className="text-sm text-primary font-semibold ">{data?.blockchain}</div>
      </td>
      <td className="">
        <div className="text-sm  text-active-button">
          <button onClick={onOpenDeleteModal(data?.id)}>Remove</button>
        </div>
      </td>
    </tr>
  );
};

export default memo(WalletsTableRow);

import React, { useState } from "react";
import Button from "../../components/shared/Button";

const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;

interface Props {}

const SuperAdminFeatures: React.FC<Props> = () => {
  const [userMaskEmail, setUserMaskEmail] = useState("");
  const onUserMaskSubmit = async () => {
    await fetch(`${strapiUrl}/authn/user-mask`, {
      method: "POST",
      body: JSON.stringify({ email: userMaskEmail }),
      headers: {
        "Content-Type": "application/json",
      },
      // requied in order to store/send auth cookie
      credentials: "include",
    }).then((response) => response.json());
    window.location.reload();
  };
  return (
    <div className="max-w-full bg-white p-6 mt-20 rounded overflow-hidden shadow-md">
      <div className="">
        <div className="font-semibold text-lg mb-2">Super Admin Features</div>
        <div className="grid gap-4 grid-cols-6 content-end">
          <div className="col-span-3">
            <label
              htmlFor="name"
              className="block text-sm font-bold text-gray-700"
            >
              User Mask Email
            </label>
            <input
              id="userMaskEmail"
              type="email"
              required
              onChange={(e) => setUserMaskEmail(e.target.value)}
              className="mr-2 py-2 shadow-sm rounded-md"
            />
            <Button secondary type="button" onClick={onUserMaskSubmit}>
              Submit
            </Button>
          </div>
          <div className="col-span-3"></div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminFeatures;

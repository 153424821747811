import { useContext, useState } from "react";
import { post } from "../../util/http-api";
import { generalToastContext } from "../../contexts/general-toast-context-provider";
import { GENERAL_TOAST_CONSTANT } from "../../static/Enums";
import { getDateDifferenceIndays, getFormattedDate } from "../../util/date";

interface SubscriptionProps {
  onClose: () => void;
}
const useSubscription = ({ onClose }: SubscriptionProps) => {
  const { setGeneralToast, setSelectedPlan } = useContext(generalToastContext);

  const [loader, setLoader] = useState<boolean>(false);

  const startBillingCycle = async () => {
    setLoader(true);
    try {
      const res = await post("subscriptions/start-billing-cycle");
      setLoader(false);
      const { stripeCurrentPeriodEnd, plan, status, stripeCancelAtPeriodEnd } =
        res ?? {
          stripeCurrentPeriodEnd: "",
        };
      if (stripeCurrentPeriodEnd) {
        const periodEndDate = new Date(stripeCurrentPeriodEnd);
        const daysLeft = getDateDifferenceIndays(periodEndDate);
        setSelectedPlan({
          currentPeriodEnd: getFormattedDate(periodEndDate),
          plan: plan?.name,
          planId: plan?.stripeProductId,
          subscriptionStatus: status,
          daysLeft,
          subsCancelAtEnd: stripeCancelAtPeriodEnd,
        });
      }
      setGeneralToast(GENERAL_TOAST_CONSTANT.TRAIL_END);
      onClose();
    } catch (e: any) {
      console.error(e, " Problem with start billing cycle.");
      setLoader(false);
    }
  };

  return {
    startBillingCycle,
    loader,
  };
};

export default useSubscription;

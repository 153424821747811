import React from "react";

interface Props {
  title: string;
  subtitle?: string | React.ReactNode;
}

const TableEmptyState: React.FC<Props> = (props) => {
  const { title, subtitle } = props;
  return (
    <div className="rounded-md overflow-hidden">
      <div className="bg-white py-28 px-12 text-center">
        <p className="font-bold pb-4">{title}</p>
        { subtitle &&<p>{subtitle}</p>}
      </div>
    </div>
  );
};

export default TableEmptyState;

import PropTypes, { InferProps } from "prop-types";
import Spinner from "./Spinner";
import { useState } from "react";

function KioskModeArtworkSearch({
  searchHandler,
  searchLoader,
}: InferProps<typeof KioskModeArtworkSearch.propTypes>) {
  const [query, setQuery] = useState("");

  const handleSearch = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    searchHandler(value);
    setQuery(value);
  };

  const handleClear = () => {
    setQuery("");
    searchHandler("");
  };

  return (
    <div className="w-full rounded-full py-3">
      <form>
        <div className=" rounded-full relative">
          <input
            name="search"
            type="text"
            onChange={handleSearch}
            value={query}
            className="w-full p-6 kiosk-search-input border kiosk-font-xl border-gray-200 bg-white text-black rounded-full font-weight-700"
            placeholder="Search by artist or title"
          />
          <div className="right-0 top-0 h-full flex items-center p-6 absolute">
            {query !== "" && !searchLoader && (
              <svg
                onClick={handleClear}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-gray-600 cursor-pointer "
              >
                <path
                  fillRule="evenodd"
                  d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {searchLoader && (
              <div className="w-6 h-6">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

KioskModeArtworkSearch.propTypes = {
  searchHandler: () => void {},
  searchLoader: PropTypes.bool,
};

export default KioskModeArtworkSearch;

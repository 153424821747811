import React from "react";

type Props = {
  primary?: boolean;
  secondary?: boolean;
  // All other props
  [x: string]: any;
};

const TableTitleBar: React.FC<Props> = (props) => {
  return (
    <div {...props} className="items-end md:justify-between sm:justify-items-stretch w-full">
      {props.children}
    </div>
  );
};

export default TableTitleBar;

import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { CONSTANT } from "../../static/constants";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import PropTypes, { InferProps } from "prop-types";
import { Exhibition } from "../model/Exhibition";

import Button from "../shared/Button";
import InputField from "../shared/InputField";
import isEmpty from "lodash/isEmpty";


function AddExhibition(props: InferProps<typeof AddExhibition.propTypes>) {
  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  // validation
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const createNewExhibition = async (data: any) => {
    if (isEmpty(errors)) {
      reset();
      props.onCloseModal(data.name);
    }
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join("");
  };

  const onCancelClick = () => {
    reset();
    props.onCloseModal(null)
  }

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={props.onCloseModal}
      >
        <div className="flex  items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl text-center leading-6 font font-semibold  text-[#121826]"
                    >
                      {CONSTANT.CREATE_EXHIBITION}
                    </Dialog.Title>
                    <div className="mt-2">
                      <form
                        onSubmit={handleSubmit(createNewExhibition)}
                        action="#"
                        method="POST"
                      >
                        <div className="sm:rounded-md">
                          <div className="px-4 py-5 mb-28 bg-white sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                              <div className="col-span-12 ">
                                {/* <label
                                  htmlFor="name"
                                  className="block text-sm font text-left leading-6 font font-semibold  text-[#42454F]"
                                >
                                  {CONSTANT.EXHIBITION_NAME}
                                </label>
                                <input
                                  type="text"
                                  id="name"
                                  autoComplete="name"
                                  {...register("name")}
                                  className={classNames(
                                    errors.name?.message == null
                                      ? "focus:ring-primary-500 focus:border-primary-500  border-gray-300 "
                                      : "focus:ring-secondary-500 focus:border-secondary-500  border-secondary-300 ",
                                    "mt-1 block w-full shadow-sm sm:text-sm font rounded-md text-base text-[#42454F] font-normal"
                                  )}
                                />
                                <p className="text-red-500 font font-medium">
                                  {errors.name?.message}
                                </p> */}


                                <InputField
                                  name={'name'}
                                  label={CONSTANT.EXHIBITION_NAME}
                                  placeholder={CONSTANT.EXHIBITION_NAME}
                                  register={register}
                                  errors={errors?.name}


                                />

                              </div>
                            </div>
                          </div>
                          <div className="absolute bottom-0 left-0 right-0 bg-gray-50 px-6 py-3 sm:px-6 flex flex-row-reverse">
                            <button
                           
                              type="submit"
                              className="w-full inline-flex justify-center rounded-md border border-[#4C4CDC] shadow-sm px-4 py-2 bg-[#4C4CDC] hover:bg-white hover:text-[#4C4CDC] text-base font font-bold text-white ml-3 w-auto text-sm"
                            >
                              {CONSTANT.CREATE}
                            </button>
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-[#D2D5DA] border-transparent shadow-sm px-4 py-2 bg-white text-base font font-bold text-[#394150] hover:border-[#4C4CDC] hover:text-[#4C4CDC]  ml-3 w-auto text-sm"
                              onClick={() => onCancelClick()}
                            >
                              {CONSTANT.CANCEL}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

AddExhibition.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
};

export default AddExhibition;

import classNames from "classnames";
import React, { Fragment, } from "react";
import { Frame } from "../model/Frame";

type Props = {
    clickHundler: (id: number) => void;
    data: Frame
};

interface Artwork {
    title: string;
    creatorUsername: string;
}

const text = {
    className: 'capitalize text-xs sm:text-sm text-gray-500 font-normal',
    artworkTitle: (artwork: Artwork) => `${artwork.title}  ${artwork?.creatorUsername !== '-' ? ' - ' + artwork?.creatorUsername : ''}`
}

const ArtOrCollTitle: React.FC<Props> = ({ data, clickHundler }) => {
    return <Fragment>
        {
            data.artworkCollection &&
            <span className={classNames(text.className, 'cursor-pointer')}
                onClick={() => {
                    clickHundler(data.artworkCollection.id)
                }}>{data.artworkCollection.name}</span>
        }
        {
            data.artwork &&
            <span className={text.className}>{text.artworkTitle(data.artwork)}</span>
        }
    </Fragment>
};

export default ArtOrCollTitle;

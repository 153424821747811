import React, {
  createContext,
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";

import analytics from "../../services/analytics";

import Layout from "../layout/layout";
import Artworks from "../../pages/artworks/artworks";
import Login from "../../pages/login/login";
import Logout from "../../pages/logout/logout";
import ArtworkDetail from "../../pages/artworks/artwork-detail";
import Collections from "../../pages/collections/collections";
import Frames from "../../pages/frames/frames";
import Exhibitions from "../../pages/exhibitions/exhibitions";
import NotFound from "../../pages/errors/not-found";
import Maintenance from "../../pages/errors/maintenance";
// Authentication
import {
  AuthProvider,
  RequireAuthn,
  AuthorizedRoute,
  RequireSubscription,
  UnSubscribedPages,
  CheckKioskMode,
  CheckUnKioskMode,
} from "../../authn/authn";

import client from "../../services/apollo-client";

import "./app.css";
import Account from "../../pages/account/account";
import CollectionDetails from "../../pages/collections/collection-details";
import ExhibitionDetails from "../../pages/exhibitions/exhibition-details";
import { NftSyncBackgroundContextProvider } from "../../contexts/nft-sync-background-context-provider";
import Pricing from "../../pages/pricing/Pricing";
import { GeneralToastContext } from "../../contexts/general-toast-context-provider";
import Payment from "../../pages/payment/Payment";
import KioskMode from "../../pages/artworks/kioskMode/kioskMode";
import PaymentCancel from "../../pages/payment/PaymentCancel";

analytics.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");

function App() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const debug = urlParams.get("debug");
    if (debug === "1") {
      localStorage.setItem("zf_debug", "1");
    } else if (debug === "0") {
      localStorage.removeItem("zf_debug");
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <NftSyncBackgroundContextProvider>
          <GeneralToastContext>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <RequireAuthn>
                      <CheckKioskMode>
                        <RequireSubscription>
                          <Layout />
                        </RequireSubscription>
                      </CheckKioskMode>
                    </RequireAuthn>
                  }
                >
                  <Route path="/" element={<Artworks />} />
                  {/* <Route path="artworks" element={<Artworks />} /> */}
                  <Route
                    path="artworks/:artworkId"
                    element={<ArtworkDetail />}
                  />
                  <Route path="collections" element={<Collections />} />
                  <Route
                    path="collections/:id"
                    element={<CollectionDetails />}
                  />

                  <Route path="frames" element={<Frames />} />
                  <Route
                    path="exhibitions"
                    element={
                      <AuthorizedRoute enabledOption="exhibitions">
                        <Exhibitions />
                      </AuthorizedRoute>
                    }
                  />

                  <Route
                    path="exhibitions/:id"
                    element={
                      <AuthorizedRoute enabledOption="exhibitions">
                        <ExhibitionDetails />
                      </AuthorizedRoute>
                    }
                  />
                  <Route path="account" element={<Account />} />
                </Route>

                <Route
                  path="/pricing"
                  element={
                    <RequireAuthn>
                      <UnSubscribedPages>
                        <Pricing />
                      </UnSubscribedPages>
                    </RequireAuthn>
                  }
                />

                <Route
                  path="/kiosk-mode"
                  element={
                    <RequireAuthn>
                      <CheckUnKioskMode>
                        <KioskMode />
                      </CheckUnKioskMode>
                    </RequireAuthn>
                  }
                />

                <Route path="/login" element={<Login />} />
                <Route path="/payment/success" element={<Payment />} />
                <Route path="/payment/cancel" element={<PaymentCancel />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/maintenance" element={<Maintenance />} />

                <Route path="*" element={<NotFound />} />
              </Routes>
              <ToastContainer />
            </BrowserRouter>
          </GeneralToastContext>
        </NftSyncBackgroundContextProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;

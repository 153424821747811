import { SelectorIcon } from "@heroicons/react/outline";
import React, { memo } from "react";
import clsx from "clsx";

import { CONSTANT } from "../../static/constants";

interface Props {
  onSortColumn: (key: string) => () => void;
  sortedColumn: [string, "asc" | "desc"];
}

const ExibitionPickereHead: React.FC<Props> = ({ onSortColumn, sortedColumn }) => {
  return (
    <thead className="bg-table-header-bg text-table-header-fg text-left text-sm uppercase tracking-wider">
      <tr className="w-full">
        {[
          { label: "", sortable: false, key: "" },
          { label: "", sortable: false, key: "" },
          { label: CONSTANT.TITLE, sortable: true, key: "title" },
          { label: CONSTANT.ARTWORKS, sortable: true, key: "creatorUsername" },
        ].map(({ label, sortable, key }, index) => {
          const [column] = sortedColumn;
          const isActive = column === key;

          return (
            <th
              className="py-3 font font-semibold"
              scope="col"
              key={index}
            >
              {sortable ? (
                <button
                  onClick={onSortColumn(key)}
                  className={clsx(
                    "bg-grey-light hover:bg-grey  hover:text-black rounded inline-flex items-start uppercase font-weight-700 font",
                    isActive ? "text-black" : " text-grey-darkest "
                  )}
                >
                  <span className="font-semibold">{label}</span>
                  <SelectorIcon height="16" className="inline"></SelectorIcon>
                </button>
              ) : (
                label
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default memo(ExibitionPickereHead);

import { gql, useMutation } from "@apollo/client";

interface updateArtworkInput {
  variables: {
    input: {
      data: {
        title?: string
        display?: string;
        thumbImageUrl?: string;
        originalImageUrl?: string;
        source?: string;
        mediaType?: string;
        bgColor?: string;
        qrCodeUrl?:string;
        creatorUsername?: string,
        artworkUrl?: string,
        aspectRatio?: string,
        ext?: string,
        fileSize?: string,
        height?: string,
        mediaUrl_720?: string,
        mediaUrl_1080?: string,
        mediaUrl_4k?: string,
        mediaUrl_original?: string,
        width?: string,
        mimeType?: string,
        assetProviderId?: string,
      };
      where: {
        id: string;
      };
    };
  };
}

export const UPDATE_ARTWORK = gql`
  mutation UpdateArtwork($input: updateArtworkInput!) {
    updateArtwork(input: $input) {
      artwork {
        id
        display
      }
    }
  }
`;

export const useUpdateArtwork = (): ((
  updateArtwork: updateArtworkInput
) => any) => {
  const [updateArtwork] = useMutation(UPDATE_ARTWORK, {
    update(cache, { data: { updateArtwork } }) {
      cache.modify({
        fields: {
          artworks(existingArtworks = []) {
            return updateArtwork.artwork;
            // const newArtworkRef = cache.writeFragment({
            //   data: updateArtwork,
            //   fragment: gql`
            //     fragment UpdateArtwork on Artwork {
            //       id
            //       display
            //     }
            //   `,
            // });
            // return [...existingArtworks, newArtworkRef];
          },
        },
      });
    },
  });

  return updateArtwork;
};

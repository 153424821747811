import { useQuery, gql, useMutation } from "@apollo/client";
import { GET_FRAMES } from "./useGetFrames";
interface updateFrameInput {
  variables: {
    input: {
      data: {
        artwork?: string | number | null;
        artworkCollection?: string | number | null;
        name?: string;
        rotation?: number;
        optimizeMedia?: boolean;
        plaque?:string;
        plaqueSize?:string;
      };
      where: {
        id: string;
      };
    };
  };
}

export const UPDATE_FRAME = gql`
  mutation UpdateFrame($input: updateFrameInput!) {
    updateFrame(input: $input) {
      frame {
        id
        name
        plaque
        plaqueSize
        rotation
        artwork {
          id
          thumbImageUrl
        }
        optimizeMedia
        artworkCollection {
          id
          artworks {
            artwork {
              id
              thumbImageUrl
            }
          }
        }
      }
    }
  }
`;

export const useUpdateFrame = (): ((updateFrame: updateFrameInput) => any) => {
  const [updateFrameResult] = useMutation(UPDATE_FRAME, {
    // refetchQueries: [GET_FRAMES, "GetFrames"],
    update(cache, { data: { updateFrame } }) {
      cache.modify({
        fields: {
          frames(existingFrames = []) {
            return updateFrame.frame;
            //   const newFrameRef = cache.writeFragment({
            //     data: updateFrame,
            //     fragment: gql`
            //       fragment UpdateFrame on Frames {
            //         id
            //         interval
            //       }
            //     `,
            //   });
            //   return [...existingFrames, newFrameRef];
          },
        },
      });
    },
  });

  return updateFrameResult;
};

import React from "react";
import DefaultDialog from "./DefaultDialog";
import DefaultButton from "./DefaultButton";
import useSubscription from "../../hooks/subscription/subscription";
import Loader from "./loader";

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
}
const texts = {
  dialogTitle: "Start your plan",
  dialogDescription1: "We're glad you're enjoying Zeroframe!",
  dialogDescription2:
    "Start your billing cycle now to remove the banner in your frames.",
  cancelButtonText: "Cancel",
  startBillingText: "Start billing cycle",
};
const StartBillingDialog: React.FC<DialogProps> = ({ isOpen, onClose }) => {
  const { startBillingCycle, loader } = useSubscription({ onClose });

  return (
    <DefaultDialog dialogClass="w-[480px]" isOpen={isOpen} onClose={onClose}>
      <div className="bg-white text-center rounded-lg shadow-md">
        <div className="rounded-t-lg p-4">
          <div className="text-2xl font-bold text-center ml-5">
            {texts.dialogTitle}
          </div>
          <p className="text-base mt-10">{texts.dialogDescription1}</p>
          <p className="text-base mt-6">{texts.dialogDescription2}</p>
        </div>
        <div className="bg-[#F9FAFB]  rounded-b-lg p-4 flex justify-end">
          <DefaultButton
            isPlain={true}
            onClick={onClose}
            text={texts.cancelButtonText}
          />
          <DefaultButton
            onClick={startBillingCycle}
            classes="ml-5"
            text={texts.startBillingText}
          />
          <Loader isLoading={loader} />
        </div>
      </div>
    </DefaultDialog>
  );
};

export default StartBillingDialog;

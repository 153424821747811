import React from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { useParams } from "react-router-dom";

function ArtworkDetail(props: InferProps<typeof ArtworkDetail.propTypes>) {
    let params = useParams();
    return (
        <main>
            Artwork: {params.artworkId}
        </main>
    )
}

ArtworkDetail.propTypes = {

}

export default ArtworkDetail


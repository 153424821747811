import React from "react";

interface Props {
    children: any;
}

const TableContainer: React.FC<Props> = (props) => {
    const { children } = props;
    return (
        <div className="flex z-10 flex-col">
                <div className="py-2 align-middle inline-block min-w-full  lg:px-6">
                    <div className="shadow relative overflow-x-visible  sm:rounded-lg">
                        {children}
                    </div>
            </div>
        </div>
    );
};

export default TableContainer
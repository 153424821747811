import React, { ReactNode, useState } from "react";
import ManagePlanButton from "../../components/shared/ManagePlanButton";
import { CONSTANT } from "../../static/constants";
import DefaultButton from "../../components/shared/DefaultButton";
import HorizontalLine from "../../components/shared/HorizontalLine";
import StartBillingDialog from "../../components/shared/StartBillingDialog";

interface Props {
  planType: string;
  subscriptionStatus: string;
  daysLeft: number;
  isCanceledAtEnd: boolean;
  periodEndDate: string;
}

const texts = {
  trialModeCardTitle: (dayLeft: number): string =>
    `You have ${dayLeft} days left on your trial.`,
  trialModeCardDesc: `Start your plan now to remove the banner on your displays.`,
  startYourPlanButtonText: "Start your plan",

  planType: (type: string): string => `You are in the ${type}.`,
  learnMoreAboutPlan: (element: ReactNode): ReactNode => (
    <>Learn about changes when updating or cancelling your plan {element}.</>
  ),
  learnMoreAboutPlanLink: `${process.env.REACT_APP_LEARN_MORE_ABOUT_PLAN_LINK}`
};

const stripeSubsLink: string =
  process.env.REACT_APP_UPDATE_SUBSCRIPTION_LINK ?? "";

const UpdatePlan: React.FC<Props> = ({
  planType,
  subscriptionStatus = "",
  daysLeft = 0,
  isCanceledAtEnd,
  periodEndDate,
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const renderTrialContent = () => (
    <>
      <HorizontalLine margin="5px 0 20px 0" />
      <div className="block sm:flex justify-between ">
        <div className="">
          <p className="font-semibold text-gray-700 text-base mb-3">
            {texts.trialModeCardTitle(daysLeft)}
          </p>
          <p className="text-gray-700 text-base mb-3">
            {texts.trialModeCardDesc}
          </p>
        </div>
        <div>
          <DefaultButton
            text={texts.startYourPlanButtonText}
            onClick={openDialog}
          />
        </div>
      </div>
    </>
  );

  const renderCancelContent = () => (
    <>
      <div className="block sm:flex justify-between">
        <div className="">
          <p className="font-semibold  text-[#B94931] text-base mb-3">
            Your {planType} will expire on {periodEndDate}.
          </p>
          <p className="text-gray-700 text-base mb-3">
            Manage your plan to reactivate your subscription.
          </p>
        </div>
      </div>
    </>
  );

  return (
    <div className="max-w-full bg-white p-6 mt-20 rounded overflow-hidden shadow-md">
      <div className="block sm:flex justify-between">
        <div className="">
          <div className="font-semibold text-lg mb-2">{CONSTANT.PLAN}</div>
          <p className="text-gray-700 text-base mb-3">
            {!isCanceledAtEnd && texts.planType(planType)}
            {/* This link is in one of the designs, but seems unnecessary? */}
            {/* {!isCanceledAtEnd && subscriptionStatus === "trialing" && (
              <ManagePlanButton link={stripeSubsLink} isLinkthem={true} />
            )} */}
          </p>
          {subscriptionStatus !== "trialing" && !isCanceledAtEnd && (
            <p className="text-gray-700 text-base mb-3">
              {texts.learnMoreAboutPlan(
                <a href={texts.learnMoreAboutPlanLink} className="underline">
                  here
                </a>
              )}
            </p>
          )}
        </div>
        <div>
          <ManagePlanButton link={stripeSubsLink} />
        </div>
      </div>

      <StartBillingDialog isOpen={dialogOpen} onClose={closeDialog} />
      {/* Dialog component */}
      {!isCanceledAtEnd && subscriptionStatus === "trialing" && renderTrialContent()}
      {isCanceledAtEnd && renderCancelContent()}
    </div>
  );
};

export default UpdatePlan;

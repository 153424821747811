import { useQuery, gql } from "@apollo/client";

export const GET_EXHIBITION = gql`
  query GetExhibitions(
    $accountId: ID!
    $limit: Int
    $offset: Int
  ) {
    exhibitions(
      where: {
        account: $accountId
      }
      limit: $limit
      start: $offset
      sort: "name:asc"
    ) {
      id
      name
      pairs{
        id
        position
        frame{
          id
          url
          name
        }
        artwork{
          id,
          title,
          thumbImageUrl
        }
        artworkCollection{
          id,
          name,
        }
      }
    }
    exhibitionsCount(
      where: {
        account: $accountId
      }
    )
  }
`;

export const useGetExhibitions = (
  accountId: any,
  limit?: number,
  offset?: number,
) => {
  const { loading, error, data, fetchMore } = useQuery(GET_EXHIBITION, {
    variables: { accountId, limit, offset },
    fetchPolicy: "cache-and-network",
  });
  return {
    exhibitions: data?.exhibitions,
    fetchMore,
    exhibitionsCount: data?.exhibitionsCount,
    loading,
    error,
  };
};

import React, { Fragment, memo } from "react";
import { CONSTANT } from "../../static/constants";

type Props = {};

const ToastTemplate: React.FC<Props> = (props) => {
    return (
        <Fragment>
            <div style={{ fontWeight: 'bold' }}>{CONSTANT.WALLET_ADDED_S} </div>
            <p>
                {CONSTANT.WALLET_ADDED_S_M}
            </p>
        </Fragment>
    );
};

export default memo(ToastTemplate);

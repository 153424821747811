import { ArrowSmUpIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, memo } from 'react';
import clsx from 'clsx';

import TableRow from '../../../components/tables/table-row';
import { DropdownOption } from '../../../components/model/DropdownOption';
import { Artwork } from '../../../components/model/Artwork';
import { shortenAddress } from '../../../util/strings';
import { display } from '../../../static/Enums';
import Button from '../../../components/shared/Button';
import ArtworkThumb from '../../../components/shared/thumbnails/ArtworkThumb';
import { getBadgeForArtwork } from '../../../util/artworks';
import Dropdown from '../../../components/shared/kioskMode/Dropdown';
interface Props {
	data: Artwork;
	pushArtworkToFrame: (artwork: Artwork) => () => void;
	updateDisplay: (id: string) => (selected: DropdownOption) => void;
	artworkLoading: boolean;
}

const ArtworksTableRow: React.FC<Props> = (props) => {
	const { data, updateDisplay, pushArtworkToFrame } = props;

	const { hasBadge, badge } = getBadgeForArtwork(data);
	const className = 'text-*-fg bg-*-bg'.replace(/\*/g, String(badge).toLowerCase());
	const opacity = data?.status === 'processing' ? 'opacity-50' : '';
	return (
		<TableRow key={data.id}>
			<td className="w-2/3">
				<div className="flex p-4">
					<div className="">
						<ArtworkThumb roundedClass="rounded-xl" height={' kiosk-table-image'} artwork={data} />
					</div>
					<div className="flex flex-col justify-center py-0 px-6 min-w-0">
						<div
							className={`${opacity} kiosk-font-2xl text-primary font-semibold overflow-hidden text-nowrap text-ellipsis`}
						>
							{data?.title}
						</div>
						<div
							className={`${opacity} kiosk-font-2xl text-gray text-table-cell-color overflow-hidden text-nowrap text-ellipsis`}
						>
							{data?.creatorUsername || shortenAddress(data?.creatorAddress)}
						</div>
					</div>
				</div>
			</td>
			{/* <td className="">
				<div
					className={`${opacity} kiosk-font-2xl text-primary font-semibold overflow-hidden text-nowrap text-ellipsis`}
				>
					{data?.title}
				</div>
				<div
					className={`${opacity} kiosk-font-2xl text-gray text-table-cell-color overflow-hidden text-nowrap text-ellipsis`}
				>
					{data?.creatorUsername || shortenAddress(data?.creatorAddress)}
				</div>
			</td> */}

			<td className="text-right font-medium w-content">
				<div className="flex justify-end py-4 items-center ">
					<div className="kiosk-font-lg justify-center py-4 px-2 items-center hidden sm:flex">
						<span
							className={clsx(
								'inline-flex items-center justify-center px-2 py-1 font-bold leading-none rounded-md',
								hasBadge && className
							)}
						>
							{badge}
						</span>
					</div>
					<div className="hidden sm:flex justify-center w-content items-center px-2">
						{data?.mediaType === 'website' || data?.status === 'processing' ? (
							<span></span>
						) : (
							<Dropdown
								// disabled={data?.status === 'processing'}
								onOptionSelect={updateDisplay(data.id)}
								options={display}
								width="w-full"
								default={{
									title: data.display,
									id: 1,
								}}
							/>
						)}
					</div>
					<div className="px-8">
						<Button
							disabled={data?.status === 'processing'}
							onClick={pushArtworkToFrame(data)}
							push={false}
							className="rounded-xl !px-2 kiosk-push-button"
							loading={props.artworkLoading}
						>
							<ArrowSmUpIcon className="kiosk-push-button-icon" />
						</Button>
					</div>
				</div>
			</td>
		</TableRow>
	);
};

export default memo(ArtworksTableRow);

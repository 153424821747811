import { gql, useMutation } from "@apollo/client";
import { GET_COLLECTIONS } from "./useGetCollections";

interface createCollectionInput {
  variables: {
    input: {
      data: {
        interval?: number;
        name?: string;
        transition?: string;
        playFullVideos?: boolean;
        creator: string;
      };
    };
  };
}

export const CREATE_COLLECTION = gql`
  mutation CreateCollection($input: createCollectionInput!) {
    createCollection(input: $input) {
      collection {
        id
        interval
        name
        transition
        playFullVideos
      }
    }
  }
`;

export const useCreateCollection = (): ((
  createCollection: createCollectionInput
) => any) => {
  const [createCollection] = useMutation(CREATE_COLLECTION, {
    refetchQueries: [GET_COLLECTIONS, "GetCollections"],
    // update(cache, { data: { createCollection } }) {
    //   cache.modify({
    //     fields: {
    //       collections(existingCollections = []) {
    //         console.log(existingCollections, "eeeeeeeeee");
    //         console.log(createCollection, "ccccccccccc");
    //         const newCollectionRef = cache.writeFragment({
    //           data: createCollection,
    //           fragment: gql`
    //             fragment NewCollection on Collection {
    //               id
    //               interval
    //               name
    //               artworks
    //             }
    //           `,
    //         });
    //         console.log(newCollectionRef, "nnnnnnnnnnnn");
    //         return [...existingCollections, newCollectionRef];
    //       },
    //     },
    //   });
    // },
  });

  return createCollection;
};



import React, { useState, useEffect, useContext } from "react";
import { InferProps } from "prop-types";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import EmailModal from "../../components/modal/EmailModal";
import { useDeleteWallet } from "../../hooks/wallet/useDeleteWallet";
import { useUpdateUser } from "../../hooks/user/useUpdateUser";
import { useCreateWallet } from "../../hooks/wallet/useCreateWallet";
import { useGetWallets } from "../../hooks/wallet/useGetWallets";
import { useUpdateWallet } from "../../hooks/wallet/useUpdateWallet";
import { useAuth } from "../../authn/authn";
import { Magic } from "magic-sdk";
import { Wallet } from "../../components/model/Wallet";
import WalletsTableRow from "./wallets-table.row";
import DeleteModal from "../../components/modal/DeleteModal";
import { CONSTANT } from "../../static/constants";
import { toast } from "react-toastify";
import AccountUsage from "./account-usage";
import SuperAdminFeatures from "./super-admin-features";
import Legal from "./legal";
import { getWallet } from "../utils/Helper";
import { nftSyncBackgroundContext } from "../../contexts/nft-sync-background-context";
import { getAccountOption } from "../../util/account";
import { getFormattedDate } from "../../util/date";
import UpdatePlan from "./update-plan";
import useSubscription from "../../hooks/subscription/subscription";
import { generalToastContext } from "../../contexts/general-toast-context-provider";

const magic = new Magic(String(process.env.REACT_APP_MAGIX_PUBLIC_KEY));

function Account(props: InferProps<typeof Account.propTypes>) {
  const [sortedColumn, setSortedColumn] = useState<[string, "asc" | "desc"]>([
    "id",
    "desc",
  ]);

  const { selectedPlan } = useContext(generalToastContext);

  const [searchText, setSearchText] = useState("");
  const createWallet = useCreateWallet();
  const deleteWallet = useDeleteWallet();
  const updateWallets = useUpdateWallet();
  const authn = useAuth();
  const limit = 20;
  const { loading, error, wallets, fetchMore, walletsCount } = useGetWallets(
    authn.getUser()?.id,
    limit,
    0,
    sortedColumn.join(":"),
    searchText
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [email, setEmail] = useState("");
  const [addEmail, setAddEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const updateUsers = useUpdateUser();
  const [isShowEmail, setIsShowEmail] = useState(false);
  const [allWallets, setAllWallets] = useState<Wallet[]>();
  const [authPublicAddress, setAuthPublicAddress] = useState("");
  const [walletId, setWalletId] = useState<string>("0");

  const { setSyncLoader, triggerSync } = useContext(nftSyncBackgroundContext);

  const showWalletSection = getAccountOption(authn.getAccount(), "syncNFTs");

  useEffect(() => {
    setAllWallets(wallets);
  }, [wallets]);

  useEffect(() => {
    if (wallets) setAuthPublicAddress(wallets[0]?.publicAddress!);
  }, [wallets]);

  const onCloseEmailModal = () => {
    setIsShowEmail(false);
  };

  const addEmailHandler = async () => {
    const jwtToken = await magic.auth.loginWithMagicLink({ email: addEmail });
    if (jwtToken) {
      setIsShowEmail(false);
      const res = await updateUsers({
        variables: {
          input: {
            data: {
              email: addEmail,
              username: addEmail,
            },
            where: {
              id: authn.getUser()?.id,
            },
          },
        },
      });
      if (res) {
        toast.success("Email update successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  const setAddEmailHandler = (val: string) => {
    setAddEmail(val);
  };
  useEffect(() => {
    if (authn.getUser()?.email) setEmail(authn.getUser()?.email);
  }, [authn.getUser()?.email]);

  const onOpenDeleteModal = (id: string) => () => {
    setShowDeleteModal(true);
    setWalletId(id);
  };

  const onCloseDeleteModal = async (state: boolean) => {
    if (state) {
      try {
        const result = await deleteWallet({
          variables: {
            input: {
              where: {
                id: walletId,
              },
            },
          },
        });
        if (result) {
          toast.success("Wallet deleted successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          authn.refetch();
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    // setLoader(false);
    setShowDeleteModal(false);
  };

  const connectWallet = async () => {
    // console.log('connectWallet')
    const { publicAddress, provider } = await getWallet();

    if (authPublicAddress === publicAddress) {
      const res = await updateWallets({
        variables: {
          input: {
            data: {
              publicAddress: publicAddress,
              creator: authn.getUser()?.id,
            },
            where: {
              id: authn.getUser()?.id,
            },
          },
        },
      });
    } else {
      const result = await createWallet({
        variables: {
          input: {
            data: {
              publicAddress: publicAddress,
              blockchain: "Ethereum",
              walletProvider: provider || "",
              owner: authn.getUser()?.id,
            },
          },
        },
      });
      if (result) {
        toast.success("Wallet added successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // wallet added, kick off sync
        triggerSync();
      }
      authn.refetch();
    }
  };

  // useEffect(() => {
  //   if (window.ethereum) {
  //     console.log(window.ethereum.selectedAddress);
  //   }
  // }, []);

  return (
    <main className="mx-auto max-w-7xl px-4 sm:mt-16 sm:px-6 lg:px-8 xl:mt-28">
      <div className="">
        <div className="my-2 md:flex">
          <span className="mr-3 text-secondary text-3xl font-bold">
            Account
          </span>
        </div>
        <div className="max-w-full bg-white mt-5 p-6 rounded overflow-hidden shadow-md">
          <div className="">
            <div className="font-semibold text-lg mb-2">Email</div>
            {/* <p className="text-gray-700 text-base">
              Add your email to enable login with email. Your wallet profile
              will be merged with your email profile.
            </p> */}
          </div>
          {email && email != "none" ? (
            <div className="flex">
              {email}
              {/* <input
                id="email"
                type="email"
                autoComplete="email"
                value={email}
                required
                placeholder="name@domain.com"
                onChange={(e) => setEmailHandler(e.target.value)}
                className="appearance-none block w-80 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              /> */}
              <span
                className="ml-12 text-active-button cursor-pointer font-bold"
                // onClick={emailUpdateHandler}
                onClick={() => setIsShowEmail(true)}
              >
                Update
              </span>
            </div>
          ) : (
            <div className="px-6 pt-4 pb-2">
              <button
                onClick={() => setIsShowEmail(true)}
                className="w-24 flex justify-center text-primary py-2  border border-bc border-transparent rounded-md shadow-sm text-sm font-medium   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Email
              </button>
            </div>
          )}
        </div>

        {showWalletSection && (
          <div className="max-w-full bg-white p-6 mt-20 rounded overflow-hidden shadow-md">
            <div className="">
              <div className="font-semibold text-lg mb-2">Wallet</div>
              <p className="text-gray-700 text-base mb-3">
                Add your crypto wallets to sync the NFTs you own.
              </p>
              <div className="rounded-md border border-table-border-color">
                <table className="min-w-full ">
                  <tbody className="bg-indigo divide-y  divide-table-border-color rounded-md">
                    {allWallets?.map((wallet: Wallet) => (
                      <WalletsTableRow
                        onOpenDeleteModal={onOpenDeleteModal}
                        key={wallet.id}
                        data={wallet}
                        walletLoading={false}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-4 pb-2">
              <button
                onClick={connectWallet}
                className="w-24 flex justify-center text-primary py-2 border border-bc border-transparent rounded-md shadow-sm text-sm font-medium   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Wallet
              </button>
            </div>
          </div>
        )}
        {authn.getUser()?.account?.id && (
          <AccountUsage accountId={authn.getUser()?.account?.id} />
        )}
        {authn.getUser()?.role?.name === "SuperAdmin" && <SuperAdminFeatures />}
        <UpdatePlan
          periodEndDate={getFormattedDate(selectedPlan.currentPeriodEnd)}
          isCanceledAtEnd={selectedPlan.subsCancelAtEnd}
          planType={selectedPlan.plan}
          daysLeft={selectedPlan.daysLeft}
          subscriptionStatus={selectedPlan.subscriptionStatus}
        />
        <Legal />
      </div>
      <EmailModal
        isOpen={isShowEmail}
        onCloseEmailModal={() => onCloseEmailModal()}
        addEmailHandler={() => addEmailHandler()}
        setAddEmailHandler={(val: any) => setAddEmailHandler(val)}
      ></EmailModal>
      <DeleteModal
        title={CONSTANT.DELETE_WALLETS_TITLE}
        description={CONSTANT.DELETE_WALLETS_DESCRIPTION}
        isOpen={showDeleteModal}
        onCloseDeleteModal={(value: boolean) => onCloseDeleteModal(value)}
      />
    </main>
  );
}

Account.propTypes = {};

export default Account;

import placeholder from '../assets/placeholder.jpg';
import { pricing } from '../static/Enums';
export const getArtworkThumbnailSrc = (frame) => {
    if (frame?.artwork) {
        return frame?.artwork?.thumbImageUrl || placeholder;
    }
    if (frame?.artworkCollection) {
        return frame?.artworkCollection?.artworks[0]?.artwork?.thumbImageUrl || placeholder;
    }
    return placeholder
}


export const getNextPlanDetailsById = (id) => {
    const currentTierIndex = pricing.tiers.findIndex((tier) => tier.id === id);
    if (currentTierIndex + 1 <= pricing.tiers.length) {
        const nextTier = pricing.tiers[currentTierIndex + 1];
        const { numberOfFrame, name } = nextTier;
        return { numberOfFrame, name };

    }
    else {
        return {
            numberOfFrame: 0,
            name: ''
        }; // Return null if there's no next plan
    }
}

export const getCurrentPlanName = (id) => {
    const tier = pricing.tiers.find((tier) => tier.id === id);
    return tier ? tier.name: null;
}


export const getCurrentPlan = (id) => {
    const tier = pricing.tiers.find((tier) => tier.id === id);
    return tier ?? null;
}

export const getPlanIndex = (id) => {
    return pricing.tiers.findIndex((tier) => tier.id === id);
}

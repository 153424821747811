/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

type Props = {
    onToggle?: Function;
    isEnabled: boolean;
    // All other props
    [x: string]: any;
};

const Toggle: React.FC<Props> = (props) => {
    const [enabled, setEnabled] = useState(props.isEnabled)

    const handleToggle = () => {
        if (props.onToggle) {
            props.onToggle(!enabled)
        }
        setEnabled(!enabled)
    }

    return (
        <Switch
            checked={enabled}
            onChange={handleToggle}
            className={classNames(
                enabled ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            )}
        >
            <span className="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
            />
        </Switch>
    )
}

export default Toggle;

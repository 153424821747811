import { createContext, Dispatch, SetStateAction } from 'react'

interface NftContextType {
    nftSyncRequest: any;
    syncLoader: string;
    setSyncLoader: Dispatch<SetStateAction<string>>;
    triggerSync: Function;
    cancelSync: Function;
}

const nftDefaultValue = {
    nftSyncRequest: null,
    syncLoader: '',
    setSyncLoader: () => { },
    triggerSync: () => { },
    cancelSync: () => { }
}

export const nftSyncBackgroundContext = createContext<NftContextType>(nftDefaultValue)
import { gql, useMutation } from "@apollo/client";
import {GET_EXHIBITION} from './useGetExhibitions'

interface createExhibitionInput {
    variables: {
      input: {
        data: {
          name: string;
          creator: string;
          pairs?:string
        };
      };
    };
  }


export const CREATE_EXHIBITION = gql`
  mutation CreateExhibition($input: createExhibitionInput!) {
    createExhibition(input: $input) {
      exhibition {
        id
        name
      }
    }
  }
`;

export const useCreateExhibition = (): ((
    createExhibition: createExhibitionInput
) => any) => {
  const [creatExhibitionResult, { error }] = useMutation(CREATE_EXHIBITION, {
    refetchQueries: [GET_EXHIBITION, "GetExhibitions"],
  });

  return creatExhibitionResult;
};

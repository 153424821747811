import React, { ReactNode, ReactText, useContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { nftSyncBackgroundContext } from '../../contexts/nft-sync-background-context';
import { SYNC_NFTS_CONSTANT } from '../../static/Enums';
import Spinner from './Spinner';

type Props = {};

const SnackBar: React.FC<Props> = (props) => {
	const { syncLoader } = useContext(nftSyncBackgroundContext);
	const toastId = useRef<ReactText>();

	useEffect(() => {
		switch (syncLoader) {
			case SYNC_NFTS_CONSTANT.SYNC_PENDING:
				toastId.current = toast('Syncing NFTs', {
					icon: <Spinner className='w-5 h-5' />,
					position: toast.POSITION.BOTTOM_CENTER,
					autoClose: false,
					closeButton: false,
					style: { margin: 'auto' },
				});
				break;
			case SYNC_NFTS_CONSTANT.SYNC_DONE:
				toast.dismiss(toastId.current);
				toast.success('Syncing done', {
					position: toast.POSITION.BOTTOM_CENTER,
				});
				break;
			case SYNC_NFTS_CONSTANT.SYNC_FAILED:
				toast.dismiss(toastId.current);
				toast.error('Syncing failed', {
					position: toast.POSITION.BOTTOM_CENTER,
				});
				break;
			case SYNC_NFTS_CONSTANT.SYNC_CANCELLED:
				toast.dismiss(toastId.current);
				toast.error('Syncing cancelled', {
					position: toast.POSITION.BOTTOM_CENTER,
				});
				break;
			default:
				break;
		}
	}, [syncLoader]);

	return null;
};
export default SnackBar;

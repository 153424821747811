import { createNftSyncRequest, getNftSyncRequest } from '../hooks/artworks/syncNFT';
import { SYNC_NFTS_CONSTANT } from '../static/Enums';

export function getBadgeForArtwork(artwork) {
    const [hasBadge, badge] = [
        [/^image\/(?=gif)\w+$/i, "GIF"],
        [/^image\/(?!gif).+$/i, "IMG"],
        [/^video\/.+$/i, "VID"],
        [/^text\/html$/i, "WEB"],
    ]
        .map(([regex, badge]) => [
            RegExp(regex).test(String(artwork?.mimeType)),
            badge,
        ])
        .find(([hasBadge]) => hasBadge) || [false, ""];

    return { hasBadge, badge }
}

export async function fetchAPI(url, data) {

    const res = await fetch(`${process.env.REACT_APP_STRAPI_API_URL}/${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: "include",
    })

    const json = await res.json()

    if (json.errors) {
        console.error(json.errors)
        throw new Error('Failed to fetch API')
    }

    return json;
}
import PropTypes, { InferProps } from "prop-types";



function CollectionDetailsTitle({ titleClickHanaler, collectionName }: InferProps<typeof CollectionDetailsTitle.propTypes>) {
    return <span>
        <span
            className="text-back-nav-color mr-1 text-lg font-semibold cursor-pointer"
            onClick={() => { titleClickHanaler() }}
        >
            Collections /
        </span>
        <span className="text-1xl  md:text-3xl font-bold text-secondary">
            {collectionName}
        </span>
    </span>
}

CollectionDetailsTitle.propTypes = {
    titleClickHanaler: () => void {},
    collectionName: PropTypes.string.isRequired,

};
export default CollectionDetailsTitle

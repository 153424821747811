import { ArrowSmUpIcon } from "@heroicons/react/outline";
import PropTypes, { InferProps } from "prop-types";
import Button from "./Button";
import TableTitle from "./TableTitle";

function TableBanner({
    title,
    count,
    addHandler,
    buttonTitle,
    pushToFrameHandler,
    isPussButtonAvaliable,
    buttonTitleSmallSize,
}: InferProps<typeof TableBanner.propTypes>) {


    return (
        <div className={` ${isPussButtonAvaliable ? 'sm:flex' : 'flex'}  mt-6 px-3  mb-3 md:px-4 lg:px-6  md:mt-16`} >
            <TableTitle
                title={title}
                count={count}
                className={`grow text-left`}
            />

            <div className={`${isPussButtonAvaliable ? 'flex flex-none mt-4 sm:mt-0 ' : 'flex-none'}   justify-end `}>
                {
                    isPussButtonAvaliable &&
                    <Button
                        secondary
                        onClick={pushToFrameHandler}
                    >
                        <ArrowSmUpIcon className="h-6 w-6" />
                    </Button>
                }
                <Button className='sm:flex-none ml-2' primary onClick={() => addHandler()} >
                    <span className="md:hidden">{buttonTitleSmallSize}</span>
                    <span className="hidden md:inline-block ">{buttonTitle}</span>
                </Button>
            </div>

        </div>

    );
}

TableBanner.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    count: PropTypes.number,
    addHandler: () => void {},
    isPussButtonAvaliable: PropTypes.bool,
    pushToFrameHandler: () => void {},
    titleClickHandler: () => void {},
    buttonTitle: PropTypes.string,
    buttonTitleSmallSize: PropTypes.string
};

export default TableBanner;

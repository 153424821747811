import { useState, useEffect } from 'react';
import Loader from '../../components/shared/loader';
import { CONSTANT, limit } from '../../static/constants';

import analytics from '../../services/analytics';

import { Artwork } from '../../components/model/Artwork';
import DeleteModal from '../../components/modal/DeleteModal';
import { DropdownOption } from '../../components/model/DropdownOption';
import TableTitleBar from '../../components/tables/table-title-bar';
import { useUpdateArtwork } from '../../hooks/artworks/useUpdateArtwork';
import ArtworkPicker from '../artworks/artwork-picker';
import { useAuth } from '../../authn/authn';
import { useSetCollectionArtworks } from '../../hooks/collections/useSetCollectionArtworks';
import { useSetCollectionArtworksOrder } from '../../hooks/collections/useSetCollectionArtworksOrder';
import SortableItems from './sortable-items';
import {
	closestCenter,
	DndContext,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDeleteCollectionArtwork } from '../../hooks/collections/useDeleteCollectionArtwork';
import { useReactiveVar } from '@apollo/client';
import { selectedFrameIdVar } from '../../states/topNavFrames';
import { useUpdateFrame } from '../../hooks/frames/useUpdateFrame';
import TableBanner from '../../components/shared/TableBanner';
import TableContainer from '../../components/shared/TableContainer';
import TableHead from '../../components/shared/TableHead';
import CollectionDetailsTitle from './collection-detail-table-title';
import Table from '../../components/shared/Table';
import { useNavigate, useParams } from 'react-router-dom';
import MainContainer from '../../components/shared/MainContainer';
import Alerts from '../../components/shared/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetCollectionDetails } from '../../hooks/collections/useGetCollectionDetails';
import TableEmptyState from '../../components/tables/table-empty-state';
// import { SortableItem } from "./sortable-items";

function CollectionDetails() {
// props: InferProps<typeof CollectionDetails.propTypes>
	const navigation = useNavigate();

	let { id } = useParams();

	const collectionId: number = parseInt(id || '');
	const authn = useAuth();
	const updateArtworks = useUpdateArtwork();
	const setCollectionArtwork = useSetCollectionArtworks();
	const setCollectionArtworksOrder = useSetCollectionArtworksOrder();
	const deleteCollectionArtwork = useDeleteCollectionArtwork();
	const updateFrame = useUpdateFrame();

	const [order, setOrder] = useState('name:ASC');
	const selectedFrameId = useReactiveVar(selectedFrameIdVar);

	const { collections, loading, fetchMore, collectionsCount } = useGetCollectionDetails({
		collectionId: id || '-1',
		accountId: authn?.getAccountId(),
		limit: -1,
		offset: 0,
		sort: order,
		id: collectionId.toString(),
	});
	const [showPicker, setShowPicker] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedArtworksIds, setSelectedArtworksIds] = useState<Array<string>>();
	const [selectedBackupArtworksIds, setSelectedBackupArtworksIds] = useState<Array<string>>();
	const [loader, setLoader] = useState(false);
	const [collectionArtworks, setCollectionArtworks] = useState<Artwork[]>([]);
	const [activeId, setActiveId] = useState<any>();
	const [artworkCollectionId, setArtworkCollectionId] = useState<string>('0');

	// setSelectedArtworks
	useEffect(() => {
		if (collections) {
			arrangeCollectionArtwork(collections[0].artworks, false);
		}
	}, [collections]);

	const arrangeCollectionArtwork = (artworks: any, append: boolean) => {
		let collArtworks: Artwork[] = [];
		const artworksIds: string[] = [];
		artworks.map((item: any) => {
			let artworkObj = { ...item.artwork };
			artworkObj.position = item.position;
			artworkObj.artworkCollectionId = item.id;
			collArtworks.push(artworkObj);
			artworksIds.push(item.artwork.id);
		});
		if (append) {
			localStorage.setItem('selectedArtwrokIds', JSON.stringify([...selectedArtworksIds!, ...artworksIds]));

			setSelectedArtworksIds((ids) => [...ids!, ...artworksIds]);
			setCollectionArtworks((artworks) => [...artworks!, ...collArtworks]);
		} else {
			setSelectedArtworksIds(artworksIds);
			localStorage.setItem('selectedArtwrokIds', JSON.stringify(artworksIds));
			setCollectionArtworks(collArtworks);
		}
	};

	const onClosePicker = async (selectedArtworks = []) => {
		if (selectedArtworks !== null) {
			const artworkIds: number[] = [];
			const tempArtworks = [...collectionArtworks, ...selectedArtworks];
			tempArtworks?.map((artwork: Artwork) => {
				artworkIds.push(Number(artwork.id));
			});
			setLoader(true);
			if (selectedArtworks?.length > 0) {
				await setCollectionArtwork({
					variables: {
						artworkIds: artworkIds,
						collectionId: collectionId,
					},
				});
				analytics.track('collection: artwork updated', {
					id: collectionId,
				});
			}
			setLoader(false);
		} else {
			setSelectedArtworksIds(JSON.parse(localStorage.getItem('selectedArtwrokIds') || ''));
		}
		setShowPicker(false);
	};

	//  opens the Confirm Delete Modal

	const onOpenDeleteModal = (id: string) => {
		setShowDeleteModal(true);
		setArtworkCollectionId(id);
	};
	const removeCollectionArtworks = async (state: boolean) => {
		if (state) {
			setLoader(true);

			await deleteCollectionArtwork({
				variables: {
					input: {
						where: {
							id: artworkCollectionId,
						},
					},
				},
			});
			await sortArtworksAfterDelete();
			setLoader(false);
		}
		setShowDeleteModal(false);
	};

	/**
	 *
	 * @function sortArtworksAfterDelete to sort the list afer the delete operation done
	 *
	 */
	const sortArtworksAfterDelete = () => {
		let artworkArr: { position: number; artworkId: number }[] = [];
		let temp = 0;
		for (let i = 0; i < collectionArtworks.length; i++) {
			if (collectionArtworks[i].artworkCollectionId !== artworkCollectionId) {
				temp = i - temp > 1 ? i - 1 : i;
				artworkArr.push({ position: temp, artworkId: parseInt(collectionArtworks[i].id) });
			}
		}
		setCollectionArtworksOrder({
			variables: {
				artworks: artworkArr,
				collectionId: collectionId,
			},
		});
	};

	const pushCollectionToFrame = async (collectionId: string) => {
		setLoader(true);
		if (selectedFrameId) {
			await updateFrame({
				variables: {
					input: {
						data: {
							artwork: null,
							artworkCollection: collectionId,
						},
						where: {
							id: selectedFrameId,
						},
					},
				},
			});
			analytics.track('collection: pushed', {
				id: collectionId,
				frameId: selectedFrameId,
			});
			analytics.add('push_count')
		}
		setLoader(false);
	};

	const updateDisplay = async (selected: DropdownOption, id: string) => {
		setLoader(true);
		const res = await updateArtworks({
			variables: {
				input: {
					data: {
						display: selected.title,
					},
					where: {
						id: id,
					},
				},
			},
		});

		setLoader(false);
	};

	const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));
	function handleDragStart(event: { active: { id: any } }) {
		setActiveId(event.active.id);
	}

	function handleDragEnd(event: any) {
		const { active, over } = event;
		if (active.id !== over.id) {
			let artworkArr: { position: number; artworkId: number }[] = [];
			setCollectionArtworks((items) => {
				const oldIndex = items.findIndex((item) => item.id === active.id);
				const newIndex = items.findIndex((item) => item.id === over.id);
				let newArr = arrayMove(items, oldIndex, newIndex);
				for (let i = 0; i < newArr.length; i++) {
					artworkArr.push({ position: i, artworkId: parseInt(newArr[i].id) });
					newArr[i].position = i;
				}
				return newArr;
			});

			setLoader(true);
			setCollectionArtworksOrder({
				variables: {
					artworks: artworkArr,
					collectionId: collectionId,
				},
			});
			setLoader(false);
		}
		// Stop overlay.
		setActiveId(null);
	}

	const fetchMoreData = async () => {
		setLoader(true);

		const { data } = await fetchMore({
			variables: {
				offset: collectionArtworks?.length,
				limit: limit,
			},
		});
		arrangeCollectionArtwork(data.collections[0]?.artworks, true);
		setLoader(false);
	};

	return (
		<MainContainer>
			{!loading &&
				(collections ? (
					<div className="sm:text-center lg:text-left">
						<TableTitleBar>
							<TableBanner
								title={
									<CollectionDetailsTitle
										titleClickHanaler={() => navigation('/collections')}
										collectionName={collections?.length > 0 ? collections[0].name : ''}
									/>
								}
								addHandler={() => setShowPicker(true)}
								buttonTitle={CONSTANT.ADD_TO_COLLECTION}
								buttonTitleSmallSize={CONSTANT.ADD}
								pushToFrameHandler={() => pushCollectionToFrame(collectionId.toString())}
								isPussButtonAvaliable={true}
							/>
						</TableTitleBar>

						<TableContainer>
							<DndContext
								sensors={sensors}
								collisionDetection={closestCenter}
								onDragStart={handleDragStart}
								onDragEnd={handleDragEnd}
							>
								{collectionArtworks?.length > 0 ? (
									<InfiniteScroll
										dataLength={Number(collectionArtworks?.length) || 0}
										next={fetchMoreData}
										hasMore={
											(Number(collectionsCount) || 0) > (Number(collectionArtworks?.length) || 0)
										}
										loader={<h4></h4>}
									>
										<Table>
											<TableHead>
												<tr className="text-table-header-fg">
													<th></th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider"
													>
														{CONSTANT.IMAGE}
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider"
													>
														{CONSTANT.TITLE}
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider"
													>
														{CONSTANT.ARTIST}
													</th>
													<th className="sm:w-0 md:w-11 lg:w-20"></th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-semibold font uppercase tracking-wider"
													>
														{CONSTANT.TYPE}
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-semibold font uppercase tracking-wider"
													>
														{CONSTANT.SIZE}
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-semibold font uppercase tracking-wider"
													>
														{CONSTANT.SOURCE}
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-semibold font uppercase tracking-wider"
													></th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-semibold font  uppercase tracking-wider"
													></th>
												</tr>
											</TableHead>
											<tbody className="bg-white divide-y divide-gray-200">
												<SortableContext
													items={collectionArtworks}
													strategy={verticalListSortingStrategy}
												>
													{collectionArtworks.map((artwork, i) => (
														<SortableItems
															key={i}
															artwork={artwork}
															updateDisplay={updateDisplay}
															onOpenDeleteModal={onOpenDeleteModal}
														/>
													))}
												</SortableContext>
											</tbody>
										</Table>
									</InfiniteScroll>
								) : (
									<TableEmptyState title="This collection doesn't have any artwork yet." />
								)}
								{/* <DragOverlay>{activeId ? activeId : null}</DragOverlay> */}
							</DndContext>
						</TableContainer>
					</div>
				) : (
					<Alerts
						className={'text-red-600 bg-red-200 bg-opacity-100'}
						text={CONSTANT.DATA_NOT_FOUND}
						icon={<ExclamationCircleIcon className="w-7" />}
					/>
				))}
			{showPicker ? (
				<div>
					<ArtworkPicker
						isOpen={showPicker}
						onCloseModal={onClosePicker}
						selectedArtworksIds={selectedArtworksIds}
						isMultiSelect={true}
					></ArtworkPicker>
				</div>
			) : (
				''
			)}
			<div>
				<DeleteModal
					isOpen={showDeleteModal}
					title={CONSTANT.ARTWORK}
					onCloseDeleteModal={removeCollectionArtworks}
					description={CONSTANT.REMOVE_COLLECTION_ARTWORK}
				></DeleteModal>
			</div>
			<Loader isLoading={loading}></Loader>
		</MainContainer>
	);
}

// CollectionDetails.propTypes = {
//   closeCollectionDetails: PropTypes.func.isRequired,
//   user: PropTypes.string.isRequired,
//   selectedCollection: PropTypes.any!,
// };

export default CollectionDetails;

import { useEffect, useState, Fragment } from 'react';
import { InferProps } from 'prop-types';
import { ArrowSmUpIcon, ChevronRightIcon, DotsVerticalIcon } from '@heroicons/react/outline';

import analytics from '../../services/analytics';

import { Menu, Transition } from '@headlessui/react';
import { CONSTANT, limit } from '../../static/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../components/shared/loader';
import { toast } from 'react-toastify';
import { Exhibition } from '../../components/model/Exhibition';
import TableTitleBar from '../../components/tables/table-title-bar';
import Button from '../../components/shared/Button';
import EditExhibition from '../../components/modal/EditExhibition';
import { useGetExhibitions } from '../../hooks/exhibitions/useGetExhibitions';
import { useCreateExhibition } from '../../hooks/exhibitions/useCreateExhibition';
import { useDeleteExhibition } from '../../hooks/exhibitions/useDeleteExhibition';
import { useUpdateExhibition } from '../../hooks/exhibitions/useUpdateExhibition';
import { useAuth } from '../../authn/authn';
import AddExhibition from '../../components/modal/AddExhibitionModal';
import DeleteModal from '../../components/modal/DeleteModal';
import { DropdownOption } from '../../components/model/DropdownOption';
import { usePushExhibition } from '../../hooks/exhibitions/usePushExhibition';
import TableRow from '../../components/tables/table-row';
import TableBanner from '../../components/shared/TableBanner';
import TableContainer from '../../components/shared/TableContainer';
import MainContainer from '../../components/shared/MainContainer';
import Table from '../../components/shared/Table';
import TableEmptyState from '../../components/tables/table-empty-state';
import { useNavigate } from 'react-router-dom';

function Exhibitions(props: InferProps<typeof Exhibitions.propTypes>) {
	const navigation = useNavigate();

	const [loader, setLoader] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [allExhibitions, setAllExhibitions] = useState<Exhibition[]>();
	const [tempExhibitions, setTempExhibitions] = useState<Exhibition[]>();
	const [exhibition, setExhibition] = useState<DropdownOption>();
	const [selectedExhibition, setSelectedExhibition] = useState<Exhibition>();
	const [exhibitionDetailsClicked, setExhibitionDetailsClicked] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [exhibitionId, setExhibitionId] = useState<string>();
	const authn = useAuth();
	const createExhibition = useCreateExhibition();
	const deleteExhibition = useDeleteExhibition();
	const updateExhibition = useUpdateExhibition();
	const pushExhibition = usePushExhibition();
	const [exhibitionLoading, setExhibitionLoading] = useState('');

	const { loading, error, exhibitions, fetchMore, exhibitionsCount } = useGetExhibitions(
		authn.getAccountId(),
		limit,
		0
	);

	const onCloseDialog = async (name: string) => {
		setShowModal(false);
		setLoader(true);
		if (name) {
			try {
				const result = await createExhibition({
					variables: {
						input: {
							data: {
								name: name,
								creator: authn.getUser().id,
							},
						},
					},
				});
				analytics.track('exhibition: created', {
					exhibitionId: result.data.createExhibition.exhibition.id,
				});
				toast.success('Exhibition added', {
					position: toast.POSITION.TOP_RIGHT,
				});
			} catch (ex: any) {
				toast.error(ex?.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			} finally {
				setLoader(false);
			}
		}
	};

	const onEditExhibition = async (exhibition: Exhibition) => {
		if (exhibition) {
			const res = await updateExhibition({
				variables: {
					input: {
						data: {
							name: exhibition.name,
						},
						where: {
							id: exhibition.id + '',
						},
					},
				},
			});
			analytics.track('exhibition: updated', {
				exhibitionId: exhibition.id,
                source: 'exibitions list'
			});
		}
		setShowEditModal(false);
	};

	const pushArtworksToFrames = async (exhibitionId: number | string) => {
        try {
            await pushExhibition({
                variables: {
                    id: exhibitionId,
                },
            });
            analytics.track('exhibition: pushed', {
                exhibitionId: exhibitionId,
                source: 'exhibition list'
            });
            analytics.add('push_count')
        } catch (ex: any) {
            console.error(ex)
            toast.error('Error pushing exhibition', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } finally {
            setExhibitionLoading('');
        }
	};

	const getExhibitionDetails = async (exhibition: DropdownOption) => {
		// setExhibitionDetailsClicked(true);
		// setExhibition(exhibition);
		navigation('/exhibitions/' + exhibition?.id);
	};

	function classNames(...classes: any) {
		return classes.filter(Boolean).join('');
	}

	const onCloseExhibition = async () => {
		setExhibitionDetailsClicked(false);
	};

	const fetchMoreData = async () => {
		setLoader(true);
		const { data } = await fetchMore({
			variables: {
				offset: allExhibitions?.length,
				limit: limit,
			},
		});
		setAllExhibitions((exhibitions) => [...exhibitions!, ...data?.exhibitions]);
		setLoader(false);
	};
	const onOpenDeleteModal = (id: string) => {
		setShowDeleteModal(true);
		setExhibitionId(id);
	};

	const onCloseDeleteModal = async (state: boolean) => {
		if (state) {
			setLoader(true);
			await deleteExhibition({
				variables: {
					input: {
						where: {
							id: exhibitionId + '',
						},
					},
				},
			});
            analytics.track('exhibition: deleted', {
				exhibitionId: exhibitionId,
			});
		}
		setLoader(false);
		setShowDeleteModal(false);
	};

	useEffect(() => {
		setAllExhibitions(exhibitions);
		setTempExhibitions(exhibitions);
	}, [exhibitions]);

	return (
		<>
			<div>
				<MainContainer>
					{!exhibitionDetailsClicked ? (
						<div className=" sm:text-center lg:text-left">
							<TableTitleBar>
								<TableBanner
									title={CONSTANT.EXHIBITIONS}
									count={exhibitionsCount}
									addHandler={() => setShowModal(true)}
									buttonTitle={CONSTANT.NEW_EXHIBITION}
									buttonTitleSmallSize={CONSTANT.CREATE}
								/>
							</TableTitleBar>
							<Loader isLoading={loading} />
							<TableContainer>
								<InfiniteScroll
									dataLength={Number(allExhibitions?.length) || 0}
									next={fetchMoreData}
									hasMore={(Number(exhibitionsCount) || 0) > (Number(allExhibitions?.length) || 0)}
									loader={<h4></h4>}
								>
									{!loading && (!allExhibitions || allExhibitions?.length === 0) ? (
										<TableEmptyState
											title="There are no exhibitions yet."
											subtitle="Exhibitions allow you to save frame and artwork pairs,
                      and update a group of frames in one click."
										/>
									) : (
										<Table className="z-10">
											<thead className="bg-table-header-bg text-table-header-fg text-left text-sm uppercase tracking-wider">
												<tr>
													<th scope="col" className="sm:px-6 py-3 ">
														{/* Image */}
													</th>
													<th scope="col" className="sm:px-6 py-3 font ">
														{CONSTANT.NAME}
													</th>
													<th></th>
													<th className="sm:w-0 md:w-40 lg:w-60"></th>
													<th scope="col" className="sm:px-6 md:pr-20 py-3">
														#{CONSTANT.FRAMES}
													</th>
													<th scope="col" className="sm:px-6 py-3 font font-semibold"></th>
													<th></th>
												</tr>
											</thead>

											<tbody className="bg-indigo divide-y divide-table-border-color ">
												{allExhibitions?.map((exhibition) => (
													<TableRow key={exhibition.id}>
														<td className=" px-4 sm:px-6 ">
															<div className="flex items-center">
																<div className="flex-shrink-0 h-14 w-14">
																	{exhibition?.pairs !== null ? (
																		<img
																			className="h-14 w-14 rounded-full bg-gray-300"
																			src={
																				exhibition?.pairs[0]?.artwork
																					?.thumbImageUrl ||
																				'./images/Placeholder1.jpg'
																			}
																			alt="not found"
																		/>
																	) : (
																		<div className="h-14 w-14 rounded-full bg-gray-300"></div>
																	)}
																</div>
															</div>
														</td>
														<td className="sm:px-6  ">
															<div className="text-sm text-primary font-semibold ">
																{exhibition?.name}
															</div>
														</td>
														<td className="sm:px-6 md:pr-20">
															<a
																onClick={() =>
																	getExhibitionDetails({
																		id: exhibition.id,
																		title: exhibition.name,
																	} as DropdownOption)
																}
															>
																<div className="cursor-pointer">
																	<ChevronRightIcon className="h-6 bg-gray-100 hover:bg-gray-300 text-gray-600 hover:text-gray-900 rounded-full p-1"></ChevronRightIcon>
																</div>
															</a>
														</td>
														<td className="sm:w-0 md:w-40 lg:w-60"></td>
														<td className="sm:px-6 md:pr-20 ">
															<div className="text-sm text-table-cell-color ">
																{exhibition?.pairs?.length}
															</div>
														</td>

														<td className="sm:px-6  text-right text-sm font-medium">
															<Button
																onClick={() => pushArtworksToFrames(exhibition.id)}
																push
																loading={exhibition.id + '' === exhibitionLoading}
															>
																<ArrowSmUpIcon className="h-5 w-5 text-black" />
															</Button>
														</td>
														<td className="text-right">
															<Menu as="div" className=" inline-block text-right">
																<div>
																	<Menu.Button className="justify-center w-full px-4 py-2 bg-white text-sm font-medium  hover:bg-gray-50">
																		<DotsVerticalIcon
																			className=" h-5 text-gray-500"
																			aria-hidden="true"
																		/>
																	</Menu.Button>
																</div>
																<div className="absolute -right-3 z-50">
																	<Transition
																		as={Fragment}
																		enter="transition ease-out duration-100"
																		enterFrom="transform opacity-0 scale-95"
																		enterTo="transform opacity-100 scale-100"
																		leave="transition ease-in duration-75"
																		leaveFrom="transform opacity-100 scale-100"
																		leaveTo="transform opacity-0 scale-95"
																	>
																		<Menu.Items className="origin-top-right absolute right-0 mr-8 rounded-md z-10 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																			<div className="w-24 min-w-fit">
																				<Menu.Item>
																					<a
																						onClick={() => {
																							setSelectedExhibition(
																								exhibition
																							);
																							setShowEditModal(true);
																						}}
																						className="px-3 py-2 block bg-white rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
																					>
																						{CONSTANT.EDIT}
																					</a>
																				</Menu.Item>
																				<Menu.Item>
																					<a
																						onClick={() =>
																							onOpenDeleteModal(
																								exhibition.id + ''
																							)
																						}
																						className="px-3 py-2 block text-red-600 bg-white rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
																					>
																						{CONSTANT.DELETE}
																					</a>
																				</Menu.Item>
																			</div>
																		</Menu.Items>
																	</Transition>
																</div>
															</Menu>
														</td>
													</TableRow>
												))}
											</tbody>
										</Table>
									)}
								</InfiniteScroll>
							</TableContainer>
							<AddExhibition
								isOpen={showModal}
								onCloseModal={onCloseDialog}
								user={authn.getUser()?.id}
							></AddExhibition>
							<EditExhibition
								isOpen={showEditModal}
								onCloseEditModal={onEditExhibition}
								exhibition={selectedExhibition}
							></EditExhibition>
							<DeleteModal
								title={CONSTANT.EXHIBITION}
								onCloseDeleteModal={(value: boolean) => onCloseDeleteModal(value)}
								isOpen={showDeleteModal}
							></DeleteModal>
						</div>
					) : (
						<div>
							{/* <ExhibitionDetails
                closeExhibitionDetails={() => onCloseExhibition()}
                exhibition={exhibition}
                onExhibitionEdit={onEditExhibition}
              ></ExhibitionDetails> */}
						</div>
					)}
				</MainContainer>
			</div>
		</>
	);
}

Exhibitions.propTypes = {};

export default Exhibitions;

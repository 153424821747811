import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

export default function PaymentCancel(): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    localStorage.removeItem("landingPageSelPlanId");
  }, []);

  return (
    <>
      <Navigate to="/pricing" state={{ from: location }} replace />
    </>
  );
}

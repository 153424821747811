import { DeviceMobileIcon as DeviceMobileOutIcon, PhotographIcon as PhotographOutIcon, DatabaseIcon as DatabaseOutIcon, ViewGridIcon as ViewGridOutIcon } from "@heroicons/react/outline";
import { DatabaseIcon, PhotographIcon, DeviceMobileIcon, ViewGridIcon } from "@heroicons/react/solid";
import { InferProps } from "prop-types";
import { useAuth } from "../../authn/authn";
import { getAccountOption } from "../../util/account";
import CustomNavLink from "../shared/CustomNavLink";
import Image from "../shared/Image";

function BottomNav(props: InferProps<typeof BottomNav.propTypes>) {

  const authn = useAuth()
  const user = authn.getUser()

  const showExhibitions = getAccountOption(authn.getAccount(), "exhibitions")

  const navigation = [
    {
      name: "Artworks", to: "/", show: true,
      icon:  <Image src={'./icons/art_unselected.svg'} className={'h-8'} title={'Artworks'} />,
      activeIcon: <Image src={'./icons/art_selected.svg'} className={'h-8'} title={'Artworks'} />
    },
    {
      name: "Collections", to: "/collections", show: true,
      icon:  <Image src={'./icons/coll_unselected.svg'} className={'h-8'} title={'Collections'} />,
      activeIcon: <Image src={'./icons/coll_selected.svg'} className={'h-8'} title={'Collections'} />
    },
    {
      name: "Exhibitions", to: "/exhibitions", show: showExhibitions,
      icon:  <Image src={'./icons/exh_unselected.svg'} className={'h-8'} title={'Exhibitions'} />,
      activeIcon: <Image src={'./icons/exh_selected.svg'} className={'h-8'} title={'Exhibitions'} />
    },
    {
      name: "Frames", to: "/frames", show: true,
      icon:  <Image src={'./icons/frames_unselected.svg'} className={'h-8'} title={'Frames'} />,
      activeIcon: <Image src={'./icons/frames_selected.svg'} className={'h-8'} title={'Frames'} />
    }
  ];
  return (
    <section id="bottom-navigation" className="block w-full md:hidden fixed inset-x-0 bottom-0 z-30 bg-white shadow">
      <div id="tabs" className="flex justify-between">
        {navigation.map((item) => {
          return item.show ? (
            <CustomNavLink
              key={item.name}
              to={item.to}
              name={item.name}
              icon={item?.icon}
              activeIcon={item?.activeIcon}
            />
          ) : null;
        })}
      </div>
    </section>
  );
}

BottomNav.propTypes = {};

export default BottomNav;

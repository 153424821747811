const DebugMode = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const isDebug = localStorage.getItem('zf_debug') === "1"
  return isDebug ? <>{children}</> : null;
};

export default DebugMode;

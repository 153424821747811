import PropTypes, { InferProps } from "prop-types";
import Button from "./Button";
import TableTitle from "./TableTitle";

function TableBannerWithSearch({
    title,
    count,
    searchHandler,
    addHandler,
    buttonTitle,
    buttonTitleSmallSize,
    secondaryButtonTitle,
    secondaryButtonHandler,
    secondaryButtonDisabledTitle,
    secondaryButtonLoading
}: InferProps<typeof TableBannerWithSearch.propTypes>) {

    const secondaryButtonText = secondaryButtonDisabledTitle ? secondaryButtonDisabledTitle : secondaryButtonTitle;
    const secondaryButtonHandlerFunction = secondaryButtonDisabledTitle ? () => {} : secondaryButtonHandler;
    const secondaryButtonDisabled = secondaryButtonDisabledTitle ? true : false;
    const secondaryButtonLoadingVal = secondaryButtonLoading ? true : false;

    return (
            <div className="grid px-3 md:px-4 lg:px-6 grid-cols-3 gap-3 mt-4 mb-4 md:mb-3 md:mt-16">
                <TableTitle
                    title={title}
                    count={count}
                    className={'md:col-span-1 col-span-3 text-left'}
                />
                <div className="md:col-span-2 col-span-3 ">
                    <div className="flex">
                        <div className="flex-1 md:flex justify-end">

                                <form onSubmit={searchHandler}>
                                    <div className="flex border border-bc bg-white rounded-md mr-4">
                                        <input
                                            name="search"
                                            type="text"
                                            className="px-4 py-2 w-full md:w-80 border-0 bg-white text-black font-base rounded-md font-weight-700 sm:text-md"
                                            placeholder="Search by artist or title"
                                        />
                                        <button
                                            type="submit"
                                            className="flex items-center justify-center px-4 border-l border-bc"
                                        >
                                            <svg
                                                className="w-6 h-6 text-search-icon"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                                            </svg>
                                        </button>
                                    </div>
                                </form>
                        </div>
                        { secondaryButtonText && secondaryButtonHandler && (
                            <Button secondary loading={secondaryButtonLoadingVal} disabled={secondaryButtonDisabled} onClick={secondaryButtonHandlerFunction} className="mr-4">
                                <span>{secondaryButtonText}</span>
                            </Button>
                        )}
                        <Button primary onClick={() => addHandler()} >
                            <span className="md:hidden">{buttonTitleSmallSize}</span>
                            <span className="hidden md:inline-block ">{buttonTitle}</span>
                        </Button>
                    </div>
                </div>
            </div>
    );
}

TableBannerWithSearch.propTypes = {
    title: PropTypes.string,
    count: PropTypes.number,
    searchHandler: () => void {},
    addHandler: () => void {},
    buttonTitle: PropTypes.string,
    buttonTitleSmallSize: PropTypes.string,
    secondaryButtonTitle: PropTypes.string,
    secondaryButtonDisabledTitle: PropTypes.string,
    secondaryButtonLoading: PropTypes.bool,
    secondaryButtonHandler: () => void {},
};

export default TableBannerWithSearch;

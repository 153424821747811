import { Navigate, useLocation } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useGetMe } from "../hooks/user/useGetMe";
import Loader from "../components/shared/loader";
import { getAccountOption } from "../util/account";
import { GENERAL_TOAST_CONSTANT, SYNC_NFTS_CONSTANT } from "../static/Enums";
import { generalToastContext } from "../contexts/general-toast-context-provider";
import { getDateDifferenceIndays } from "../util/date";
import { getCurrentPlan, getPlanIndex } from "../util/frames";

const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;

interface AuthContextType {
  // user: any;
  getUser: () => any;
  getAccount: () => any;
  getAccountId: () => any;
  getSubscription: () => any;
  getSelectedSubscriptionPlan: () => any;
  // fetchUser: () => void;
  signin: () => void;
  signout: () => void;
  refetch: () => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
  return React.useContext(AuthContext);
}

// AuthProvider is a component that wraps the entire app and provides the auth context
// to all of its children. On initial load, it will fetch the current user.
// TODO: integrate with Apollo client local state
export function AuthProvider({ children }: { children: React.ReactNode }) {
  // let [user, setUser] = React.useState<any>(null);

  let { loading, error, user, refetch } = useGetMe();

  let signin = () => {
    refetch();
  };

  let signout = () => {
    return fetch(`${strapiUrl}/authn/logout`, {
      method: "POST",
      // requied in order to store/send auth cookie
      credentials: "include",
    });
  };

  let getUser = () => {
    return user;
  };

  let getAccount = () => {
    return user.account;
  };

  // Convenience function to get the account ID
  let getAccountId = () => {
    return user.account?.id;
  };

  // Convenience function to get the user subscription
  let getSubscription = () => {
    return user.account?.subscription;
  };

  // modify the subscription data and return selected plan detail
  let getSelectedSubscriptionPlan = () => {
    const subscription = user.account?.subscription;
    if (!subscription) return null;

    const { stripeCurrentPeriodEnd, plan, status, stripeCancelAtPeriodEnd } =
      subscription;

    const periodEndDate = new Date(stripeCurrentPeriodEnd);
    const daysLeft = getDateDifferenceIndays(periodEndDate);
    return {
      currentPeriodEnd: periodEndDate.toDateString(),
      plan: plan?.name,
      planId: plan?.stripeProductId,
      subscriptionStatus: status,
      daysLeft,
      subsCancelAtEnd: stripeCancelAtPeriodEnd,
    };
  };

  let value = {
    getUser,
    getAccount,
    getAccountId,
    getSubscription,
    signin,
    signout,
    refetch,
    getSelectedSubscriptionPlan,
  };

  // No need to do anything with the error — if the user is null, <RequireAuthn ...> will send to /login
  // if (error) {
  //   console.log(error)
  //   return <div>Error</div>
  // }

  if (loading && !user) {
    return <Loader isLoading />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// RequireAuthn is a component that requires the user to be logged in. If the user is not logged in,
// it will redirect to /login.
export function RequireAuthn({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const myParam = params.get("selectedPlan");
  if (myParam) localStorage.setItem("landingPageSelPlanId", myParam);

  if (!auth?.getUser()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export function CheckKioskMode({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  if (auth.getUser().isKioskModeOn) {
    // Redirect them to the pricing page, to complete the subscription process, if the user not subscribed yet
    return <Navigate to="/kiosk-mode" state={{ from: location }} replace />;
  }
  
  return children;
}

export function CheckUnKioskMode({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.getUser().isKioskModeOn) {
    // Redirect them to the pricing page, to complete the subscription process, if the user not subscribed yet
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export function RequireSubscription({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const { setGeneralToast, setSelectedPlan } = useContext(generalToastContext);
  const selectedPlan = auth?.getSelectedSubscriptionPlan();
  const notActiveOrTrialing =
    selectedPlan?.subscriptionStatus !== "active" &&
    selectedPlan?.subscriptionStatus !== "trialing";

  useEffect(() => {
    const handlePayment = () => {
      if (!auth) return;
      if (selectedPlan && !notActiveOrTrialing) {
        const { currentPeriodEnd, planId } = selectedPlan;
        const prevPlan = localStorage.getItem("prevPlan");
        const parsedPrevPlan =
          prevPlan !== "undefined" ? JSON.parse(prevPlan ?? "{}") : null;
        if (
          parsedPrevPlan?.planId &&
          parsedPrevPlan?.planId !== selectedPlan?.planId
        ) {
          const toastMessage =
            getPlanIndex(parsedPrevPlan?.planId) > getPlanIndex(planId)
              ? GENERAL_TOAST_CONSTANT.DOWNGRADING
              : GENERAL_TOAST_CONSTANT.UPGRADE;
          setGeneralToast(toastMessage);
        } else if (currentPeriodEnd) {
          if (!localStorage.getItem("isFirstTime")) {
            setGeneralToast(GENERAL_TOAST_CONSTANT.WELCOME);
          }
          localStorage.setItem("isFirstTime", "yes");
        }
        setSelectedPlan(selectedPlan);
        localStorage.removeItem("landingPageSelPlanId");
        localStorage.setItem("prevPlan", JSON.stringify(selectedPlan));
      }
    };

    handlePayment();
  }, [auth]);

  const handleSubscriptionCheck = useCallback(() => {
    // Leaving in for reference, this is the original check
    // if (!auth || !auth.getAccountId() || !selectedPlan?.daysLeft || selectedPlan.daysLeft <= 0 || notActiveOrTrialing) {

    // if user isn't logged in, or doesn't have an account, or has a plan that is not active or trialing, redirect to pricing page
    // NOTE: we're ignoring the daysLeft check here, as there's potential for a user to have a plan that is active or trialing, but has no days left
    // because the subscription on our server hasn't been updated yet from stripe... better to allow a bit of extra use than to lock them out when they
    // shouldn't be.
    if (!auth || !auth.getAccountId() || notActiveOrTrialing) {
      // console.log('Redirecting to /pricing', selectedPlan, notActiveOrTrialing);
      return <Navigate to="/pricing" state={{ from: location }} replace />;
    }
    return children;
  }, [auth, selectedPlan]);

  return handleSubscriptionCheck();
}

export function UnSubscribedPages({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const selectedPlan = auth?.getSelectedSubscriptionPlan();
  const location = useLocation();

  // true if user's subscription is active or trialing
  const activeOrTrialing =
    selectedPlan?.subscriptionStatus === "active" ||
    selectedPlan?.subscriptionStatus === "trialing";

  useEffect(() => {
    const handleLandRedirection = () => {
      if (!auth) return;

      const selectedPlanId = localStorage.getItem("landingPageSelPlanId");
      const parsedPlanId =
        selectedPlanId !== "undefined" ? selectedPlanId : null;

      if (parsedPlanId && !selectedPlan) {
        const { uuid, email } = auth.getUser();
        const paymentLink = getCurrentPlan(parsedPlanId);
        window.open(
          `${strapiUrl}/stripe/create-checkout-session/${paymentLink?.price.monthly.priceId}/${email}`,
          "_self"
        );
      }
    };

    handleLandRedirection();
  }, [auth]);

  const handleSubscriptionCheck = useCallback(() => {
    // console.log('selectedPlan', selectedPlan, activeOrTrialing);
    // if user has a plan that is active or trialing, redirect to pricing page
    if (selectedPlan && activeOrTrialing)
      return <Navigate to="/" state={{ from: location }} replace />;
    return children;
  }, [auth, selectedPlan]);

  return handleSubscriptionCheck();
}
/* AuthorizedRoute is a component that requires the user to have a specific permission via an 'enabledOption' in order to view a route.
e.g. usage:
<Route
  path="exhibitions"
  element={<AuthorizedRoute enabledOption="enableExhibitions"><Exhibitions /></AuthorizedRoute>}
/>

In the above example, the user must have the 'enableExhibitions' flag set to true on their account in order to view the exhibitions page.
*/
export function AuthorizedRoute({
  // path,
  // element,
  children,
  enabledOption,
}: {
  // path: string;
  // element: JSX.Element;
  children: JSX.Element;
  enabledOption: string;
}) {
  let auth = useAuth();
  let location = useLocation();

  if (!getAccountOption(auth.getAccount(), enabledOption)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
  // return <Route path={path} element={element} />;
}

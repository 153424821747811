import { Fragment, useContext, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import PropTypes, { InferProps } from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { Link } from "react-router-dom";
import { useAuth } from "../../../authn/authn";
import { useGetFrames } from "../../../hooks/frames/useGetFrames";
import { selectedFrameIdVar } from "../../../states/topNavFrames";
import ArtworkThumb from "../../shared/thumbnails/ArtworkThumb";
import { generalToastContext } from "../../../contexts/general-toast-context-provider";
import { orderBy } from "lodash";
import useAccountLimitation from "../../../hooks/frames/useAccountLimitation";
import UpgradePlanModal from "../../modal/UpgradePlanModal";

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(" ");
}

function FrameSelector(props: InferProps<typeof FrameSelector.propTypes>) {
  const authn = useAuth();
  const selectedFrameId = useReactiveVar(selectedFrameIdVar);
  const [selected, setSelected] = useState<any>(null);
  const [subInfoDialog, setSubInfoDialog] = useState<boolean>(false);
  const { frameLimit, frameConsumed } = useAccountLimitation();
  const { selectedPlan } = useContext(generalToastContext);

  // On mount, get the frames — this UI will need adjustment eventually since we don't actually
  // want to fetch all the frames if there are a lot of them.
  const { loading, error, frames } = useGetFrames({
    accountId: authn?.getAccountId(),
    limit: 100,
    offset: 0,
    sort: "name:ASC",
  });

  //
  useEffect(() => {
    if (frames?.length > 0) {
      if (selectedFrameId) {
        const selectedFrame = frames.find(
          (frame: any) => frame.id === selectedFrameId
        );
        if (selectedFrame) {
          setSelected(selectedFrame);
        } else {
          selectedFrameIdVar(frames[0].id);
        }
      } else {
        selectedFrameIdVar(frames[0].id);
      }
    }
  }, [frames, selectedFrameId]);

  // When the selected frame changes, update the selected frame id reactive var and val in localStorage so that
  // it can be persisted across page refreshes.
  const onSelectFrame = (e: any) => {
    if (e?.index + 1 > frameLimit) {
      setSubInfoDialog(true);
    } else {
      localStorage.setItem("selectedFrameId", e.id);
      selectedFrameIdVar(e.id);
    }
  };

  const closeSubInfoModal = () => {
    setSubInfoDialog(false);
  };
  const getSelectedArtworkDisplayName = () => {
    if (selected?.artwork) {
      return selected.artwork.title;
    }
    if (selected?.artworkCollection) {
      return selected.artworkCollection.name;
    }
    return "";
  };

  // TODO: loading state
  // if (loading) {
  //   console.log('loading!', loading)
  //   return null;
  // }
  if (frames?.length === 0) {
    return (
      <div className="bg-gray-900  px-3 py-2 rounded-md text-sm font-normal w-52 text-center ">
        <p className="text-[#9DA5B7] ">
          To display artworks,{" "}
          <Link to="/frames" className="underline">
            create your first frame
          </Link>
          .
        </p>
      </div>
    );
  }

  return (
    <>
      <Listbox value={selected} onChange={onSelectFrame}>
        {({ open }) => (
          <div className="relative">
            <Listbox.Button
              className={`relative kiosk-frame-selector-box
               rounded-3xl p-4 shadow-sm bg-gray-900 text-left cursor-default pr-12
              focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 `}
            >
              <span className="flex items-center">
                {/* <img
                src={getArtworkThumbnailSrc(selected)}
                alt=""
                className="flex-shrink-0 h-10 w-10 rounded-md"
              />  */}

                <span className="">
                  <ArtworkThumb
                    artwork={
                      selected?.artwork
                        ? selected.artwork
                        : selected?.artworkCollection?.artworks[0]?.artwork
                    }
                    roundedClass="rounded-xl"
                    height="kiosk-frame-image"
                  />
                </span>
                <span className="w-5/6 pr-12">
                  <div
                    className={`ml-3 block text-white truncate font-bold kiosk-font-2xl`}
                  >
                    {selected?.name || "select frame"}
                  </div>
                  {getSelectedArtworkDisplayName() && (
                    <div className="ml-3 kiosk-font-xl block text-gray-400 truncate w-full">
                      {getSelectedArtworkDisplayName()}
                    </div>
                  )}
                </span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className={`kiosk-frame-box-icon text-white`}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-72 w-full bg-white shadow-lg  rounded-3xl ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
                {frames?.map((frame: any, index: number) => (
                  <Listbox.Option
                    key={frame?.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-pointer select-none relative p-4 pr-9",
                        index + 1 > frameLimit ? "opacity-50" : ""
                      )
                    }
                    value={{ ...frame, index }}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {frame?.artwork || frame?.artworkCollection ? (

                            <span className="">
                              <ArtworkThumb
                                artwork={
                                  frame?.artwork
                                    ? frame?.artwork
                                    : frame.artworkCollection?.artworks[0]
                                        ?.artwork
                                }
                                roundedClass="rounded-xl"
                                height="kiosk-frame-image"
                                width="w-10"
                              />
                            </span>
                          ) : (
                            <img
                              src="./images/Placeholder1.jpg"
                              alt=""
                              className="flex-shrink-0 kiosk-frame-image rounded-md"
                            />
                          )}
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block kiosk-font-2xl max-h-[40px] truncate"
                            )}
                          >
                            {frame?.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>

      <UpgradePlanModal
        isModalOpen={subInfoDialog}
        onCloseDialog={closeSubInfoModal}
        selectedPlan={selectedPlan}
        limit={frameLimit}
        type="frame"
        consumed={frameConsumed}
      />
    </>
  );
}

FrameSelector.propTypes = {
  // frames: PropTypes.any,
  selectedFrameId: PropTypes.number,
};

export default FrameSelector;

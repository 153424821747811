import { gql, useMutation } from "@apollo/client";

interface deleteWalletInput {
  variables: {
    input: {
      where: {
        id: string;
      };
    };
  };
}

const DELETE_ARTWORK = gql`
  mutation DeleteWallet($input: deleteWalletInput) {
    deleteWallet(input: $input) {
      wallet {
        id
      }
    }
  }
`;

export const useDeleteWallet = (): ((
  deleteWallet: deleteWalletInput
) => any) => {
  const [deleteWallet, { loading: mutationLoading, error: mutationError }] =
    useMutation(DELETE_ARTWORK, {
      update(cache, { data: { deleteWallet } }) {
        cache.modify({
          fields: {
            wallets(existingWallets = [], { readField }) {
              return existingWallets.filter(
                (wallet: any) =>
                  deleteWallet.wallet.id !== readField("id", wallet)
              );
            },
          },
        });
      },
    });
  return deleteWallet;
};

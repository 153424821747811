import { CONSTANT } from "../../static/constants";
import PropTypes, { InferProps } from "prop-types";
import logo from "../../assets/logo-black.svg";

function Loader(props: InferProps<typeof Loader.propTypes>) {
  return props.isLoading ? (
    <div className="bg-white bg-opacity-50 fixed bottom-0 top-0 left-0 right-0 flex justify-center items-center z-40">
      {/* <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-purple-500"></div> */}
      <img className="block h-6 animate-pulse" src={logo} alt="Zeroframe" />
      {/* <label className="block pl-2 text-gray-500">{CONSTANT.LOADING}</label> */}
    </div>
  ) : null;
}

Loader.propTypes = {
  isLoading: PropTypes.bool,
};

export default Loader;

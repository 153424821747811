import { Dialog } from "@headlessui/react";
import React from "react";
import { CONSTANT } from "../../static/constants";
import Button from "../shared/Button";
type Props = {
  title: string;
  subTitle?: string;
  // All other props
  [x: string]: any;
};

const ModalHeader: React.FC<Props> = (props) => {
  const { title, subTitle, ...rest } = props;
  return (
    <div {...rest} className="mb-9 mt-2">
      <Dialog.Title
        className="text-2xl leading-6 font-bold text-gray-900 text-center"
        as="h3"
      >
        {title}
      </Dialog.Title>
      {subTitle && (
        <Dialog.Title className="text-md text-gray-500 text-center" as="p">
          {subTitle}
        </Dialog.Title>
      )}
    </div>
  );
};

export default ModalHeader;

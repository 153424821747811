import { useQuery, gql } from "@apollo/client";

export type GetCollectionDetails = {
  accountId: string;
  collectionId: string;
  limit?: number;
  offset?: number;
  sort?: string;
  id?: string;
  search?: string;
};

export const GET_COLLECTION_DETAILS = gql`
  query GetCollectionDetails(
    $accountId: ID!
    $collectionId: String
    $limit: Int
    $offset: Int
    $sort: String
    $id: String
    $search: String
  ) {
    collections(
      where: { account: $accountId, id: $id, _or: [{ name_contains: $search }] }
      sort: $sort
    ) {
      id
      name
      interval
      artworks(limit: $limit, start: $offset, sort: "position") {
        id
        position
        artwork {
          creatorAddress
          mediaType
          thumbImageUrl
          display
          id
          title
          creatorUsername
          source
          mimeType
          fileSize
        }
      }
      creator {
        id
        username
      }
      frames {
        id
      }
      updated_at
    }

    collectionArtworksCount(where: { sourceCollection: $collectionId })
  }
`;

export const useGetCollectionDetails = ({
  collectionId,
  accountId,
  limit,
  offset,
  sort,
  id,
  search,
}: GetCollectionDetails) => {
  const { loading, error, data, fetchMore } = useQuery(GET_COLLECTION_DETAILS, {
    variables: { collectionId, accountId, limit, offset, sort, id, search },
    fetchPolicy: "cache-and-network",
  });
  // console.log(error);
  return {
    collections: data?.collections,
    frames: data?.frames,
    collectionsCount: data?.collectionArtworksCount,
    fetchMore,
    loading,
    error,
  };
};

import { XIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { ReactNode } from "react";

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  dialogClass?: string;
}

const DefaultDialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  children,
  dialogClass,
}) => {
  const overlayStyles = isOpen
    ? "fixed top-0 left-0 w-full  h-full bg-black bg-opacity-50 z-50 flex justify-center items-center"
    : "hidden";

  return (
    <div className={overlayStyles} onClick={onClose}>
      <div
        className={classNames("bg-white  rounded-lg shadow-md", dialogClass)}
        onClick={(e) => e.stopPropagation()}
      >
        <XIcon
          onClick={onClose}
          className="h-10 w-10 text-gray-500 float-right cursor-pointer pt-2 pr-4"
        />
        <div>{children}</div>
      </div>
    </div>
  );
};

export default DefaultDialog;

import { Color, ColorPicker, toColor, useColor } from "react-color-palette";
import { Popover } from "@headlessui/react";
import PropTypes, { InferProps } from "prop-types";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import clsx from "clsx";
//@ts-ignore
import { WidgetLoader, Widget } from "react-cloudinary-upload-widget";

// import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CONSTANT } from "../../static/constants";
import { display } from "../../static/Enums";

import "react-color-palette/lib/css/styles.css";
import "../../styles/scss/artwork-dialog.scss";
import Modal from "./Modal";
import ArtworkThumb from "../shared/thumbnails/ArtworkThumb";
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/outline";
import { useAuth } from "../../authn/authn";
import { formatBytes } from "../../util/strings";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import InputField from "../shared/InputField";
import SelectField from "../shared/SelectField";
import isEmpty from "lodash/isEmpty";
import Alerts from "../shared/Alert";
import ArtworkCollectionThumb from "../shared/thumbnails/ArtworkCollectionThumb";
import { Artwork } from "../model/Artwork";

const cloudinaryId = process.env.REACT_APP_CLOUDINARY_ID;
const cloudinaryPreset = process.env.REACT_APP_CLOUDINARY_PRESET;
const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;

const initialCloudinaryState = {
  mediaUrl_original: "",
  artworkUrl: "",
  thumbImageUrl: "",
  mediaType: "",
  ext: "",
  fileSize: "",
  width: 0,
  height: 0,
  mediaUrl_720: "",
  mediaUrl_1080: "",
  mediaUrl_4k: "",
  aspectRatio: "",
  source: "",
  assetProviderId: "",
  mimeType: "",
};

function AddEditArtworkModal(
  props: InferProps<typeof AddEditArtworkModal.propTypes>
) {
  const [color, setColor] = useColor("hex", "#000000");
  const [bgColor, setBgColor] = useState("#000000");
  const schema = yup.object().shape({
    title: yup.string(),
    creatorUsername: yup.string(),
    qrCodeUrl: yup.string().url(),
    display: yup.string().required(),
    bgColor: yup.string().required(),
  });
  const [cloudinaryImage, setCloudinaryImage] = useState(false);
  const [cloudinaryImageName, setCloudinaryImageName] = useState("");
  const [uploadButtonDisable, setUploadButtonDisable] = useState(false);
  const [addUrlButtonDisable, setAddUrlButtonDisable] = useState(false);
  const [cloudinaryImageThumbnail, setCloudinaryImageThumbnail] = useState("");

  const authn = useAuth();
  const userObj = authn.getUser();
  const [cloudinaryData, setCloudinaryData] = useState(initialCloudinaryState);
  const [cloudinaryDataArray, setCloudinaryDataArray] = useState<any>([]);

  // validation
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (props.artwork) {
      setBgColor(props.artwork?.bgColor);
      const color = toColor("hex", props.artwork?.bgColor);
      setColor(color);
      reset({
        id: props.artwork?.id,
        title: props.artwork?.title,
        qrCodeUrl: props.artwork?.qrCodeUrl,
        creatorUsername: props.artwork?.creatorUsername,
        display: props.artwork?.display,
        source: props.artwork?.source,
      });

      if (props.artwork?.source !== "upload") {
        setCloudinaryImage(false);
        setUploadButtonDisable(true);
        setAddUrlButtonDisable(false);
        setValue("artUrl", props.artwork?.artworkUrl);
      }

      if (props.artwork?.source === "upload") {
        setCloudinaryImage(true);
        setUploadButtonDisable(false);
        setAddUrlButtonDisable(true);
        setValue("artUrl", "");
        const thumbImageUrl52 = props.artwork?.thumbImageUrl;
        setCloudinaryImageThumbnail(thumbImageUrl52);
        setCloudinaryImageName(props.artwork?.title);
        setCloudinaryData({
          ...props.artwork,
        });
      }

    } else {
      // 'New' artwork modal
      setCloudinaryImage(false);
      setUploadButtonDisable(false);
      setAddUrlButtonDisable(false);
      setBgColor("#000000");
      const color = toColor("hex", "#000000");
      setColor(color);
      reset({
        title: "",
        creatorUsername: "",
        artUrl: "",
        qrCodeUrl: "",
        display: "fit",
        bgColor: "#000000",
      });
    }

  }, [props.artwork, props.isOpen]);

  const getModifiedArtworks = (data: any) => {
    if (props.artwork) {
      return getCloudinaryPart(cloudinaryData, data)
    }
    else {
      if (!cloudinaryImage) {
        return [getCloudinaryPart(cloudinaryData, data)]
      }
      else {
        return cloudinaryDataArray.map((item: any) => ({ ...getCloudinaryPart(item, data) }))
      }
    }
  }

  const getCloudinaryPart = (item: any, data: any) => {
    const artwork: any = {
      id: data.id,
      creatorUsername: data.creatorUsername,
      display: data.display,
      bgColor: data.bgColor,
      title: data.title || item.filename,
      creator: props.user,
      source: item.source || "url",
      qrCodeUrl: data.qrCodeUrl,
      artworkUrl: data.artUrl !== '' ? data.artUrl : item.artworkUrl,
      mediaUrl_720: item?.mediaUrl_720,
      mediaUrl_1080: item?.mediaUrl_1080,
      mediaUrl_4k: item?.mediaUrl_4k,
      aspectRatio: item?.aspectRatio,
      mediaUrl_original: item?.mediaUrl_original,
      ext: item?.ext,
      fileSize: item?.fileSize.toString(),
      width: item?.width,
      height: item?.height,
      assetProviderId: item?.assetProviderId,
      mediaType: item?.mediaType || "unknown",
      mimeType: item?.mimeType,
    }

    // In order not to kill the thumbnail when the artwork is a URL artwork
    // with thumbImageUrl fetched on backend (e.g. with OpenSea integration), only
    // add this prop if it's populated.
    if (data.thumbImageUrl || item?.thumbImageUrl) {
        artwork.thumbImageUrl = data.thumbImageUrl || item?.thumbImageUrl
    }
    return artwork
  }

  // Form submit handler
  const onSubmit = async (data: any) => {
    // Create artwork based on form data and cloudinary upload data
    if (isEmpty(errors)) {
      const temp = getModifiedArtworks(data)
      resetModal()
      props.onCloseDialog(temp);
    }
  };

  const closeDialog = async () => {
    // if cloudinary upload has completed but we don't save the artwork, delete the asset
    resetModal()
    if (!props.artwork) {
      deleteFromCloudinary();
    }
    props.onCloseDialog(null);
  };

  /**
   * Change color value from input
   */
  const changeColorValue: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => setBgColor(value);

  /**
   * Format bg color on input blur
   */
  const formatColorOnBlur = () => {
    const color = toColor("hex", bgColor);
    setValue("bgColor", color.hex);
    setBgColor(color.hex);
    setColor(color);
  };

  /**
   * Update color value
   */
  const updateColorValue = (color: Color) => {
    setValue("bgColor", color.hex);
    setBgColor(color.hex);
    setColor(color);
  };

  // Cloudinary success callback
  const successCallBack = (data: any) => {
    if (data.event === "success") {
      const mediaType = data.info.resource_type;
      const artworkUrl = `https://res.cloudinary.com/${cloudinaryId}/${data.info.resource_type
        }/upload/q_auto/f_auto/${data.info.path!}`;
      const fileSize = data.info.bytes;
      const ext = data.info.url.split(".").pop();
      const mediaUrl_original = data.info.secure_url;
      const mediaUrl_720 = `https://res.cloudinary.com/${cloudinaryId}/${data.info.resource_type
        }/upload/c_limit,h_720/q_auto/f_auto/${data.info.path!}`;
      const mediaUrl_1080 = `https://res.cloudinary.com/${cloudinaryId}/${data.info.resource_type
        }/upload/c_limit,h_1080/q_auto/f_auto/${data.info.path!}`;
      const mediaUrl_4k = `https://res.cloudinary.com/${cloudinaryId}/${data.info.resource_type
        }/upload/c_limit,h_3840/q_auto/f_auto/${data.info.path!}`;
      const gcd: any = (a: any, b: any) => {
        return b == 0 ? a : gcd(b, a % b);
      };
      const width = data.info.width;
      const height = data.info.height;
      const r = gcd(width, height);
      const aspectRatio = width / r + ":" + height / r;
      const thumbImageUrl = `https://res.cloudinary.com/${cloudinaryId}/${data.info.resource_type}/upload/c_limit,h_160,w_160/v${data.info.version}/${data.info.public_id}.jpg`;
      const thumbImageUrl52 = `https://res.cloudinary.com/${cloudinaryId}/${data.info.resource_type}/upload/c_limit,h_52,w_52/v${data.info.version}/${data.info.public_id}.jpg`;
      const mimeType = `${mediaType}/${data.info?.format}`;
      const filename = data.info.original_filename + "." + data.info.url.split(".").pop();
      const temp = {
        mediaType: mediaType,
        artworkUrl: artworkUrl,
        fileSize: fileSize,
        ext: ext,
        mediaUrl_original: mediaUrl_original,
        mediaUrl_720: mediaUrl_720,
        mediaUrl_1080: mediaUrl_1080,
        mediaUrl_4k: mediaUrl_4k,
        width: width,
        height: height,
        aspectRatio: aspectRatio,
        thumbImageUrl: thumbImageUrl,
        mimeType: mimeType,
        assetProviderId: data.info.public_id,
        source: "upload",
        filename: filename,
      }
      // const mimeType

      setCloudinaryData(temp);
      setAddUrlButtonDisable(true);
      setCloudinaryImage(true);
      setCloudinaryImageThumbnail(thumbImageUrl52);
      setCloudinaryImageName(filename);
      if (!props.artwork) {
        setCloudinaryDataArray((artwork: Array<Artwork>) => {
          if (Array.isArray(artwork)) {
            if (!(artwork?.filter(item => item.artworkUrl === temp.artworkUrl).length > 0)) {
              return [...artwork!, temp]
            } else {
              return [...artwork]
            }
          }
        })
      }

    }
  };

  const artUrlChangeHandler = (val: string) => {
    if (val) {
      setUploadButtonDisable(true);
    } else {
      setUploadButtonDisable(false);
    }
  };

  const resetCloudinaryImageHandler = () => {
    // TODO: if the user is editing an existing artwork, clicking the Trash icon shouldn't delete the artwork from cloudinary
    // since the user can still cancel out. If they then upload a new asset, we _should_ delete the old one, but currently aren't.

    if (!props.artwork) {
      deleteFromCloudinary();
    }
    else {
      resetModal()
    }
  };

  const deleteFromCloudinary = async () => {
    if (cloudinaryImage) {
      const res = await Promise.all(cloudinaryDataArray?.map(async (item: any) => {
        if (item.assetProviderId) {
          return await fetch(
            `${strapiUrl}/artworks/cloudinary/${encodeURIComponent(
              item.assetProviderId
            )}`,
            {
              method: "DELETE",
              credentials: "include",
            }
          );
        }
      }))
      if (res) {
        setCloudinaryDataArray([])
      }
    }
  };

  // If user is above storage limit, restrict upload
  const canUpload = () => {
    if (props.storageLimit == null || props.storageUsed == null) {
      return true;
    }
    return props.storageLimit > 0 && props.storageUsed < props.storageLimit;
  };


  const resetModal = () => {
    setAddUrlButtonDisable(false);
    setCloudinaryImage(false);
    setCloudinaryImageThumbnail("");
    setCloudinaryImageName("");
    setCloudinaryDataArray([]);
    setCloudinaryData(initialCloudinaryState);
  }

  return (
    <Modal
      title={props?.artwork ? CONSTANT.EDIT_ARTWORK : CONSTANT.ADD_ARTWORK}
      onCancel={closeDialog}
      formId="edit-artwork"
      isOpen={props.isOpen}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        method="POST"
        action="#"
        id="edit-artwork"
      >
        <div className="overflow-visible sm:rounded-md ">
          <div className="mb-10">
            <div className="flex flex-col flex-1 gap-6  w-full">
              {/* Upload input */}
              {canUpload() && (
                <>
                  {cloudinaryImage ? (
                    <div className="border border-gray-300 p-1 rounded-md">
                      <div className="flex items-center justify-between">
                        {
                          (cloudinaryDataArray?.length > 1) ?
                            <ArtworkCollectionThumb src={cloudinaryDataArray} />
                            :
                            <ArtworkThumb src={cloudinaryData.thumbImageUrl} />
                        }
                        <p>{(cloudinaryDataArray?.length > 1) ? CONSTANT.BULK_ARTWORKS : cloudinaryImageName}</p>
                        <div onClick={resetCloudinaryImageHandler}>
                          <TrashIcon className="h-5 w-5 mr-2 text-gray-400 cursor-pointer" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <WidgetLoader />
                      <Widget
                        sources={["local", "dropbox", "google_drive"]} // set the sources available for uploading -> by default
                        resourceType={"auto"} // optionally set with 'auto', 'image', 'video' or 'raw' -> default = 'auto'
                        cloudName={cloudinaryId} // your cloudinary account cloud name.
                        uploadPreset={cloudinaryPreset} // check that an upload preset exists and check mode is signed or unisgned
                        folder={userObj?.username || "unknown-user"}
                        buttonText={"Upload file"} // default 'Upload Files'
                        cropping={false} // set ability to crop images -> default = true
                        onSuccess={successCallBack} // add success callback -> returns result
                        onFailure={resetCloudinaryImageHandler} // add failure callback -> returns 'response.error' + 'response.result'
                        logging={false} // logs will be provided for success and failure messages,
                        // apiKey={00000000000000} // cloudinary API key -> number format
                        accepts={"application/json"} // for signed uploads only -> default = 'application/json'
                        contentType={"application/json"} // for signed uploads only -> default = 'application/json'
                        withCredentials={false} // default = true -> check axios documentation for more information
                        unique_filename={true} // setting it to false, you can tell Cloudinary not to attempt to make
                        style={{}}
                        className="w-auto inline-flex justify-center items-center rounded-md shadow-sm px-4 py-4 font-semibold hover:bg-gray-200 text-gray-700 border border-gray-300 text-sm disabled:bg-gray-100 disabled:text-gray-400 hover:bg-gray-200"
                        disabled={uploadButtonDisable}
                        // maxFileSize={300000000} // max file size in bytes
                        // maxImageFileSize={20971500} // max image file size in bytes
                        // maxVideoFileSize={314573000} // max video file size in bytes
                        showPoweredBy={false}
                        showAdvancedOptions={false}
                        multiple={props.artwork ? false : true}
                      // destroy={true}
                      />
                    </>
                  )}
                  <div className="relative">
                    <p className="absolute bottom-0 right-0 block text-sm font-bold text-gray-400">
                      Max 40Mb per image, 4Gb per video
                    </p>
                  </div>
                </>
              )}
              {!canUpload() && (
                <div className="w-full bg-indigo-100 rounded-md p-2 text-center">
                  <span className="text-sm text-gray-700">
                    You have exceeded your account's{" "}
                    {formatBytes(props.storageLimit)} storage limit.
                  </span>
                </div>
              )}

              {
                (cloudinaryDataArray?.length > 1) &&
                <div className="border-b">
                  <Alerts
                    className={'text-[#4c4cdc] bg-[#eef2ff] text-small bg-opacity-100'}
                    text={CONSTANT.ARTWORKS_MULTIPLE_ITEMS}
                    icon={<InformationCircleIcon className="w-7" />}
                  />
                </div>
              }

              {
                !(cloudinaryDataArray?.length > 1) &&
                <div className="-mt-6 ">
                  <div className="w-full">
                    <label
                      className="block text-sm font-bold text-gray-700"
                      htmlFor="artUrl"
                    >
                      {canUpload() ? "Or add via URL / OpenSea link" : "Add via URL / OpenSea link"}
                    </label>
                    <input
                      disabled={addUrlButtonDisable}
                      className={
                        addUrlButtonDisable
                          ? "bg-gray-100 focus:ring-secondary-500 focus:border-secondary-500 border-secondary-300 mt-1 block w-full shadow-sm sm:text-sm rounded-md"
                          : "mt-1 block w-full shadow-sm sm:text-sm rounded-md focus:ring-primary-500 focus:border-primary-500 border-gray-300"
                      }
                      placeholder={"https://..."}
                      {...register("artUrl", {
                        onChange: (el) => artUrlChangeHandler(el.target.value),
                      })}
                      id="artUrl"
                      type="url"
                      required
                    />

                    <p className="text-secondary-400">{errors.artUrl?.message}</p>
                  </div>
                  <div className="relative mt-6">
                    <p className="absolute bottom-0   right-0 block text-sm font-bold text-gray-400">
                      No file size limit
                    </p>
                  </div>
                  <div className="grid gap-4 grid-cols-6 mb-6">
                    <div className="col-span-3">
                      <InputField
                        name={'title'}
                        label={CONSTANT.TITLE}
                        placeholder={CONSTANT.TITLE}
                        register={register}
                        errors={errors?.title}

                      />
                    </div>
                    <div className="col-span-3">
                      <InputField
                        name={'creatorUsername'}
                        label={CONSTANT.ARTIST}
                        placeholder={CONSTANT.ARTIST}
                        register={register}
                        errors={errors?.creatorUsername}

                      />
                    </div>
                  </div>

                  <div className="grid gap-4 grid-cols-6">
                    <div className="col-span-6">
                      <InputField
                        name={'qrCodeUrl'}
                        label={CONSTANT.QR_CODE_URL}
                        placeholder={CONSTANT.QR_CODE_URL_PLACEHOLDER}
                        register={register}
                        errors={errors?.qrCodeUrl}
                      />

                    </div>

                  </div>
                </div>
              }

              {/* Display settings title */}
              <div className="w-full">
                <span className="text-indigo-700 mt-2 block text-sm font-bold font">
                  {CONSTANT.DISPLAY_SETTINGS}
                </span>
              </div>

              {/* Scale AND Background Color */}
              <div className="grid gap-4 grid-cols-2">
                <div className="col-span-1">
                  <SelectField
                    name={'display'}
                    label={CONSTANT.SCALE}
                    register={register}
                    errors={errors?.display}
                    options={display}
                  />

                </div>
                <div className="col-span-1">

                  <div className="grid gap-4 grid-cols-4 items-center">
                    <div className="col-span-3">


                      <InputField
                        name={'bgColor'}
                        label={CONSTANT.BACKGROUND_COLOR}
                        placeholder={'#0000000'}
                        register={register}
                        errors={errors?.bgColor}
                        registerOptions={{
                          pattern: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
                          onChange: changeColorValue,
                          onBlur: formatColorOnBlur,
                          value: bgColor,
                        }}
                      />

                    </div>
                    <div className="col-span-1 pt-4">
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              style={{
                                backgroundColor: color.hex,
                              }}
                              className="color-button rounded-full shadow-sm"
                              type="button"
                            />
                            {open && (
                              <div className="color-palette-backdrop"></div>
                            )}
                            <Popover.Panel className="absolute z-10 max-w-sm px-4 drop-shadow-md sm:px-0 lg:max-w-3xl color-palette">
                              <ColorPicker
                                onChange={updateColorValue}
                                color={color}
                                height={200}
                                width={300}
                                hideHSV
                                hideRGB
                              />
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

AddEditArtworkModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  storageUsed: PropTypes.number,
  storageLimit: PropTypes.number,
  user: PropTypes.string.isRequired,
  artwork: PropTypes.any,
};

export default AddEditArtworkModal;

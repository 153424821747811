import { init, identify, Identify, setUserId, track, reset, flush } from '@amplitude/analytics-browser';

const analytics = {
    init: function(apiKey: string) {
        // reset()
        // console.log('initializing amplitude')
        init(apiKey)
    },
    setUserProp: function(key: string, value: any) {
        const identifyObj = new Identify();
        identifyObj.set(key, value);
        identify(identifyObj);
    },
    add: function(key: string, quant: number = 1) {
        const identifyObj = new Identify();
        identifyObj.add(key, quant);
        identify(identifyObj);
    },
    track: function(eventName: string, props: object = {}) {
        console.log('analytics.track called', eventName, props)
        track(eventName, props);
    },
    setUserId: function(userId: string) {
        setUserId(userId)
        flush()
    },
    reset,
    flush
}

export default analytics
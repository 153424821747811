import React, { Fragment, useEffect } from "react";
import PropTypes, { InferProps } from "prop-types";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";

import { isAddress, shortenAddress, formatBytes } from "../../util/strings";
import Dropdown from "../../components/shared/Dropdown";
import { DropdownOption } from "../../components/model/DropdownOption";
import { display } from "../../static/Enums";

import { CONSTANT } from "../../static/constants";
import ArtworkThumb from "../../components/shared/thumbnails/ArtworkThumb";
import { getBadgeForArtwork } from "../../util/artworks";

function SortableItems(props: InferProps<typeof SortableItems.propTypes>) {
  const artwork = props.artwork;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: artwork.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };


  const {hasBadge, badge} = getBadgeForArtwork(artwork);

  const className = "text-*-fg bg-*-bg".replace(
    /\*/g,
    String(badge).toLowerCase()
  );

  return (
    <tr
      ref={setNodeRef}
      style={style}
      {...attributes}
      key={artwork.id}
      className="h-16 cursor-default"
    >
      <td>
        <div className="flex items-center pl-2">
          <button className="">
            <div className="flex text-dots hover:text-gray-400" {...listeners}>
              <DotsVerticalIcon className="h-7 w-6 " />
              <DotsVerticalIcon className="h-7 w-6 relative -left-4" />
            </div>
          </button>
          <span className="text-secondary font-semibold text-sm">
            {artwork.position + 1}
          </span>
        </div>
      </td>
      <td className="md:px-6  whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 ">
            <ArtworkThumb artwork={artwork} />
          </div>
        </div>
      </td>
      <td className="md:px-6  whitespace-nowrap">
        <div className="text-sm font-semibold text-left text-secondary">
          {artwork?.title}
        </div>
        <div className="text-sm text-gray md:hidden text-left text-table-cell-color ">
          {artwork?.creatorUsername}
        </div>
      </td>
      <td className="md:px-4 lg:px-6 hidden md:table-cell whitespace-nowrap">
        <div className="text-sm text-table-cell-color ">
          {artwork?.creatorUsername}
        </div>
      </td>
      <td className=" md:px-4 lg:px-6 hidden md:table-cell md:w-11 lg:w-20"></td>
      <td className="md:px-6  whitespace-nowrap">
        <div className="text-sm">
          <span
            className={clsx(
              "inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-md",
              hasBadge && className
            )}
          >
            {badge}
          </span>
        </div>
      </td>
      <td className="md:px-4 lg:px-6 hidden md:table-cell whitespace-nowrap">
        <div className="text-sm text-table-cell-color ">
          {artwork && formatBytes(artwork.fileSize)}
        </div>
      </td>
      <td className="md:px-4 lg:px-6 hidden md:table-cell  whitespace-nowrap">
        <div className="text-sm text-table-cell-color ">
          {isAddress(artwork?.source)
            ? shortenAddress(artwork?.source)
            : artwork?.source}
        </div>
      </td>
      <td className="md:px-4 lg:px-6 hidden md:table-cell whitespace-nowrap">
        <Dropdown
          onOptionSelect={(selected: DropdownOption) =>
            props.updateDisplay(selected, artwork.id)
          }
          width={"w-20"}
          options={display}
          default={{ title: artwork.display, id: 1 }}
        ></Dropdown>
      </td>
      <td className="text-right">
        <Menu as="div" className="inline-block text-left">
          <div>
            <Menu.Button className="justify-center w-full px-4 py-2 bg-white text-sm font-medium  hover:bg-gray-50">
              <DotsVerticalIcon
                className=" h-5 text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <div className="absolute right-3 z-50">
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mr-8 rounded-md z-10 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="w-36 min-w-fit">
                  <Menu.Item>
                    <button
                      onClick={() =>
                        props.onOpenDeleteModal(artwork.artworkCollectionId)
                      }
                      className="px-3 py-2 block text-red-600 bg-white rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
                    >
                      {CONSTANT.REMOVE_ARTWORK}
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </Menu>
      </td>
    </tr>
  );
}

SortableItems.propTypes = {
  updateDisplay: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
  artwork: PropTypes.any!,
};

export default SortableItems;

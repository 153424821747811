export const CONSTANT = {
  TITLE: "Title",
  ARTIST: "Artist",
  SCALE: "Scale",
  THUMBNAIL_URL: "Thumbnail URL",
  BACKGROUND_COLOR: "Background Color",
  TYPE: "Type",
  COLLECTIONS: "Collections",
  COLLECTION: "collection",
  FRAMES: "Frames",
  CONN: "CONN.",
  CONN_DESKTOP: "CONNECTED",
  LINK: "Link",
  PLAQUE: "Plaque",
  ROTATION: "Rotation",
  DISPLAY: "Display",
  DISPLAY_SETTINGS: "Display Settings",
  DISPLAY_INFORMATION: "Plaque Info",
  DISPLAY_INFORMATION_SIZE: "Plaque Size",
  PUSH: "Push",
  NAME: "Name",
  FRAME_NAME: "Frame name",
  ARTWORKS: "Artworks",
  SYNC_NFTS: "Sync NFTs",
  SYNC_WALLET: "Sync wallet",
  SYNC_WALLETS: "Sync wallets",
  TIME: "time (s)",
  DELETE: "Delete",
  NEW_COLLECTION: "Create collection",
  NEW_EXHIBITION: "Create exhibition",
  EXHIBITIONS: "Exhibitions",
  EXHIBITION: "Exhibition",
  ADD_ARTWORK: "Add artwork",
  EDIT_ARTWORK: "Edit artwork",
  ADD_TO_COLLECTION: "Add to collection",
  ADD_ARTWORKS: "Add artworks",
  REMOVE: "Remove",
  DISPLAYING: "Displaying",
  URL: "URL",
  CONNECTED: "Connected",
  NEW_FRAME: "Create frame",
  NEXT: "Next",
  PREVIOUS: "Previous",
  SHOWING: "Showing",
  TO: "to",
  OF: "of",
  ADD: "Add",
  RESULT: "Result",
  CREATE_COLLECTION: "Create collection",
  EDIT_COLLECTION: "Edit collection",
  ADD_NEW_ARTWORK: "Add new artwork",
  ARTWORK_TITLE: "Artwork Title",
  AUTHOR: "Author",
  ARTWORK_URL: "Artwork URL",
  SAVE: "Save",
  CANCEL: "Cancel",
  REMOVE_RECORD: "Remove ",
  DELETE_RECORD: "Delete ",
  DELETE_MESSAGE: "Are you sure you want delete this ",
  PAIRING_CODE: "Pairing code",
  ADD_NEW_FRAME: "Add new frame",
  EDIT_FRAME: "Edit frame",
  PAIR_FRAME_MODAL_HEADING: "Pair display",
  PAIR_FRAME_MENU_ITEM: "Pair display",
  PAIR_SUBMIT_BUTTON: "Pair",
  ADD_ARTWORK_COLLECTION: "Add artworks to this collection",
  LOADING: "Loading ...",
  IMAGE: "Image",
  WALLETS: "Wallets",
  ADD_WALLET: "Add wallet",
  ADD_ETHEREUM_WALLET: "Add Ethereum Wallet",
  ADD_TEZOS_WALLET: "dd Tezos Wallet",
  ETHEREUM: "Ethereum",
  SOURCE: "Source",
  SIGN_IN: "Sign in",
  SIGN_IN_TITLE: "Enter your email to create a sign in link",
  SIGN_IN_DESCRIPTION: "If it's your first time, it will create a new account",
  ENTER_EMAIL: "Enter email",
  SIGN_IN_WITH_EMAIL: "Sign in with email",
  OR: "Or",
  SIGN_IN_WITH_WALLET: "Sign in with wallet",
  DESC: "desc",
  ASC: "asc",
  PAGE_SIZE: 15,
  ZEROFRAME: "ZEROFRAME",
  PRO: "PRO",
  THUMBNAIL_IMAGE: "/thumbnail.jpg",
  FRAME_STUDIO: "No Frames Created",
  FRAME: "frame",
  ARTWORK: "Artwork",
  HOME_TITLE: "Display NFTs on any screen",
  HOME_SUB_TITLE:
    "Connect your wallet. Create playlists. Manage multiple frames.",
  SIZE: "Size",
  EDIT: "Edit",
  REMOVE_ARTWORK: "Remove artwork",
  TOTAL: "Total",
  CREATE: "Create",
  CREATE_EXHIBITION: "Create exhibition",
  EXHIBITION_NAME: "Exhibition name ",
  EXHIBITION_EDIT: "Edit exhibition",
  PUSH_WHOLE_EXHIBITION: "Push whole exhibition",
  EXHIBITION_PAIR: "Exhibition frame",
  ADD_PAIR: "Add Pair",
  REMOVE_EXHIBITION_PAIR:
    "Are you sure you want to remove this frame from the exhibition?",
  REMOVE_COLLECTION_ARTWORK:
    "Are you sure you want to remove this artwork from the collection?",
  OPTIMIZE_LABEL: "Optimize media",
  QR_CODE_URL: "QR-code (optional)",
  QR_CODE_URL_PLACEHOLDER: "Link to artwork in marketplace, Opensea, etc.",
  DISPLAY_PLAQUE_SETTINGS: "Plaque display setting.",
  EDIT_EXHIBITION: "Edit exhibition",
  INTERVAL: "Interval time",
  PAIRING_CODE_OPTIONAL: "Pairing Code (optional)",
  PLAQUE_LABEL: "QR-code link (optional)",
  PAIRING_CODE_DESCRIPTION:
    "Navigate to 0fra.me in your display’s browser, and enter the pairing code here:",
  PAIRING_CODE_INFO: "You can also create the frame now and pair it later.",
  PAIRING_CODE_ERROR: "Pairing code is not entered completely",
  OPTIMIZE_MEDIA_DESCRIPTION:
    "Automatically adjusts media quality based on display size.",
  SEND_EMAIL: "Send link",
  DATA_NOT_FOUND: "Data not found.",
  TRANSITION: "Transition",
  PLAY_FULL_VIDEOS_LABEL: "Play videos through",
  PLAY_FULL_VIDEOS_DESCRIPTION:
    "Videos will play once through until the end, overriding the interval time.",
  ARTWORKS_MULTIPLE_ITEMS:
    "You’ll be able to edit the artwork and artist details later.",
  BULK_ARTWORKS: "Uploaded artworks",
  ARTWORK_UPDATE: "Artwork updated",
  ARTWORK_ADDED: "Artwork added",
  WALLET_ADDED_S: "Wallet added successfully",
  WALLET_ADDED_S_M:
    "You can add more wallets or remove the existing once in your Account page.",
  DELETE_WALLETS_TITLE: "wallet",
  DELETE_WALLETS_DESCRIPTION:
    "Are you sure you want to remove this wallet? All artworks owned by this wallet will be removed from Zeroframe.",
  SELECT_YOUR_PLAN: "Select your plan",
  TRIAL_PLAN: "7-day free trial included in all plans.",
  START_TRAIL_BUTTON: "Start your free trial",
  NON_AND_COM_USE:
    "You’ll need a gallery plan if the artworks displayed are for sale.",
  MANGE_PLAN_BUTTON: "Manage your plan",
  PLAN: "Plan",
};

export const limit = 20;

import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";

import analytics from "../../services/analytics";

import { useDeleteArtwork } from "../../hooks/artworks/useDeleteArtwork";
import { useUpdateArtwork } from "../../hooks/artworks/useUpdateArtwork";
import { useGetArtworks } from "../../hooks/artworks/useGetArtworks";
import AddEditArtworkModal from "../../components/modal/AddEditArtworkModal";
import InfiniteScroll from "react-infinite-scroll-component";
import TableTitleBar from "../../components/tables/table-title-bar";
import DeleteModal from "../../components/modal/DeleteModal";
import { DropdownOption } from "../../components/model/DropdownOption";
import { Artwork } from "../../components/model/Artwork";
import ArtworksTableHead from "./artworks-table-head";
import ArtworksTableRow from "./artworks-table.row";
import Loader from "../../components/shared/loader";
import Table from "../../components/shared/Table";
import { CONSTANT } from "../../static/constants";
import { useAuth } from "../../authn/authn";
import { useReactiveVar } from "@apollo/client";
import { selectedFrameIdVar } from "../../states/topNavFrames";
import { useUpdateFrame } from "../../hooks/frames/useUpdateFrame";
import { useGetAccount } from "../../hooks/user/useGetAccount";
import TableBannerWithSearch from "../../components/shared/TableBannerWithSearch";
import MainContainer from "../../components/shared/MainContainer";
import TableContainer from "../../components/shared/TableContainer";
import TableEmptyState from "../../components/tables/table-empty-state";
import { post } from "../../util/http-api";
import { nftSyncBackgroundContext } from "../../contexts/nft-sync-background-context";
import { useGetWallets } from "../../hooks/wallet/useGetWallets";
import { getWallet } from "../utils/Helper";
import { useCreateWallet } from "../../hooks/wallet/useCreateWallet";
import { SYNC_NFTS_CONSTANT } from "../../static/Enums";
import ToastTemplate from "../../components/shared/ToastTemplate";
import { getAccountOption } from "../../util/account";
import UpgradePlanModal from "../../components/modal/UpgradePlanModal";
import useAccountLimitation from "../../hooks/frames/useAccountLimitation";
import { generalToastContext } from "../../contexts/general-toast-context-provider";
import { formatBytes } from "../../util/strings";

const Artworks: React.FC<any> = (props) => {
  const { syncLoader, setSyncLoader, triggerSync, cancelSync } = useContext(
    nftSyncBackgroundContext
  );

  const [sortedColumn, setSortedColumn] = useState<[string, "asc" | "desc"]>([
    "id",
    "desc",
  ]);
  const [searchText, setSearchText] = useState("");
  const updateArtworks = useUpdateArtwork();
  const deleteArtwork = useDeleteArtwork();
  const updateFrame = useUpdateFrame();
  const authn = useAuth();
  const limit = 20;
  const { wallets } = useGetWallets(authn.getUser()?.id);
  const [subInfoDialog, setSubInfoDialog] = useState<boolean>(false);
  const { selectedPlan } = useContext(generalToastContext);


  const {
    loading,
    artworks,
    fetchMore,
    artworksCount,
    refetchArtworks,
    startPolling,
    stopPolling,
  } = useGetArtworks(
    authn?.getAccountId(),
    limit,
    0,
    sortedColumn.join(":"),
    searchText,
    (data: any) => {
      // if any of the artworks have a status of 'processing', then we need to trigger start polling
      if (
        data?.artworks?.some((artwork: any) => artwork?.status === "processing")
      ) {
        startPolling(5000);
      } else {
        stopPolling();
      }
    }
  );

  const { refetch: refetchAccount } = useGetAccount(
    authn.getUser()?.account?.id || 0
  ); // the || 0 is a bit of a hack for when the user doesn't have an 'account'
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [allArtworks, setAllArtworks] = useState<Artwork[]>();
  const [artworkEditData, setArtworkEditData] = useState<Artwork>();
  const [artworkId, setArtworkId] = useState<string>("0");
  const [loader, setLoader] = useState(false);
  const [artworkLoading, setArtworkLoading] = useState("");
  const selectedFrameId = useReactiveVar(selectedFrameIdVar);
  const createWallet = useCreateWallet();
  const { storageLimit, storageConsumed } = useAccountLimitation(allArtworks?.length);

  useEffect(() => {
    if (artworkId === "0") {
      setAllArtworks(artworks);
    }
  }, [artworks]);

  const onCloseDialog = async (artwork: any) => {
    setShowEditModal(false);
    if (!artwork) return;

    if (artwork?.id) {
      setLoader(true);
      const updatedArtwork = Object.assign({}, artwork);
      delete updatedArtwork.id;
      try {
        await updateArtworks({
          variables: {
            input: {
              data: updatedArtwork,
              where: {
                id: artwork.id,
              },
            },
          },
        });
        analytics.track("artwork: updated", {
          id: artwork.id,
        });
        setLoader(false);
        toast.success(CONSTANT.ARTWORK_UPDATE, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (ex: any) {
        console.log(ex);
        toast.error(ex?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } finally {
        setLoader(false);
      }
    } else {
      setLoader(true);
      try {
        const res = await post("artworks", artwork);
        if (res.error) throw res.error;

        let isProcessing = false;
        for (let i = 0; i < res.length; i++) {
          const a = res[i];
          analytics.track("artwork: added", {
            id: a.id,
            mediaType: a.mediaType,
            source: a.source,
          });
          if (a.status === "processing") {
            isProcessing = true;
          }
        }

        if (isProcessing) {
          // begin polling to refresh the artworks until all are done processing
          startPolling(5000);
        }

        setAllArtworks((artwoks) => [...res, ...artwoks!]);
        toast.success(CONSTANT.ARTWORK_ADDED, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (ex: any) {
        toast.error(ex?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } finally {
        setLoader(false);
      }
    }
  };

  const closeSubInfoModal = () => {
    setSubInfoDialog(false);
  };
  const onSortColumn = (column: string) => () => {
    const [col, sort] = sortedColumn;

    setAllArtworks([]);
    setSortedColumn([
      column,
      column === col ? (sort === "asc" ? "desc" : "asc") : "desc",
    ]);
  };

  const onSearch = async (event: any) => {
    event.preventDefault(); // don't redirect the page
    const search = event.target.search.value;
    setSearchText(search);
  };

  const pushArtworkToFrame = (artwork: Artwork) => async () => {
    const artworkId = artwork.id;
    const artworkMediaType = artwork.mediaType;

    setArtworkLoading(artworkId);
    if (selectedFrameId) {
      const res = await updateFrame({
        variables: {
          input: {
            data: {
              artwork: artworkId,
              artworkCollection: null,
            },
            where: {
              id: selectedFrameId,
            },
          },
        },
      });
      analytics.track("artwork: pushed", {
        id: artworkId,
        frameId: selectedFrameId,
        mediaType: artworkMediaType,
      });
      analytics.add("push_count");
    }
    setArtworkLoading("");
  };

  const updateDisplay = (id: string) => async (selected: DropdownOption) => {
    setArtworkLoading(id);
    const res = await updateArtworks({
      variables: {
        input: {
          data: {
            display: selected.title,
          },
          where: {
            id: id,
          },
        },
      },
    });
    setArtworkLoading("");
  };

  const fetchMoreData = async (isSync: boolean = false) => {
    setLoader(!isSync);
    const { data } = await fetchMore({
      variables: {
        offset: isSync ? 0 : allArtworks?.length,
        limit,
      },
    });
    isSync
      ? setAllArtworks(data.artworks)
      : setAllArtworks((artwoks) => [...artwoks!, ...data.artworks]);

    isSync ? setSyncLoader(SYNC_NFTS_CONSTANT.SYNC_DONE) : setLoader(false);
  };

  useEffect(() => {
    if (syncLoader === SYNC_NFTS_CONSTANT.SYNC_DONE) {
      refetchArtworks();
    }
  }, [syncLoader, refetchArtworks]);

  const onOpenDeleteModal = (id: string) => () => {
    setShowDeleteModal(true);
    setArtworkId(id);
  };

  const onEditHandler = (data: any) => () => {
    setArtworkEditData(data);
    setShowEditModal(true);
  };

  const onAddArtworkHandler = () => {
    setArtworkEditData(undefined);
    if (storageConsumed > storageLimit) {
      setSubInfoDialog(true);
    } else {
      setShowEditModal(true);
    }
  };

  const onCloseDeleteModal = async (state: boolean) => {
    try {
      if (state) {
        setLoader(true);
        const result = await deleteArtwork({
          variables: {
            input: {
              where: {
                id: artworkId,
              },
            },
          },
        });
        if (result) {
          setAllArtworks(
            allArtworks?.filter((item: Artwork) => item?.id !== artworkId)
          );
          setArtworkId("0");
          refetchAccount();
          analytics.track("artwork: deleted", {
            id: artworkId,
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setShowDeleteModal(false);
      setLoader(false);
    }
  };

  const connectWallet = async () => {
    const { publicAddress, provider } = await getWallet();

    const result = await createWallet({
      variables: {
        input: {
          data: {
            publicAddress: publicAddress,
            blockchain: "Ethereum",
            walletProvider: provider || "",
            owner: authn.getUser()?.id,
          },
        },
      },
    });
    if (result) {
      toast.success(<ToastTemplate />, {
        position: toast.POSITION.TOP_RIGHT,
        className: "items-left text-gray-600 text-sm w-100",
      });
    }
    authn.refetch();

    return publicAddress;
  };

  const handleSyncWalletsOrCancel = async () => {
    // if currently pending, cancel
    if (syncLoader === SYNC_NFTS_CONSTANT.SYNC_PENDING) {
      cancelSync();

      return;
    }
    if (!wallets.length) {
      await connectWallet();
    }
    triggerSync();
    // syncWallets(setSyncLoader)
  };

  const syncButtonDefaultTitle =
    wallets?.length < 1 ? CONSTANT.SYNC_WALLET : CONSTANT.SYNC_WALLETS;
  const syncButtonTitle =
    syncLoader === SYNC_NFTS_CONSTANT.SYNC_PENDING
      ? "Cancel Sync"
      : syncButtonDefaultTitle;
  const showSyncNFTs = getAccountOption(authn.getAccount(), "syncNFTs");
  return (
    <>
      <div>
        <MainContainer>
          <TableTitleBar>
            <TableBannerWithSearch
              title={CONSTANT.ARTWORKS}
              count={artworksCount}
              searchHandler={onSearch}
              addHandler={onAddArtworkHandler}
              buttonTitle={CONSTANT.ADD_ARTWORK}
              buttonTitleSmallSize={CONSTANT.ADD}
              secondaryButtonHandler={showSyncNFTs && handleSyncWalletsOrCancel}
              secondaryButtonTitle={showSyncNFTs && syncButtonTitle}
            />
          </TableTitleBar>
          {<Loader isLoading={loading} />}
          <div className="text-left ">
            <TableContainer>
              <InfiniteScroll
                hasMore={
                  (Number(artworksCount) || 0) >
                  (Number(allArtworks?.length) || 0)
                }
                dataLength={Number(allArtworks?.length) || 0}
                next={fetchMoreData}
                loader={<h4></h4>}
              >
                {!loading && (!allArtworks || allArtworks?.length === 0) ? (
                  <TableEmptyState
                    title="There are no artworks yet."
                    // subtitle="Add an artwork to get started."
                  />
                ) : (
                  <Table>
                    <ArtworksTableHead
                      sortedColumn={sortedColumn}
                      onSortColumn={onSortColumn}
                    />

                    <tbody className="bg-indigo divide-y divide-table-border-color">
                      {allArtworks?.map((artwork: Artwork) => (
                        <ArtworksTableRow
                          pushArtworkToFrame={pushArtworkToFrame}
                          onOpenDeleteModal={onOpenDeleteModal}
                          onEditHandler={onEditHandler}
                          updateDisplay={updateDisplay}
                          key={artwork.id}
                          data={artwork}
                          artworkLoading={artworkLoading === artwork.id}
                        />
                      ))}
                    </tbody>
                  </Table>
                )}
              </InfiniteScroll>
            </TableContainer>
            {showEditModal && (
              <AddEditArtworkModal
                onCloseDialog={onCloseDialog}
                user={authn?.getUser()?.id}
                artwork={artworkEditData}
                isOpen={showEditModal}
              />
            )}

            <DeleteModal
              title={CONSTANT.ARTWORK}
              isOpen={showDeleteModal}
              onCloseDeleteModal={(value: boolean) => onCloseDeleteModal(value)}
            />
          </div>
        </MainContainer>
        <UpgradePlanModal
          isModalOpen={subInfoDialog}
          onCloseDialog={closeSubInfoModal}
          selectedPlan={selectedPlan}
          limit={formatBytes(storageLimit)}
          consumed={formatBytes(storageConsumed)}
          type="artwork"
        />
        <Loader isLoading={loader} />
      </div>
    </>
  );
};

export default Artworks;

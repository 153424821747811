import { useQuery, gql, useMutation } from "@apollo/client";
import { GET_EXHIBITION_DETAILS } from "./useGetExhibitionDetails";

interface updateExhibitionInput {
  variables: {
    input: {
      data: {
        name?: string;
        pairs?: any[]
      };
      where: {
        id: string;
      };
    };
  };
}

export const UPDATE_EXHIBITION = gql`
  mutation UpdateExhibition($input: updateExhibitionInput!) {
    updateExhibition(input: $input) {
        exhibition {
            id
            name
        }
    }
  }
`;

export const useUpdateExhibition = (): ((updateExhibition: updateExhibitionInput) => any) => {
  const [updateExhibitionResult] = useMutation(UPDATE_EXHIBITION, {
    refetchQueries: [GET_EXHIBITION_DETAILS, "GetExhibitionDetails"],
    
    // update(cache, { data: { updateExhibition } }) {
    //   // console.log(updateExhibition);
    //   cache.modify({
    //     fields: {
    //       exhibitions(existingExhibitions = []) {
    //         // console.log(updateExhibition.exhibition);
    //         if (updateExhibition.exhibition) {
    //           return updateExhibition.exhibition;
            
            
    //           // const newExhibitionRef = cache.writeFragment({
    //           //   data: updateExhibition,
    //           //   fragment: gql`
    //           //     fragment updateExhibition on Exhibitions {
    //           //       id
    //           //       name
    //           //     }
    //           //   `,
    //           // });
    //           // return [...existingExhibitions, newExhibitionRef];
    //       },
    //     },
    //   });
    // },
  });

  return updateExhibitionResult;
};

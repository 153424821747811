import React from "react";

const Legal = () => {
  return (
    <div className="max-w-full p-6 mt-20 text-center">
      <a
        className="mx-4"
        href="https://app.termly.io/document/privacy-policy/7acc4cbd-8fcb-4cdd-bdab-357e0d88c880"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      <a
        className="mx-4"
        href="https://app.termly.io/document/terms-of-use-for-saas/3425ea15-b0ea-4bfa-b362-264eb023463c"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>
    </div>
  );
};

export default Legal;

import { CONSTANT } from "../../../static/constants";
import { ChevronUpIcon } from "@heroicons/react/solid";

import logo from "../../../assets/logo.svg";
import zeroframe from "../../../assets/ZEROFRAME.svg";

import React, { useEffect, useState, useContext } from "react";

import analytics from "../../../services/analytics";

import { useUpdateArtwork } from "../../../hooks/artworks/useUpdateArtwork";
import { useGetArtworks } from "../../../hooks/artworks/useGetArtworks";
import InfiniteScroll from "react-infinite-scroll-component";
import { DropdownOption } from "../../../components/model/DropdownOption";
import { Artwork } from "../../../components/model/Artwork";
import ArtworksTableHead from "./artworks-table-head";
import ArtworksTableRow from "./artworks-table-row";
import Loader from "../../../components/shared/loader";
import Table from "../../../components/shared/Table";
import { useAuth } from "../../../authn/authn";
import { useReactiveVar } from "@apollo/client";
import { selectedFrameIdVar } from "../../../states/topNavFrames";
import { useUpdateFrame } from "../../../hooks/frames/useUpdateFrame";
import { useGetAccount } from "../../../hooks/user/useGetAccount";
import MainContainer from "../../../components/shared/MainContainer";
import TableEmptyState from "../../../components/tables/table-empty-state";
import { nftSyncBackgroundContext } from "../../../contexts/nft-sync-background-context";
import { SYNC_NFTS_CONSTANT } from "../../../static/Enums";
import KioskModeArtworkSearch from "../../../components/shared/KioskModeArtworkSearch";
import KioskModeDetails from "../../../components/modal/KioskModeDetails";
import FrameSelector from "../../../components/header/kioskMode/frame-selector";

const texts = {
  zeroframeInfo1:
    "Zeroframe is a platform for collectors, curators and galleries to display digital art on any screen – a smartTV, tablet or projector.",
  zeroframeInfo2:
    "Easily display your collection, manage multiple displays in a gallery or curate artworks for a remote location. It supports images, videos, GIFs and web-based generative artworks.",
  zeroframeInfo3: (
    <>
      Try it for free at{" "}
      <a href="zeroframe.art" className="text-white font-bold">
        zeroframe.art
      </a>
    </>
  ),
};

const KioskMode = () => {
  const [isHeaderExpanded, setIsHeaderExpanded] = useState(false);
  const { syncLoader, setSyncLoader } = useContext(
    nftSyncBackgroundContext
  );

  const [sortedColumn, setSortedColumn] = useState<[string, "asc" | "desc"]>([
    "id",
    "desc",
  ]);
  const [searchText, setSearchText] = useState("");
  const updateArtworks = useUpdateArtwork();
  const updateFrame = useUpdateFrame();
  const authn = useAuth();
  const limit = 20;

  const {
    loading,
    artworks,
    fetchMore,
    artworksCount,
    refetchArtworks,
    startPolling,
    stopPolling,
  } = useGetArtworks(
    authn?.getAccountId(),
    limit,
    0,
    sortedColumn.join(":"),
    searchText,
    (data: any) => {
      // if any of the artworks have a status of 'processing', then we need to trigger start polling
      if (
        data?.artworks?.some((artwork: any) => artwork?.status === "processing")
      ) {
        startPolling(5000);
      } else {
        stopPolling();
      }
    }
  );


  const [allArtworks, setAllArtworks] = useState<Artwork[]>();
  const [artworkId, setArtworkId] = useState<string>("0");
  const [loader, setLoader] = useState(false);
  const [artworkLoading, setArtworkLoading] = useState("");
  const selectedFrameId = useReactiveVar(selectedFrameIdVar);

  useEffect(() => {
    if (artworkId === "0") {
      setAllArtworks(artworks);
    }
  }, [artworks]);

  const onSortColumn = (column: string) => () => {
    const [col, sort] = sortedColumn;

    setAllArtworks([]);
    setSortedColumn([
      column,
      column === col ? (sort === "asc" ? "desc" : "asc") : "desc",
    ]);
  };

  const onSearch = async (search:string) => {
    setSearchText(search);
  };

  const pushArtworkToFrame = (artwork: Artwork) => async () => {
    const artworkId = artwork.id;
    const artworkMediaType = artwork.mediaType;

    setArtworkLoading(artworkId);
    if (selectedFrameId) {
      const res = await updateFrame({
        variables: {
          input: {
            data: {
              artwork: artworkId,
              artworkCollection: null,
            },
            where: {
              id: selectedFrameId,
            },
          },
        },
      });
      analytics.track("artwork: pushed", {
        id: artworkId,
        frameId: selectedFrameId,
        mediaType: artworkMediaType,
      });
      analytics.add("push_count");
    }
    setArtworkLoading("");
  };

  const updateDisplay = (id: string) => async (selected: DropdownOption) => {
    setArtworkLoading(id);
    const res = await updateArtworks({
      variables: {
        input: {
          data: {
            display: selected.title,
          },
          where: {
            id: id,
          },
        },
      },
    });
    setArtworkLoading("");
  };

  const fetchMoreData = async (isSync: boolean = false) => {
    setLoader(!isSync);
    const { data } = await fetchMore({
      variables: {
        offset: isSync ? 0 : allArtworks?.length,
        limit,
      },
    });
    isSync
      ? setAllArtworks(data.artworks)
      : setAllArtworks((artwoks) => [...artwoks!, ...data.artworks]);

    isSync ? setSyncLoader(SYNC_NFTS_CONSTANT.SYNC_DONE) : setLoader(false);
  };

  useEffect(() => {
    if (syncLoader === SYNC_NFTS_CONSTANT.SYNC_DONE) {
      refetchArtworks();
    }
  }, [syncLoader, refetchArtworks]);

  const expandHeader = () => {
    setIsHeaderExpanded(true);
  };

  const closeHeader = () => {
    setIsHeaderExpanded(false);
  };

  return (
    <div className="h-[100vh] w-full ">
      <div className={` sm:px-8 kiosk-header z-50 fixed w-[100vw] sticy bg-primary px-4 transition-all duration-1000 ease-in-out `}
      >
        <div className="h-full py-4 flex justify-between items-center">
          <div className="flex">
            <img
              className="kiosk-logo-img w-auto cursor-pointer"
              src={logo}
              alt="Zeroframe"
              onClick={expandHeader}
            />
            <img
              className=" kiosk-logo-text ml-4 w-auto cursor-pointer"
              src={zeroframe}
              alt="Zeroframe"
              onClick={expandHeader}
            />
          </div>
          <div className="">
            <FrameSelector/>
          </div>
        </div>
      </div>

    <div className="kiosk-header"/>

      <div className="h-full  px-2 sm:px-6 py-2 bg-[#F3F4F6]">
        <div>
          <MainContainer>
            <div className="text-left">
              <KioskModeArtworkSearch searchHandler={onSearch} searchLoader={loading} />
              <InfiniteScroll
                hasMore={
                  (Number(artworksCount) || 0) >
                  (Number(allArtworks?.length) || 0)
                }
                dataLength={Number(allArtworks?.length) || 0}
                next={fetchMoreData}
                className="rounded-3xl border mt-2"
                loader={<h4></h4>}
              >
                {!loading && (!allArtworks || allArtworks?.length === 0) ? (
                  <TableEmptyState
                    title="There are no artworks yet."
                    // subtitle="Add an artwork to get started."
                  />
                ) : (
                  <Table className="p-2 table-fixed w-full">
                    <tbody className="bg-indigo divide-y divide-table-border-color">
                      {allArtworks?.map((artwork: Artwork) => (
                        <ArtworksTableRow
                          pushArtworkToFrame={pushArtworkToFrame}
                          updateDisplay={updateDisplay}
                          key={artwork.id}
                          data={artwork}
                          artworkLoading={artworkLoading === artwork.id}
                        />
                      ))}
                    </tbody>
                  </Table>
                )}
              </InfiniteScroll>
            </div>
          </MainContainer>
        </div>
      </div>
      <KioskModeDetails isOpen={isHeaderExpanded} onClose={closeHeader} />
      <Loader isLoading={loader}/>
    </div>
  );
};

export default KioskMode;

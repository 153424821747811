import { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";

import { CONSTANT } from "../../static/constants";
import { Frame } from "../model/Frame";
import Modal from "./Modal";
import PairCode from "../shared/PairCode";
// import './PairFrame.css'
import ViewFinder from "../shared/ViewFinder";

import { useBreakpoint } from "../../util/use-breakpoint";
interface Props {
  frame: Frame | undefined;
  isOpen: boolean;
  onCloseModal: any;
}

function PairFrameModal(props: Props) {
  const [pairingCode, setPairingCode] = useState("");
  const [error, setError] = useState("");

  const { isMd } = useBreakpoint("md");

  const updateFrame = () => {
    if (pairingCode?.length === 0 || pairingCode?.length === 6) {
      let frame = {
        pairingCode: pairingCode,
      };
      props.onCloseModal(frame);
    } else {
      setError(CONSTANT.PAIRING_CODE_ERROR);
    }
  };

  const onQRResult = (result: any, error: Error | null | undefined) => {
    if (!!result) {
      setPairingCode(result?.text);
    }

    // if (!!error) {
    //   console.info(error);
    // }
  };

  useEffect(() => {
    if (props.isOpen) {
      setPairingCode("");
    }
  }, [props.isOpen]);

  // When the pairingCode has all 6 chars, update
  useEffect(() => {
    if (pairingCode?.length === 6) {
      updateFrame();
      setPairingCode("");
    }
  }, [pairingCode]);

  return (
    <Modal
      title={CONSTANT.PAIR_FRAME_MODAL_HEADING}
      onCancel={props.onCloseModal}
      confirmText={CONSTANT.PAIR_SUBMIT_BUTTON}
      isOpen={props.isOpen}
      // if no formId or onConfirm is passed, only a cancel button is shown in modal actions
      // formId="pair-frame"
      // onConfirm={updateFrame}
    >
      {!isMd && props.isOpen && (
        <div className="md:hidden h-full w-full">
          <p className="text-sm font-bold mb-1">
            Scan the QR-code on your display to pair
          </p>
          <QrReader
            ViewFinder={ViewFinder}
            onResult={onQRResult}
            constraints={{ facingMode: "environment" }}
          />
        </div>
      )}
      <PairCode
        // topMessage={CONSTANT.PAIRING_CODE_DESCRIPTION}
        topMessage={"Pairing code"}
        setError={setError}
        error={error}
        setPairingCode={setPairingCode}
      />
    </Modal>
  );
}

export default PairFrameModal;

import { gql, useMutation } from "@apollo/client";
import { GET_FRAMES } from "./useGetFrames";

interface createFrameInput {
  variables: {
    input: {
      data: {
        name: string;
        pairingCode: string;
        creator: string;
      };
    };
  };
}

export const CREATE_FRAME = gql`
  mutation CreateFrame($input: createFrameInput!) {
    createFrame(input: $input) {
      frame {
        id
        name
        plaque
        plaqueSize
        isConnected
        rotation
        optimizeMedia
        artwork {
          id
          thumbImageUrl
        }
        artworkCollection {
          id
          artworks {
            artwork {
              id
              thumbImageUrl
            }
          }
        }
        updated_at
        pairingCode
      }
    }
  }
`;

export const useCreateFrame = (): ((createFrame: createFrameInput) => any) => {
  const [createFrameResult] = useMutation(CREATE_FRAME, {
    refetchQueries: [GET_FRAMES, "GetFrames"],
  });

  return createFrameResult;
};

import PropTypes, { InferProps } from "prop-types";
import { CONSTANT } from "../../static/constants";



function ExhibitionDetailsTitle({ titleClickHanaler, exhibitionName }: InferProps<typeof ExhibitionDetailsTitle.propTypes>) {
    return <span>
        <span
            className="text-back-nav-color mr-1 text-lg font-semibold cursor-pointer"
            onClick={() => { titleClickHanaler() }}
        >
            {CONSTANT.EXHIBITIONS} /{" "}
        </span>
        <span className="text-1xl  md:text-3xl font-bold text-secondary">
            {exhibitionName}
        </span>
    </span>
}

ExhibitionDetailsTitle.propTypes = {
    titleClickHanaler: () => void {},
    exhibitionName: PropTypes.string.isRequired,

};
export default ExhibitionDetailsTitle

import React from "react";
import { CONSTANT } from "../../static/constants";
import classNames from "classnames";

interface ButtonProps {
  link: string;
  isLinkthem?: boolean;
}

const ManagePlanButton: React.FC<ButtonProps> = ({
  link,
  isLinkthem = false,
}) => {
  const handleClick = () => {
    // Redirect to the specified link
    window.open(link, "_blank");
  };

  return (
    <button
      className={classNames(
        isLinkthem
          ? "bg-transparent  font-semibold ml-1 underline"
          : `bg-white border-[#D2D5DA] hover:bg-gray-100  border-2 rounded-lg py-2 px-4 transition duration-300 ease-in-out`
      )}
      onClick={handleClick}
    >
      {CONSTANT.MANGE_PLAN_BUTTON}
    </button>
  );
};

export default ManagePlanButton;

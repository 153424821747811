import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { CONSTANT, limit } from "../../static/constants";
import PropTypes, { InferProps } from "prop-types";
import { Artwork } from "../../components/model/Artwork";
import { Collection } from "../../components/model/Collection";
import ArtworkThumb from "../../components/shared/thumbnails/ArtworkThumb";
import { useGetArtworks } from "../../hooks/artworks/useGetArtworks";
import { useGetCollections } from "../../hooks/collections/useGetCollections";
import { useAuth } from "../../authn/authn";
import InfiniteScroll from "react-infinite-scroll-component";
import ArtworkPickerHead from "../artworks/artwork-picker-head";
import ExhibitionPickerHead from "./exhibition-picker-head";
import classNames from "classnames";
import Dropdown from "../../components/shared/DropdownWithoutSelect";
import { DropdownOption } from "../../components/model/DropdownOption";
import { artworkCollection } from "../../static/Enums";
import ArtworkCollectionThumb from "../../components/shared/thumbnails/ArtworkCollectionThumb";
import { XIcon } from "@heroicons/react/solid";
import Table from "../../components/shared/Table";
import Modal from "../../components/modal/Modal";

function ExhibitionPicker(props: InferProps<typeof ExhibitionPicker.propTypes>) {
  //
  const [allArtworks, setAllArtworks] = useState<Artwork[]>([]);
  const [selectedArtworks, setSelectedArtworks] = useState<Artwork[]>([]);
  const [allCollections, setAllCollections] = useState<Collection[]>([]);
  const [selectedCollections, setSelectedCollections] = useState<Collection[]>(
    []
  );
  const [searchText, setSearchText] = useState("");
  const [searchCollectionText, setSearchCollectionText] = useState("");
  const authn = useAuth();
  const [selectedFrame, setSelectedFrame] = useState<DropdownOption>();
  const [displayContent, setDisplayContent] = useState<any>();
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const searchRef = useRef("");
  const [artState, setArtState] = useState("");
  const [colState, setColState] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");

  const [sortedColumn, setSortedColumn] = useState<[string, "asc" | "desc"]>([
    "id",
    "desc",
  ]);

  const { loading, error, artworks, fetchMore, artworksCount } = useGetArtworks(
    authn?.getAccountId(),
    limit,
    0,
    "title:asc",
    searchText
  );

  const {
    loading: loadingCollection,
    error: errorCollection,
    collections,
    fetchMore: fetchMoreCollection,
    collectionsCount,
  } = useGetCollections({
    accountId: authn?.getAccountId(),
    limit: limit,
    offset: 0,
    sort: "name:asc",
    search: searchCollectionText,
  });

  useEffect(() => {
    setAllArtworks(artworks);
  }, [artworks]);

  useEffect(() => {
    setAllCollections(collections);
  }, [collections]);



  useEffect(() => {
    setSearchText("");
    setSearchCollectionText("");
    setShowCloseIcon(false)

    setConfirmButtonText(getConfirmButtonText(props.selectedType || 1))
    if (props?.selectedType == 1) {
      setSelectedFrame({ id: props.selectedType, title: 'Artwork' });
    }
    if (props?.selectedType == 2) {
      setSelectedFrame({ id: props.selectedType, title: 'Collection' });
    }
    setDisplayContent(props?.selectedType);
    setArtState(props?.selectedArtworksIds?.[0]);
    setColState(props?.selectedCollectionIds?.[0]);
  }, [props.isOpen]);

  const getCheckedCheckbox = (selected: Artwork) => (event: any) => {
    if (props.isMultiSelect) {
      if (!event.target.checked) {
        setSelectedArtworks(
          selectedArtworks.filter((artwork) => artwork.id !== selected.id)
        );
      } else {
        setSelectedArtworks([...selectedArtworks, selected]);
      }
    } else {
      setSelectedArtworks([selected]);
      setArtState(selected.id);
      setSelectedCollections([]);
      setColState("");
    }
  };

  const getCheckedCheckboxCollection = (selected: Collection) => (event: any) => {
    if (props.isMultiSelect) {
      if (!event.target.checked) {
        setSelectedCollections(
          selectedCollections.filter((collection) => collection.id !== selected.id)
        );
      } else {
        setSelectedCollections([...selectedCollections, selected]);
      }
    } else {
      setSelectedCollections([selected]);
      setColState(selected.id);
      setSelectedArtworks([]);
      setArtState("");
    }
  };

  const onSortColumn = (column: string) => () => {
    const [col, sort] = sortedColumn;
    setAllArtworks([]);
    setSortedColumn([
      column,
      column === col ? (sort === "asc" ? "desc" : "asc") : "desc",
    ]);
  };

  const fetchMoreData = async () => {
    await fetchMore({
      variables: {
        offset: allArtworks?.length,
        limit,
      },
    }).then((res: any) => {
      setAllArtworks((artworks) => [...artworks!, ...res?.data?.artworks]);

    });
  };

  const onSearch = async (event: any) => {
    event.preventDefault(); // don't redirect the page
    const search = event.target.search.value;

    if (selectedFrame?.title == "Artwork") {
      setSearchText(search);
    }
    if (selectedFrame?.title == "Collection") {
      setSearchCollectionText(search);
    }
  };

  const onArtworkCollectionSelect = async (selected: DropdownOption) => {
    setDisplayContent(selected.id);
    setSelectedFrame(selected);
    setConfirmButtonText(getConfirmButtonText(selected.id || 1))

  };
  const searchKeyUp = (el: any) => {
    if (el.target.value) {
      setShowCloseIcon(true)
    } else {
      setShowCloseIcon(false)
    }
  }

  const closeIconKeyClick = () => {
    //@ts-ignore
    searchRef.current.value = "";
    const search = '';
    setSearchText(search);
    setSearchCollectionText(search);
    setShowCloseIcon(false)
  }


  const getConfirmButtonText = (type: number = 1) => {
    if (type == 1) {
      return props.isMultiSelect
        ? `Add ${selectedArtworks !== null ? selectedArtworks.length : ""
        } artworks`
        : " Select artwork"
    } else {
      return props.isMultiSelect
        ? `Add ${selectedCollections !== null ? selectedCollections.length : ""
        } collection`
        : " Select collection"
    }
  }


  return (
    <>
      {props.isOpen ? (
        <Modal
          title={props.isMultiSelect ? "Add artworks " : " Select artwork or collection "}
          onCancel={() => {
            props.onCloseModal(null)
          }}
          formId="select-artCol"
          isOpen={props.isOpen}
          onConfirm={() => {
            if (displayContent === 1 && selectedArtworks.length !== 0) {
              props.onCloseModal(selectedArtworks, "artwork")
            } else {
              props.onCloseModal(selectedCollections, "collection")
            }
          }}
          confirmText={confirmButtonText}
          cancelText={CONSTANT.CANCEL}
        >
          <div className="grid md:flex w-full items-center mt-4 mb-3 px-5 md:justify-center">
            <Dropdown
              width="w-full md:w-44"
              onOptionSelect={(select: DropdownOption) =>
                onArtworkCollectionSelect(select)
              }
              default={
                displayContent
              }
              options={artworkCollection}
              noSlect={true}
            // disFrame={disFrame}
            />
            <form onSubmit={onSearch} className="mt-2 md:m-0 w-full px-0">
              <div className="flex justify-between border border-bc mx-0 md:ml-5 bg-white rounded-md ">
                {displayContent == 1 ? (
                  <div className="relative"><input
                    //@ts-ignore
                    ref={searchRef}
                    name="search"
                    type="text"
                    className="px-4 py-2 border-0 bg-white text-search font-base rounded-md font-weight-700 sm:text-sm font"
                    placeholder="Search by artist or title"
                    onKeyUp={searchKeyUp}
                  />
                    {showCloseIcon &&
                      <span className="absolute mr-2 flex h-full top-0 items-center right-0 cursor-pointer" onClick={closeIconKeyClick}>
                        <XIcon className="h-5 x-5 text-gray-400" />
                      </span>
                    }
                  </div>
                ) : (
                  <div className="relative"><input
                    //@ts-ignore
                    ref={searchRef}
                    name="search"
                    type="text"
                    className="px-4 py-2 border-0 bg-white text-search font-base rounded-md font-weight-700 sm:text-sm font"
                    placeholder="Search collection"
                    onKeyUp={searchKeyUp}
                  />
                    {showCloseIcon &&
                      <span className="absolute mr-2 flex h-full top-0 items-center right-0 cursor-pointer" onClick={closeIconKeyClick}>
                        <XIcon className="h-5 x-5 text-gray-400" />
                      </span>
                    }
                  </div>
                )}
                <button
                  type="submit"
                  className="flex items-center justify-center px-2 border-l border-bc"
                >
                  <svg
                    className="w-6 h-6 text-search-icon"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                  </svg>
                </button>
              </div>
            </form>
          </div>
          <div
            id="scrollableDiv"
            className="sm:rounded-md h-80 overflow-auto pb-10"
          >
            <InfiniteScroll
              hasMore={
                (Number(artworksCount) || 0) >
                (Number(allArtworks?.length) || 0)
              }
              dataLength={Number(allArtworks?.length) || 0}
              next={fetchMoreData}
              loader={<h4></h4>}
              scrollableTarget="scrollableDiv"
            >
              <Table>
                {displayContent == 1 ? (
                  <ArtworkPickerHead
                    sortedColumn={sortedColumn}
                    onSortColumn={onSortColumn}
                  />
                ) : (
                  <ExhibitionPickerHead
                    sortedColumn={sortedColumn}
                    onSortColumn={onSortColumn}
                  />
                )}

                <tbody className="bg-indigo divide-y divide-table-border-color">
                  {displayContent == 1
                    ? allArtworks?.map((artwork: Artwork) => {
                      return (
                        <tr key={artwork.id} className="w-full h-16">
                          <td className="px-2">
                            <div className="">
                              <input
                                title="Selected"
                                value={artwork?.id}
                                onChange={getCheckedCheckbox(artwork)}
                                //@ts-ignore
                                checked={artwork.id === artState ? true : false}
                                className={classNames(
                                  "text-primary-500 cursor-pointer w-5 h-5 focus:ring-primary-40 focus:ring-opacity-25 border border-gray-300 rounded",
                                  {
                                    "disabled:border-4 disabled:border-double disabled:border-[#2563eb]":
                                      artState,
                                  }
                                )}
                                type={
                                  props.isMultiSelect
                                    ? "checkbox"
                                    : "radio"
                                }
                                name="artwork"
                              />
                            </div>
                          </td>
                          <td className="sm:px-6 ">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-14 w-14">
                                <ArtworkThumb artwork={artwork} />
                              </div>
                            </div>
                          </td>
                          <td className="sm:px-6  ">
                            <div className="text-sm text-primary font-semibold ">
                              {artwork?.title}
                            </div>
                            <div className="text-sm md:hidden text-gray text-table-cell-color ">
                              {artwork?.creatorUsername}
                            </div>
                          </td>
                          <td className="sm:px-6 hidden md:table-cell">
                            <div className="text-sm text-table-cell-color ">
                              {artwork?.creatorUsername}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                    : allCollections?.map((collection: Collection) => {
                      return (
                        <tr key={collection.id} className="w-full h-16">
                          <td className="px-2">
                            <div className="">
                              <input
                                title="Selected"
                                value={collection?.id}
                                onChange={getCheckedCheckboxCollection(collection)}
                                //@ts-ignore
                                checked={collection.id == colState ? true : false}
                                className={classNames(
                                  "text-primary-500 cursor-pointer w-5 h-5 focus:ring-primary-40 focus:ring-opacity-25 border border-gray-300 rounded",
                                  {
                                    "disabled:border-4 disabled:border-double disabled:border-[#2563eb]":
                                      colState,
                                  }
                                )}
                                type={
                                  props.isMultiSelect
                                    ? "checkbox"
                                    : "radio"
                                }
                                name="artwork"
                              />
                            </div>
                          </td>
                          <td className=" ">
                            <ArtworkCollectionThumb src={collection?.artworks} />
                          </td>
                          <td className=" ">
                            <div className="text-sm text-primary font-semibold ">
                              {collection?.name}
                            </div>
                          </td>
                          <td className="">
                            <div className="text-sm text-table-cell-color ">
                              {collection?.artworks?.length}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </InfiniteScroll>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}

ExhibitionPicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  selectedArtworksIds: PropTypes.array!,
  selectedCollectionIds: PropTypes.array!,
  isMultiSelect: PropTypes.bool,
  selectedType: PropTypes.number,
};

export default ExhibitionPicker;

import { useEffect } from "react";
import { CONSTANT } from "../../static/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import PropTypes, { InferProps } from "prop-types";
import { transition } from "../../static/Enums";
import isEmpty from "lodash/isEmpty";
import Modal from "./Modal";
import InputField from "../shared/InputField";
import SelectField from "../shared/SelectField";
import Toggle from "../shared/Toggle";

const initValue = {
  name: '',
  interval: 30,
  playFullVideos: false
}

function AddEditCollection(
  props: InferProps<typeof AddEditCollection.propTypes>
) {
  const schema = yup.object().shape({
    name: yup.string().required(),
    interval: yup.string().required(),
    transition: yup.string(),
    playFullVideos: yup.bool(),
  });

  // validation
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });




  useEffect(() => {
    if (props.collection?.id) {
      reset({
        id: props.collection?.id,
        name: props.collection?.name,
        interval: props.collection?.interval,
        transition: props.collection?.transition,
        playFullVideos: props.collection?.playFullVideos || false
      });
    } else {
      reset(initValue)
    }
  }, [props.collection, reset]);


  const onSubmit = async (data: any) => {
    if (isEmpty(errors)) {
      let collectionData;
      if (props.collection?.id) {
        // update
        collectionData = {
          id: props.collection.id,
          name: data.name,
          interval: Number(data.interval),
          transition: data.transition,
          playFullVideos: data.playFullVideos,
          creator: props.user,
        };
      } else {
        // create
        collectionData = {
          name: data.name,
          interval: Number(data.interval),
          transition: data.transition,
          playFullVideos: data.playFullVideos,
          creator: props.user,
        };
      }
      props.onCloseDialog(collectionData);
      reset(initValue)
    }
  };

  const onToggle = (enabled: boolean) => {
    setValue("playFullVideos", enabled);
  };

  const onCancelDialog = () => {
    reset();
    props.onCloseDialog(null);
  };

  return (
    <Modal
      title={CONSTANT.CREATE_COLLECTION}
      onCancel={() => onCancelDialog()}
      formId="add-collection"
      isOpen={props.isOpen}
    >
      <form
        id="add-collection"
        onSubmit={handleSubmit(onSubmit)}
        action="#"
        method="POST"
      >
        <div className="overflow-visible sm:rounded-md">
          <div className="w-full grid gap-4 grid-cols-12">
            <div className="col-span-12">
              <InputField
                name={"name"}
                label={CONSTANT.NAME}
                placeholder={CONSTANT.NAME}
                register={register}
                errors={errors?.name}
              />
            </div>

            <div className="col-span-4">
              <InputField
                name={"interval"}
                label={CONSTANT.INTERVAL}
                suffix={"seconds"}
                placeholder={"30"}
                register={register}
                errors={errors?.interval}
                type="number"
                defaultValue={30}
              />
            </div>

            <div className="col-start-7 col-span-4">
              <SelectField
                name={"transition"}
                label={CONSTANT.TRANSITION}
                register={register}
                errors={errors?.transition}
                options={transition}
              />
            </div>

            <div className="col-span-12 mt-4">
              <div className="grid grid-cols-4">
                <div className="col-span-3">
                  <label
                    htmlFor="optimizeMedia"
                    className="text-sm font-bold text-gray-700"
                  >
                    {CONSTANT.PLAY_FULL_VIDEOS_LABEL}
                  </label>
                  <div className="text-sm text-gray-500">
                    {CONSTANT.PLAY_FULL_VIDEOS_DESCRIPTION}
                  </div>
                </div>
                <div className="col-span-1 text-right">
                  <Toggle isEnabled={props.collection?.playFullVideos || false} onToggle={onToggle} />
                </div>
              </div>
              <p className="text-red-500 font-medium">{errors.playFullVideos?.message}</p>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

AddEditCollection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  collection: PropTypes.any,
};

export default AddEditCollection;

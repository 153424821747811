import React, { Fragment, memo, useEffect, useState, useRef } from "react";

import Dropdown from "../../components/shared/Dropdown";
import { DropdownOption } from "../../components/model/DropdownOption";
import { ChevronDownIcon } from "@heroicons/react/solid";
import ExhibitionPicker from "./exhibition-picker";
import { Artwork } from "../../components/model/Artwork";
import { Collection } from "../../components/model/Collection";
import { CONSTANT } from "../../static/constants";
// import ArtworkPicker from "../artworks/artwork-picker";

interface Props {
  frames: DropdownOption[];
  onAddExhibition: (frameId: number | null, artwork: number | null, collection: number | null) => () => void;
  disFrame: any[];
}

const ExhibitionAddPairRow: React.FC<Props> = (props) => {
  const [selectedFrame, setSelectedFrame] = useState<DropdownOption>();
  const [defaultValue, setDefaultValue] = useState<DropdownOption>({
    id: null,
    title: "",
  } as DropdownOption);
  const { frames, onAddExhibition, disFrame } = props;
  const [showPicker, setShowPicker] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedCollectionId, setSelectedCollectionId] = useState("");
  const [selectedType, setSelectedType] = useState(1);
  const [defaultArtwork, setDefaultArtwork] = useState<DropdownOption>({
    id: null,
    title: "",
    imageUrl: "",
  } as DropdownOption);
  const [defaultCollection, setDefaultCollection] = useState<DropdownOption>({
    id: null,
    title: "",
  } as DropdownOption);

  const [checkFrameSelected, setCheckFrameSelected] = useState(false);
  const [checkArtworkSelected, setCheckArtworkSelected] = useState(false);

  useEffect(() => {
    if (checkFrameSelected && checkArtworkSelected) {
      onAddExhibition(
        selectedFrame?.id || null,
        defaultArtwork?.id || null,
        defaultCollection?.id || null
      )()
      setSelectedId("");
      setDefaultValue({ id: null, title: "" });
      setCheckFrameSelected(false);
      setCheckArtworkSelected(false);
    }
  }, [checkFrameSelected, checkArtworkSelected]);

  const onFrameSelect = (frame: DropdownOption) => {
    // 'Select' option is id 0, do nothing
    if (frame.id === 0) return

    setSelectedFrame(frame);
    setCheckFrameSelected(true);
  };

  const onPickerClose = async (selectedArtwork = [], type: string) => {
    if (type === "artwork") {
      if (selectedArtwork !== null) {
        const tempArtwork = selectedArtwork[0] as Artwork;
        setSelectedId(tempArtwork.id);
        setDefaultArtwork({
          id: Number(tempArtwork.id),
          title: tempArtwork.title,
          imageUrl: tempArtwork.thumbImageUrl,
        } as DropdownOption);
        setDefaultCollection({
          id: null,
          title: "",
        } as DropdownOption);
        // setArtworkActive(true);
      }
      setCheckArtworkSelected(true);
    }
    if (type === "collection") {
      if (selectedArtwork !== null) {
        const tempCollection = selectedArtwork[0] as Collection;
        setSelectedCollectionId(tempCollection.id);
        setDefaultCollection({
          id: Number(tempCollection.id),
          title: tempCollection.name,
        } as DropdownOption);
        setDefaultArtwork({
          id: null,
          title: "",
          imageUrl: "",
        } as DropdownOption);
        // setArtworkActive(true);
      }
      setCheckArtworkSelected(true);
    }
    setShowPicker(false);
  };
  return (
    <tr key={0} className="h-16 bg-gray-200">
      <td className="px-4  whitespace-nowrap">
        <div className="flex items-center">

          {selectedId !== "" ? (
            <img
              className="h-14 w-14 rounded-md bg-[#9E9E9E]"
              src={defaultArtwork?.imageUrl}
            />
          ) : (
            <div className="h-14 w-14  bg-[#9E9E9E] opacity-20 rounded-md"></div>
          )}
        </div>
      </td>
      <td className="px-2  whitespace-nowrap">
        <Dropdown
          width="w-full sm:w-40"
          onOptionSelect={(select: DropdownOption) => onFrameSelect(select)}
          default={defaultValue}
          options={frames}
          disFrame={disFrame}
        ></Dropdown>
      </td>
      <td className="px-2 whitespace-nowrap">
        <button
          onClick={() => setShowPicker(true)}
          className="inline-flex w-full justify-between rounded-md border border-gray-300 shadow-sm px-4 pr-1 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        >
          {selectedId !== "" ? defaultCollection.id ? defaultCollection.title : defaultArtwork.title : "Select artwork "}
          <ChevronDownIcon className=" ml-2 h-5 w-5" aria-hidden="true" />
        </button>
        <ExhibitionPicker
          isOpen={showPicker}
          isMultiSelect={false}
          onCloseModal={onPickerClose}
          selectedArtworksIds={[selectedId]}
          selectedCollectionIds={[selectedCollectionId]}
          selectedType={selectedType}
        ></ExhibitionPicker>
      </td>
      <td className="sm:w-0 md:w-40 lg:w-60"></td>
      <td colSpan={2}></td>
    </tr>
  );
};

export default memo(ExhibitionAddPairRow);

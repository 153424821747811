import { ArrowSmUpIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, memo } from 'react';
import clsx from 'clsx';

import Dropdown from '../../components/shared/Dropdown';
import TableRow from '../../components/tables/table-row';
import { DropdownOption } from '../../components/model/DropdownOption';
import { Artwork } from '../../components/model/Artwork';
import { isAddress, shortenAddress, formatBytes } from '../../util/strings';
import { CONSTANT } from '../../static/constants';
import { display } from '../../static/Enums';
import Spinner from '../../components/shared/Spinner';
import Button from '../../components/shared/Button';
import ArtworkThumb from '../../components/shared/thumbnails/ArtworkThumb';
import { getBadgeForArtwork } from '../../util/artworks';
interface Props {
	data: Artwork;
	pushArtworkToFrame: (artwork: Artwork) => () => void;
	updateDisplay: (id: string) => (selected: DropdownOption) => void;
	onOpenDeleteModal: (id: string) => () => void;
	onEditHandler: (id: any) => () => void;
	artworkLoading: boolean;
}

const ArtworksTableRow: React.FC<Props> = (props) => {
	const { data, updateDisplay, onOpenDeleteModal, onEditHandler, pushArtworkToFrame } = props;

	const { hasBadge, badge } = getBadgeForArtwork(data);
	const className = 'text-*-fg bg-*-bg'.replace(/\*/g, String(badge).toLowerCase());
	const opacity = data?.status === 'processing' ? 'opacity-50' : '';
	return (
		<TableRow key={data.id}>
			<td className="px-2 py-2 md:px-4 lg:px-6">
				<div className="flex items-center">
					<div className="flex-shrink-0 h-14 w-14">
						<ArtworkThumb artwork={data} />
					</div>
				</div>
			</td>
			<td className="md:px-4 lg:px-6 ">
				<div className={`${opacity} text-sm text-primary font-semibold`}>{data?.title}</div>
				<div className={`${opacity} text-sm md:hidden text-gray text-table-cell-color `}>
					{data?.creatorUsername || shortenAddress(data?.creatorAddress)}
				</div>
			</td>
			<td className={`${opacity} md:px-4 lg:px-6 hidden md:table-cell`}>
				<div className="text-sm text-table-cell-color ">
					{data?.creatorUsername || shortenAddress(data?.creatorAddress)}
				</div>
			</td>
			<td className="sm:w-0 md:w-28 lg:w-40"></td>
			<td className="md:pl-6 md:pr-6 ">
				<div className="text-sm">
					<span
						className={clsx(
							'inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-md',
							hasBadge && className
						)}
					>
						{badge}
					</span>
				</div>
			</td>
			{data?.status === 'processing' ? (
				<td colSpan={2} className="md:px-4 lg:px-6 hidden md:table-cell">
					<div className="text-sm text-table-cell-color ">Processing...</div>
				</td>
			) : (
				<>
					<td className="md:px-4 lg:px-6 hidden md:table-cell">
						<div className="text-sm text-table-cell-color ">{data && formatBytes(data.fileSize)}</div>
					</td>
					<td className="md:px-4 lg:px-6 hidden md:table-cell  text-sm text-table-cell-color font">
						{isAddress(data?.source) ? shortenAddress(data?.source) : data?.source}
					</td>
				</>
			)}
			<td className="md:px-4 lg:px-6 hidden md:table-cell">
				<div className="col-span-6 sm:col-span-3">
					<div className="col-span-2">
						{data?.mediaType === 'website' || data?.status === 'processing' ? (
							<span></span>
						) : (
							<Dropdown
                                // disabled={data?.status === 'processing'}
								onOptionSelect={updateDisplay(data.id)}
								options={display}
								width="w-full"
								default={{
									title: data.display,
									id: 1,
								}}
							/>
						)}
					</div>
				</div>
			</td>
			<td className="pl-4 md:px-4 lg:px-6 text-right text-sm font-medium">
				<Button disabled={data?.status === 'processing'} onClick={pushArtworkToFrame(data)} push loading={props.artworkLoading}>
					<ArrowSmUpIcon className="h-5 w-5" />
				</Button>
			</td>
			<td className="text-right">
				<Menu as="div" className="inline-block text-left">
					<div>
						<Menu.Button className="justify-center w-full px-4 py-2 bg-white text-sm font-medium  hover:bg-gray-50">
							<DotsVerticalIcon className=" h-5 text-gray-500" aria-hidden="true" />
						</Menu.Button>
					</div>

					<div className="absolute right-10 z-50">
						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items className="rounded-md z-10 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
								<div className="w-24 min-w-fit">
									<Menu.Item>
										<a
											onClick={onEditHandler(data)}
											className="px-3 py-2 block bg-white rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
										>
											{CONSTANT.EDIT}
										</a>
									</Menu.Item>
									<Menu.Item>
										<a
											onClick={onOpenDeleteModal(data.id)}
											className="px-3 py-2 block text-red-600 bg-white rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
										>
											{CONSTANT.DELETE}
										</a>
									</Menu.Item>
								</div>
							</Menu.Items>
						</Transition>
					</div>
				</Menu>
			</td>
		</TableRow>
	);
};

export default memo(ArtworksTableRow);

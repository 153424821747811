import { useState, useEffect } from "react";
import { CONSTANT, limit } from "../../static/constants";
import PropTypes, { InferProps } from "prop-types";
import { Artwork } from "../../components/model/Artwork";
import ArtworkThumb from "../../components/shared/thumbnails/ArtworkThumb";
import { useGetArtworks } from "../../hooks/artworks/useGetArtworks";
import { useAuth } from "../../authn/authn";
import InfiniteScroll from "react-infinite-scroll-component";
import ArtworkPickerHead from "./artwork-picker-head";
import classNames from "classnames";
import { Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";
import { avoidDuplicates, initSelect, removeItem } from "../utils/Helper";
import Table from "../../components/shared/Table";

function ArtworkPicker(props: InferProps<typeof ArtworkPicker.propTypes>) {
  const [allArtworks, setAllArtworks] = useState<Artwork[]>([]);
  const [selectedArtworks, setSelectedArtworks] = useState<Artwork[]>([]);
  const [searchText, setSearchText] = useState("");
  const authn = useAuth();

  const [sortedColumn, setSortedColumn] = useState<[string, "asc" | "desc"]>([
    "id",
    "desc",
  ]);

  const { loading, error, artworks, fetchMore, artworksCount } = useGetArtworks(
    authn?.getAccountId(),
    limit,
    0,
    sortedColumn.join(":"),
    searchText
  );

  useEffect(() => {
    setAllArtworks(initSelect(artworks, props?.selectedArtworksIds || [], true));
  }, [artworks]);

  const getCheckedCheckbox = (selected: Artwork) => (event: any) => {
    if (props.isMultiSelect) {
      if (!event.target.checked) {
        setAllArtworks(initSelect(allArtworks, removeItem(props?.selectedArtworksIds || [], selected.id)))
        setSelectedArtworks(
          selectedArtworks.filter((artwork) => artwork.id !== selected.id)
        );
      } else {
        setAllArtworks(initSelect(allArtworks, avoidDuplicates(props?.selectedArtworksIds || [], selected.id)))
        setSelectedArtworks([...selectedArtworks, selected]);
      }
    } else {
      setAllArtworks(initSelect(allArtworks, avoidDuplicates(props?.selectedArtworksIds || [], selected.id)))
      setSelectedArtworks([selected]);
    }
  };

  const onSortColumn = (column: string) => () => {
    const [col, sort] = sortedColumn;
    setAllArtworks([]);
    setSortedColumn([
      column,
      column === col ? (sort === "asc" ? "desc" : "asc") : "desc",
    ]);
  };

  const fetchMoreData = async () => {
    await fetchMore({
      variables: {
        offset: allArtworks?.length,
        limit,
      },
    }).then((res: any) => {
      setAllArtworks((artworks) => [...artworks!, ...initSelect(res?.data?.artworks, props?.selectedArtworksIds || [], true)]);
    });
  };

  const onSearch = async (event: any) => {
    event.preventDefault(); // don't redirect the page
    const search = event.target.search.value;
    setSearchText(search);
  };

  return (
    <>
      {props.isOpen ? (
        <div className="fixed left-0 z-40 right-0 top-0 h-full w-screen px-2 text-center  " >
          <div className="relative z-50">
            <div className="inline-block relative mx-auto w-full h-full sm:mt-24 max-w-md p-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="text-center text-[#121826] text-xl font-semibold">
                {props.isMultiSelect ? "Add artworks " : " Select artwork "}
              </div>
              <div className="grid md:flex items-center mt-4 mb-3 justify-center">
                <form onSubmit={onSearch} className="m-0 w-full">
                  <div className="flex border w-full border-bc mx-0 bg-white rounded-md">
                    <input
                      name="search"
                      type="text"
                      className="px-4 py-2 w-full sm:w-90 border-0 bg-white text-search font-base rounded-md font-weight-700 sm:text-sm font"
                      placeholder="Search by artist or title"
                    />
                    <button
                      type="submit"
                      className="flex items-center justify-center px-4 border-l border-bc"
                    >
                      <svg
                        className="w-6 h-6 text-search-icon"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
              <div className="mb-10">
                <div
                  id="scrollableDiv"
                  style={{ height: 400, overflow: "auto" }}
                  className="sm:rounded-md w-full sm:w-100"
                >
                  <InfiniteScroll
                    hasMore={
                      (Number(artworksCount) || 0) >
                      (Number(allArtworks?.length) || 0)
                    }
                    dataLength={Number(allArtworks?.length) || 0}
                    next={fetchMoreData}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                  >
                    <Table>
                      <ArtworkPickerHead
                        sortedColumn={sortedColumn}
                        onSortColumn={onSortColumn}
                      />

                      <tbody className="bg-indigo divide-y divide-table-border-color">
                        {allArtworks?.map((artwork: Artwork) => {
                          return (
                            <tr key={artwork.id} className="w-full h-16">
                              <td className="px-2">
                                <div className="">
                                  <input
                                    title="Selected"
                                    disabled={artwork.isDisabled}
                                    checked={artwork.isSelected}

                                    value={artwork?.id}
                                    onChange={getCheckedCheckbox(artwork)}
                                    className={classNames(
                                      "text-primary-500 cursor-pointer w-5 h-5 focus:ring-opacity-25 rounded",
                                      {
                                        "appearance-none checked:bg-[#D2D5DA]  cursor-not-allowed ":
                                          artwork.isSelected && artwork.isDisabled,
                                      }
                                      , {
                                        "appearance-none checked:bg-[#4C4CDC]":
                                          artwork.isSelected && !artwork.isDisabled,
                                      }
                                    )}

                                    type={
                                      props.isMultiSelect ? "checkbox" : "radio"
                                    }
                                    name="artwork"

                                  />
                                </div>
                              </td>
                              <td className="sm:px-6 ">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 h-14 sm:w-14">
                                    <ArtworkThumb artwork={artwork} />
                                  </div>
                                </div>
                              </td>
                              <td className="sm:px-6 ">
                                <div className="text-sm text-primary font-semibold ">
                                  {artwork?.title} {artwork.isSelected}
                                </div>
                                <div className="text-sm text-gray sm:hidden text-table-cell-color ">
                                  {artwork?.creatorUsername}
                                </div>
                              </td>
                              <td className="sm:px-6 hidden sm:table-cell">
                                <div className="text-sm text-table-cell-color ">
                                  {artwork?.creatorUsername}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                </div>
              </div>

              <div className="absolute bottom-0 left-0 right-0 bg-gray-50 py-3 px-6 flex flex-row-reverse">
                <button
                  disabled={selectedArtworks.length === 0 ? true : false}
                  onClick={() => props.onCloseModal(selectedArtworks)}
                  className="w-full inline-flex justify-center bg-[#4C4CDC] rounded-md border border-[#4C4CDC] shadow-sm px-4 py-2 hover:bg-white hover:text-[#4C4CDC] text-base font font-bold text-white ml-3 w-auto text-sm"
                >
                  {props.isMultiSelect
                    ? `Add ${selectedArtworks !== null ? selectedArtworks.length : ""
                    } artworks`
                    : " Select artwork"}
                </button>

                <button
                  type="button"
                  className="w-full capitalize inline-flex justify-center rounded-md border border-[#D2D5DA] hover:border-[#4C4CDC] hover:text-[#4C4CDC] shadow-sm px-4 py-2 bg-white  text-base font font-bold text-black  ml-3 w-auto text-sm"
                  onClick={() => {
                    props.onCloseModal(null)
                  }}
                >
                  {CONSTANT.CANCEL}
                </button>
              </div>
            </div>
          </div>
          <span
            onClick={() => {
              props.onCloseModal(null)
            }
            }
            className="h-full w-screen bg-black opacity-50 absolute z-20 left-0 right-0 bottom-0 top-0 align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

ArtworkPicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  selectedArtworksIds: PropTypes.array!,
  isMultiSelect: PropTypes.bool,
};

export default ArtworkPicker;

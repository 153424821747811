import React from "react";
import { getBadgeForArtwork } from "../../../util/artworks";

type Props = {
  src?: string;
  artwork?: any;
  // All other props
  [x: string]: any;
};

const ArtworkThumb: React.FC<Props> = (props) => {
  const { artwork, src, ...rest } = props;
  const finalSrc = src || artwork?.thumbImageUrl;
  const { badge } = getBadgeForArtwork(artwork);
  const height = props.height ? props.height : "h-14";
  const width = props.width ? props.width : "w-14";
  const roundedClass = props.roundedClass ? props.roundedClass : "rounded-md";
  const opacity = artwork?.status === 'processing' ? 'opacity-30' : '';
  if (!finalSrc) {
    return (
      <div
        className={`${opacity} ${height} ${width} ${roundedClass} bg-gray-200 text-gray-400 flex items-center justify-center font-bold`}
        {...rest}
      >
        {badge}
      </div>
    );
  }
  return (
    <div
      className={`${roundedClass} ${height} ${width} ${opacity}`}
      style={{
        backgroundImage: `url('${finalSrc}')`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
      {...rest}
    ></div>
  );
};

export default ArtworkThumb;

import { SelectorIcon } from "@heroicons/react/outline";
import React, { memo } from "react";
import clsx from "clsx";

import { CONSTANT } from "../../static/constants";
import TableHead from "../../components/shared/TableHead";
import Table from "../../components/shared/Table";

interface Props {
  onSortColumn: (key: string) => () => void;
  sortedColumn: [string, "asc" | "desc"];
}

const ArtworksTableHead: React.FC<Props> = ({ onSortColumn, sortedColumn }) => {
  return (
    <TableHead>
      <tr>
        {[
          { label: "", sortable: false, key: "" },
          { label: CONSTANT.TITLE, sortable: true, key: "title" },
          { label: CONSTANT.ARTIST, sortable: true, key: "creatorUsername" },
          { label: "", sortable: false, key: "" },
          { label: CONSTANT.TYPE, sortable: true, key: "mimeType" },
          { label: CONSTANT.SIZE, sortable: false, key: "" },
          { label: CONSTANT.SOURCE, sortable: true, key: "source" },
          { label: CONSTANT.DISPLAY, sortable: false, key: "" },
          { label: "", sortable: false, key: "" },
          { label: "", sortable: false, key: "", className: 'w-8' },
        ].map(({ label, sortable, key, className = '' }, index) => {
          const [column] = sortedColumn;
          const isActive = column === key;

          return (
            <th
              className={`px-6 py-3 font font-semibold ${className}`}
              scope="col"
              key={index}
            >
              {sortable ? (
                <button
                  onClick={onSortColumn(key)}
                  className={clsx(
                    "bg-grey-light hover:bg-grey  hover:text-black rounded inline-flex items-start uppercase font-weight-700 font",
                    isActive ? "text-black" : " text-grey-darkest "
                  )}
                >
                  <span className="font-semibold">{label}</span>
                  <SelectorIcon className="h-4 w-4 mt-[2px] inline" />

                </button>
              ) : (
                label
              )}
            </th>
          );
        })}
      </tr>
    </TableHead>
  );
};

export default memo(ArtworksTableHead);

import { ReactNode } from "react";
import DefaultDialog from "../shared/DefaultDialog";
import { getCurrentPlan, getNextPlanDetailsById } from "../../util/frames";
import DefaultButton from "../shared/DefaultButton";
import { SelectedPlan } from "../../contexts/general-toast-context-provider";

const manageSubscriptionLink = process.env.REACT_APP_UPDATE_SUBSCRIPTION_LINK;
const learnMoreAboutPlanLink = process.env.REACT_APP_LEARN_MORE_ABOUT_PLAN_LINK;
const contactUsEmail =
  process.env.REACT_APP_CONTACT_US_EMAIL ?? "jon@zeroframe.art";
const texts = {
  trialModeCardTitle: `You've reached your limit`,
  startYourPlanButtonText: (nextPlan: string) => `Upgrade to ${nextPlan} plan`,
  planNumOfFrame: (
    planType: any,
    limit: number | string,
    current: number | string
  ) =>
    `Your ${planType} plan allows up to ${limit} ${
      limit === 1 ? "frame" : "frames"
    }. You currently have ${current} ${
        current === 1 ? "frame" : "frames"
      }. ${ current > limit ? "Some frames may have been disabled." : "" }`,

  planArtworkStorage: (
    planType: any,
    limit: string | number,
    current: string | number
  ) =>
    `Your ${planType} plan only allows up to ${limit}. You're currently at ${current}.`,
  upgradFramePlan: `Please upgrade your plan, or delete some frames before creating new ones.`,
  upgradArtworkPlan: `Please upgrade your plan, or delete artwork before uploading new one.`,
  learnMoreAboutPlan: (element: ReactNode): ReactNode => (
    <>{element} about plans.</>
  ),

  startBillingText: (planType: string) => `Upgrade to ${planType} plan`,
};

type UpgradePlanModalProps = {
  isModalOpen: boolean;
  onCloseDialog: () => void;
  selectedPlan: SelectedPlan;
  limit: string | number;
  type: "artwork" | "frame";
  consumed: string | number;
};

const UpgradePlanModal: React.FC<UpgradePlanModalProps> = ({
  isModalOpen,
  onCloseDialog,
  selectedPlan,
  limit,
  type = "frame",
  consumed,
}) => {
  const nextPlan = getNextPlanDetailsById(selectedPlan.planId)?.name;
  const currentPlan =  getCurrentPlan(selectedPlan.planId);
  return (
    <DefaultDialog
      dialogClass="w-[470px]"
      isOpen={isModalOpen}
      onClose={onCloseDialog}
    >
      <div className="bg-white text-center rounded-lg shadow-md">
        <div className="rounded-t-lg p-6">
          <h2 className="text-2xl font-bold">{texts.trialModeCardTitle}</h2>
          <p className="text-base mt-4">
            {type === "frame"
              ? texts.planNumOfFrame(
                currentPlan?.name,
                  limit,
                  consumed
                )
              : texts.planArtworkStorage(
                currentPlan?.name,
                  limit,
                  consumed
                )}
          </p>

          <p className="text-base mt-5">
            {type === "frame" ? texts.upgradFramePlan : texts.upgradArtworkPlan}
          </p>
        </div>
        <div>
          <p className="text-base text-gray-500">
            {texts.learnMoreAboutPlan(
              <a href={learnMoreAboutPlanLink} target="_blank" className="underline">
                Learn more
              </a>
            )}
          </p>
        </div>
        <div className="rounded-b-lg p-4 flex justify-center">
          <DefaultButton
            classes="mb-4"
            onClick={() => {
              // Redirect to the specified link
              let url = manageSubscriptionLink;
              if (nextPlan.toLowerCase() === "custom")
                url = `mailto:${contactUsEmail}`;

              window.open(url);
            }}
            text={texts.startBillingText(nextPlan)}
          />
        </div>
      </div>
    </DefaultDialog>
  );
};

export default UpgradePlanModal;

import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InferProps } from "prop-types";
import { Magic } from "magic-sdk";
import { useState, useEffect } from "react";

import analytics from "../../services/analytics";

import { loginWithWallet } from "../../authn/wallet";
import { useAuth } from "../../authn/authn";

import Spinner from "../../components/shared/Spinner";

import logo from "../../assets/logo-black.svg";
interface FormData {
  email: string;
  password: string;
}
const magic = new Magic(String(process.env.REACT_APP_MAGIX_PUBLIC_KEY));
/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
	// ...
	plugins: [
	  // ...
	  require('@tailwindcss/forms'),
	],
  }
  ```
*/

enum RequestStatus {
  LOADING,
  FAILED,
  NONE,
}

function Login(props: InferProps<typeof Login.propTypes>) {
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const { register, handleSubmit } = useForm<FormData>();
  let auth = useAuth();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    analytics.track("page view: sign in");
  }, []);

  const verifyEmail = async (jwtToken: string) => {
    try {
      let resp = await fetch(
        `${process.env.REACT_APP_STRAPI_API_URL}/authn/verify-email`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          credentials: "include",
        }
      );
      const data = await resp.json();
      return data.user;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      setStatus(RequestStatus.LOADING);
      analytics.track("user: initiated sign in");
      const jwtToken = await magic.auth.loginWithMagicLink({ email });

      if (jwtToken) {
        const user = await verifyEmail(jwtToken);
        if (user !== null) {
          setStatus(RequestStatus.NONE);
          analytics.setUserId(user.uuid);
          analytics.track("user: signed in");
          auth.signin();
        } else {
          setStatus(RequestStatus.FAILED);
        }
      } else {
        setStatus(RequestStatus.FAILED);
      }

      setStatus(RequestStatus.NONE);
    } catch (error) {
      setStatus(RequestStatus.FAILED);
      console.log(error);
    }
  });

  const onWalletConnect = async () => {
    try {
      const resp = await loginWithWallet();
      auth.signin();
      navigate("/", { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  if (auth.getUser()) {
    // analytics.setUserId(auth.getUser().uuid)
    // analytics.track('user: signed in')

    // Redirect them to the pricing page, to complete the subscription process, if the user not subscribed yet
    if (!auth.getAccountId()) return <Navigate to="/pricing" state={{ from: location }} replace />;

    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <>
      {/*
		  This example requires updating your template:

		  ```
		  <html class="h-full bg-gray-50">
		  <body class="h-full">
		  ```
		*/}
      <div className="h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
          <img className="block h-6 m-auto" src={logo} alt="Zeroframe" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={onSubmit}>
              <div className="mb-5">
                {status === RequestStatus.FAILED && (
                  <p className="text-red-700 p-4 text-center">
                    Sign in failed. Please try again, or contact support.
                  </p>
                )}
                <p className="font-bold text-gray-600 text-center">
                  Enter your email to receive a sign in link
                </p>
                <p className="text-gray-700 leading-5 text-center text-sm">
                  If it’s your first time, a new account will be created.
                </p>
              </div>
              <div>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    placeholder="Your email"
                    autoFocus
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    {...register("email")}
                    disabled={status === RequestStatus.LOADING}
                  />
                </div>
              </div>

              <div>
                <button
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-active-button focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  disabled={status === RequestStatus.LOADING}
                  type="submit"
                >
                  {status === RequestStatus.LOADING ? (
                    <Spinner className="w-5 h-5" />
                  ) : (
                    "Sign in with email"
                  )}
                </button>
              </div>
            </form>

            {/* <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
              </div>

              <div className="mt-6">
                <div>
                  <button
                    className="w-full flex justify-center text-primary py-2 px-4 border border-bc border-transparent rounded-md shadow-sm text-sm font-medium bg-indigo-600 hover:bg-active-button hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={status === RequestStatus.LOADING}
                    onClick={onWalletConnect}
                    type="submit"
                  >
                    Sign in with wallet
                  </button>
                </div>
              </div>
            </div> */}
            <p className="text-center pt-4 text-sm text-gray-500">
              By signing up, you agree to the{" "}
              <a
                className="underline"
                href="https://app.termly.io/document/terms-of-use-for-saas/3425ea15-b0ea-4bfa-b362-264eb023463c"
                target="_blank"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                className="underline"
                href="https://app.termly.io/document/privacy-policy/7acc4cbd-8fcb-4cdd-bdab-357e0d88c880"
                target="_blank"
              >
                Privacy Policy
              </a>
              , including{" "}
              <a
                className="underline"
                href="https://app.termly.io/document/cookie-policy/2bb4c0c6-e793-4c6a-9329-02233f52313a"
                target="_blank"
              >
                Cookie Use
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

Login.propTypes = {};

export default Login;

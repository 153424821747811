import React, { useState, useEffect, Fragment } from "react";
import Loader from "../../components/shared/loader";
import { CONSTANT, limit } from "../../static/constants";
import PropTypes, { InferProps } from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";

import analytics from '../../services/analytics';

import DeleteModal from "../../components/modal/DeleteModal";
import {
  DropdownOption,
  PairProps,
} from "../../components/model/DropdownOption";
import TableTitleBar from "../../components/tables/table-title-bar";
import { useGetExhibitionDetails } from "../../hooks/exhibitions/useGetExhibitionDetails";
import { useAuth } from "../../authn/authn";
import { Exhibition } from "../../components/model/Exhibition";
import EditExhibition from "../../components/modal/EditExhibition";
import { useDeleteExhibitionPair } from "../../hooks/exhibitions/useDeleteExhibitionPair";
import { useCreatePair } from "../../hooks/exhibitions/useCreateExhibitionPair";
import ExhibitionTableRow from "./exhibition-pair-table-row";
import ExhibitionAddPairRow from "./exhibition-add-pair-row";
import { useUpdateExhibitionPair } from "../../hooks/exhibitions/useUpdateExhibitionPair";
import { useUpdateFrame } from "../../hooks/frames/useUpdateFrame";
import { usePushExhibition } from "../../hooks/exhibitions/usePushExhibition";
import TableBanner from "../../components/shared/TableBanner";
import ExhibitionDetailsTitle from "./exhibition-detail-table-title";
import MainContainer from "../../components/shared/MainContainer";
import TableContainer from "../../components/shared/TableContainer";
import Table from "../../components/shared/Table";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateExhibition } from "../../hooks/exhibitions/useUpdateExhibition";
import Alerts from "../../components/shared/Alert";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useGetExhibitionPairs } from "../../hooks/exhibitions/useGetExhibitionPairs";

type SelectedExhibition = {
  id: number;
  name: string;
};

type Pair = {
  artworkId: number;
  frameId: number;
  // collectionId: number;
};

function ExhibitionDetails(
  props: InferProps<typeof ExhibitionDetails.propTypes>
) {


  const navigation = useNavigate()
  let { id } = useParams();
  const exhibitionId: number = parseInt(id || '');


  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedId, setSelectedId] = useState("0");
  const [allFrame, setAllFrame] = useState<DropdownOption[]>([]);
  const [allArtwork, setAllArtwork] = useState<DropdownOption[]>([]);
  const [allCollection, setAllCollection] = useState<DropdownOption[]>([]);
  const [allExhibition, setAllExhibition] = useState<Exhibition>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [pairs, setPairs] = useState<PairProps[]>();
  const [onLoading, setOnLoading] = useState(false);
  const [selectedExhibition, setSelectedExhibition] =
    useState<SelectedExhibition>();
  const authn = useAuth();
  const deleteExhibitionPair = useDeleteExhibitionPair();
  const createExhibitionPair = useCreatePair();
  const [frameLoading, setFrameLoading] = useState("");
  const updateExhibitionPair = useUpdateExhibitionPair();
  const pushExhibition = usePushExhibition();
  const updateFrame = useUpdateFrame();
  const updateExhibition = useUpdateExhibition();


  const disFrame: any = [];

  const {
    loading,
    error,
    exhibition,
    artworks,
    frames,
    collections,
    pairCount,

  } = useGetExhibitionDetails(
    authn.getUser().id,
    Number(exhibitionId),
    limit,
    0,
    "id:desc"
  );

  const {
    fetchMore,
  } = useGetExhibitionPairs(
    Number(exhibitionId),
    limit,
    0,
    "id:desc"
  );

  //opens the Confirm Delete Modal
  const onDeleteModalClose = async (state: boolean) => {
    if (state) {
      setLoader(true);

      const ids: number[] = [];
      pairs?.map((pair) => {
        if (pair.id != Number(selectedId)) {
          ids.push(pair.id);
        }
      });

      // TODO: error handling
      deleteExhibitionPair({
        variables: {
          input: {
            where: {
              id: selectedId,
            },
          },
        },
      });
      analytics.track('exhibition: pair removed', {
        exhibitionId: exhibition.id,
    });
    }
    setLoader(false);
    setShowDeleteModal(false);
  };

  const onOpenDeleteModal = (id: string) => () => {
    setShowDeleteModal(true);
    setSelectedId(id);
  };

  const onUpdatePairRecord = async (
    artworkId: number | string | null,
    frameId: string,
    collectionId: number | string | null,
    pairId: string
  ) => {
    setLoader(true);
    const res = await updateExhibitionPair({
      variables: {
        input: {
          data: {
            artwork: artworkId,
            frame: frameId,
            artworkCollection: collectionId,
          },
          where: {
            id: pairId,
          },
        },
      },
    });
    analytics.track('exhibition: pair updated', {
        artworkId: artworkId,
        collectionId: collectionId,
        frameId: frameId,
        exhibitionId: exhibition.id,
    });
    setLoader(false);
  };

  const fetchMoreData = () => {
    setLoader(true);
    fetchMore({
      variables: {
        offset: pairs?.length,
        limit: limit
      },
    }).then((fetchMoreResult) => {
      setPairs((pairs) => [...pairs!, ...fetchMoreResult?.data?.exhibitions[0]?.pairs])

    });
    setLoader(false);
  };

  const pushToFrame =
    (
      frameId: number,
      artworkId: number | null,
      artworkCollectionId: number | null
    ) =>
      async () => {
        setFrameLoading(frameId + "");
        await updateFrame({
          variables: {
            input: {
              data: {
                artwork: artworkId,
                artworkCollection: artworkCollectionId,
              },
              where: {
                id: frameId + "",
              },
            },
          },
        });
        const model = artworkCollectionId ? 'collection' : 'artwork'
        const id = artworkCollectionId || artworkId
        analytics.track(`${model}: pushed`, {
            id: id,
            frameId: frameId,
            source: 'exhibition detail'
        })
        analytics.add('push_count')
        setFrameLoading("");
      };

  // TODO: don't duplicate this from exhibitions.tsx
  // TODO: exhibitions are stored in the DB, so no need to push in this way -- an endpoint that is just 'pushExhibition'
  // could fetch the exhibition and push it out to all the frames
  const onPushExhibition = async () => {
    const result = await pushExhibition({
      variables: {
        id: exhibition.id,
      },
    });
    analytics.track('exhibition: pushed', {
        exhibitionId: exhibition.id,
        source: 'exhibition detail'
    });
    analytics.add('push_count')
  };

  const onAddExhibition =
    (
      frameId: number | null,
      artwork: number | null,
      collection: number | null
    ) =>
      async () => {
        // TODO: error handling
        await createExhibitionPair({
          variables: {
            input: {
              data: {
                position: 0,
                artwork: artwork,
                artworkCollection: collection,
                frame: frameId,
                exhibition: allExhibition?.id
              },
            },
          },
        });
        analytics.track('exhibition: pair added', {
            artworkId: artwork,
            collectionId: collection,
            frameId: frameId,
            exhibitionId: allExhibition?.id,
        });
      };

  const onCloseEditModal = (exhibition: any) => {
    if (exhibition) {
      setSelectedExhibition(exhibition);
      onEditExhibition(exhibition);
    }
    setShowEditModal(false);
  };

  useEffect(() => {
    setAllExhibition(exhibition);
    setPairs(exhibition?.pairs);
    setSelectedExhibition({
      id: exhibitionId,
      name: exhibition?.name,
    });
  }, [exhibition]);

  useEffect(() => {
    if (frames && frames?.length > 0) {
      setAllFrame(frames);
    }
  }, [frames]);



  const onEditExhibition = async (exhibition: Exhibition) => {
    if (exhibition) {
      const res = await updateExhibition({
        variables: {
          input: {
            data: {
              name: exhibition.name,
            },
            where: {
              id: exhibition.id + "",
            },
          },
        },
      });
      analytics.track('exhibition: updated', {
        exhibitionId: exhibition.id,
        source: 'exibition detail'
    });
    }
    setShowEditModal(false);
  };



  useEffect(() => {
    setAllArtwork(artworks);
  }, [artworks]);
  useEffect(() => {
    setAllCollection(collections);
  }, [collections]);


  const backhandler = () => {
    navigation('/exhibitions')
  }

  return (
    <MainContainer>
      {
        !loading && (
          exhibition ?
            <div>
              <TableTitleBar>
                <TableBanner
                  title={
                    <ExhibitionDetailsTitle
                      titleClickHanaler={backhandler}
                      exhibitionName={exhibition?.name || ''}
                    />
                  }
                  addHandler={() => setShowEditModal(true)}
                  buttonTitle={CONSTANT.EDIT_EXHIBITION}
                  buttonTitleSmallSize={CONSTANT.EDIT}
                  pushToFrameHandler={onPushExhibition}
                  isPussButtonAvaliable={true}
                />
              </TableTitleBar>

              <TableContainer>
                <InfiniteScroll
                  dataLength={Number(pairs?.length) || 0}
                  next={fetchMoreData}
                  hasMore={(Number(pairCount) || 0) >
                    (Number(pairs?.length) || 0)}
                  loader={<h4></h4>}
                >
                  <Table>
                    <thead className="bg-table-header-bg text-table-header-fg text-left text-sm uppercase tracking-wider">
                      <tr className="text-table-header-fg">
                        <th scope="col" className="px-6 py-3 text-left">
                          {CONSTANT.IMAGE}
                        </th>
                        <th scope="col" className="px-6 py-3 text-left">
                          {CONSTANT.FRAME}
                        </th>
                        <th scope="col" className="px-6 py-3 text-left">
                          {CONSTANT.ARTWORK}/{CONSTANT.COLLECTION}
                        </th>
                        <th className="sm:w-0 md:w-40 lg:w-60"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {pairs?.map((pair, i) => (


                        <Fragment key={i + 1}>
                          <div className="hidden">
                            {disFrame.push(pair?.frame?.id!)}
                          </div>
                          <ExhibitionTableRow
                            key={i + 1}
                            frames={allFrame}
                            disFrame={disFrame}
                            artworks={allArtwork}
                            collections={allCollection}
                            pair={pair}
                            onOpenDeleteModal={onOpenDeleteModal}
                            pushToFrame={pushToFrame}
                            onLoading={frameLoading === pair?.frame?.id! + ""}
                            onUpdateExhibitionPair={onUpdatePairRecord}
                          ></ExhibitionTableRow>
                        </Fragment>
                      ))}

                      <ExhibitionAddPairRow
                        key={0}
                        frames={allFrame}
                        disFrame={disFrame}
                        onAddExhibition={onAddExhibition}
                      ></ExhibitionAddPairRow>
                    </tbody>
                  </Table>
                </InfiniteScroll>
              </TableContainer>
            </div>
            :
            <Alerts
              className={'text-red-600 bg-red-200 bg-opacity-100'}
              text={CONSTANT.DATA_NOT_FOUND}
              icon={<ExclamationCircleIcon className="w-7" />}
            />
        )

      }


      <div>
        <EditExhibition
          onCloseEditModal={onCloseEditModal}
          isOpen={showEditModal}
          exhibition={selectedExhibition}
        ></EditExhibition>
        <DeleteModal
          isOpen={showDeleteModal}
          title={CONSTANT.FRAME}
          description={CONSTANT.REMOVE_EXHIBITION_PAIR}
          onCloseDeleteModal={(state: boolean) => {
            onDeleteModalClose(state);
          }}
        ></DeleteModal>
      </div>
      <Loader isLoading={loader}></Loader>
    </MainContainer>
  );
}

ExhibitionDetails.propTypes = {
  // closeExhibitionDetails: () => {},
  exhibition: PropTypes.any,
  // onExhibitionEdit: () => {},
};

export default ExhibitionDetails;

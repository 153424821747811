import { useEffect, useRef, useState } from "react";
import { CONSTANT } from "../../static/constants";
import { Frame } from "../model/Frame";
import VerificationInput from "react-verification-input";
import './PairCode.css'
import PropTypes, { InferProps } from "prop-types";
import Alerts from "./Alert";
import { ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/solid";


function PairCode({
    setPairingCode,
    topMessage,
    setError,
    error = null,
    infoMessage = null,
}: InferProps<typeof PairCode.propTypes>) {

    const onPairingCodeChange = (value: string) => {
        if (error !== '') {
            setError('')
        }
        setPairingCode(value)
    }

    return (
        <div className="py-2">
            <p className="block text-sm font-bold text-gray-700">{topMessage}</p>

            <div className="max-w-xs text-center m-auto py-1">

                <VerificationInput
                    placeholder=""
                    removeDefaultStyles
                    classNames={{
                        container: "container",
                        character: "character",
                        characterInactive: "character--inactive",
                        characterSelected: "character--selected",
                    }}
                    onChange={onPairingCodeChange} />
            </div>

            {
                error &&
                <Alerts
                    className={'text-red-600 bg-red-200 bg-opacity-100'}
                    text={error}
                    icon={<ExclamationCircleIcon className="w-7" />}
                />
            }
            {
                infoMessage &&
                <Alerts
                    className={'text-[#4c4cdc] bg-[#eef2ff] bg-opacity-100'}
                    text={infoMessage}
                    icon={<InformationCircleIcon className="w-7" />}
                />
            }


        </div>
    );
}


PairCode.propTypes = {
    setPairingCode: () => void {},
    topMessage: PropTypes.string,
    error: PropTypes.string,
    setError: () => void {},
    infoMessage: PropTypes.string,
};


export default PairCode;

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { DropdownOption } from "../model/DropdownOption";
import PropTypes, { InferProps } from "prop-types";

function DropdownWithoutSelect(props: InferProps<typeof DropdownWithoutSelect.propType>) {
  const [selected, setSeleted] = useState<DropdownOption>(props?.default);

  useEffect(() => {
    if(props.default==1) {
      setSeleted(props?.options[0]);
    } else {
      setSeleted(props?.options[1]);
    }
  }, [props.default]);

  const onOptionSelected = (option: DropdownOption) => {
    setSeleted(option);
    props.onOptionSelect(option);
  };
  const onOptionDefault = (val: string) => {
    setSeleted(props?.default);
    props.onOptionSelect(props?.default);
  };

  // TODO: move disFrames out of this component, the DropdownOption model should have a disabled property
  // and the logic for determining if a dropdown option is disabled should be external to this shared component
  const renderOption = (option: DropdownOption, index = 0) => {
    const isDisabled = props?.disFrame?.includes(option.id)
    const disClass = isDisabled ? 'text-gray-300 ' : '';
    return (
        <Menu.Item key={option.id || index}>
          <button
            disabled={isDisabled}
            onClick={() => onOptionSelected(option)}
            className={
              disClass +
              "w-full text-left block px-4 py-2 text-sm cursor-pointer hover:bg-table-header-bg rounded-none"
            }
          >
            {option?.title}
          </button>
        </Menu.Item>
    );
  };

  return (
    <Menu as="div" className="inline-block text-left">
      <div>
        <Menu.Button
          className={
            props.width +
            " inline-flex justify-between rounded-md border border-gray-300 shadow-sm px-4 pr-1 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          }
        >
          {props?.noSlect ? null :(
           props?.options?.title 
          )}
          {selected && selected?.id !== 0 ? selected?.title : "Select"}
          <ChevronDownIcon className=" ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <div className=" w-44 absolute right-46 z-50">
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className=" origin-top-right z-10 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className={props?.width + " max-h-60 overflow-scroll"}>
              {props?.noSlect ? null :(
              <Menu.Item key="selectOption">
                <button
                  onClick={() => onOptionDefault("Select")}
                  className="block px-4 py-2 text-sm cursor-pointer hover:bg-table-header-bg"
                >
                  {/* {option?.title} */}
                  {/* Select */}
                </button>
              </Menu.Item>
              )}
              {props?.options?.map((option: DropdownOption, idx: number) =>
                renderOption(option, idx)
              )}
            </div>
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  );
}

DropdownWithoutSelect.propType = {
  onOptionSelect: (item: DropdownOption) => {},
  options: [],
  width: "w-96",
  default: [],
  disFrame: [],
  noSlect: Boolean,
};

export default DropdownWithoutSelect;

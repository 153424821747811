import React from "react";

interface Props {
    children: any;
}

const TableHead: React.FC<Props> = (props) => {
    const { children } = props;
    return (
        <thead className="bg-table-header-bg hidden md:table-row-group text-table-header-fg text-left text-sm uppercase tracking-wider">
            {children}
        </thead>
    );
};

export default TableHead
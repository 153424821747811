import React, { ReactText, useContext, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { generalToastContext } from "../../contexts/general-toast-context-provider";
import { CheckCircleIcon } from "@heroicons/react/outline";
import "./GeneralToast.css";
import { GENERAL_TOAST_CONSTANT } from "../../static/Enums";

// Define a mapping of toast messages
const toastMessages: Record<
  string,
  {
    header: string;
    content: string | ((planName?: string, date?: string) => string);
  }
> = {
    [GENERAL_TOAST_CONSTANT.DOWNGRADING]: {
        header: 'Plan Update successfully',
        content: (planName, date) => `Your ${planName} will take effect on ${date}. We’ll keep your frames, exhibitions and media that exceeds the Collector limit for 30 days after that date.`,
      },
      [GENERAL_TOAST_CONSTANT.UPGRADE]: {
        header: 'Plan Update successfully',
        content :(plan)=> `Your ${plan} starts now!`,
      },
      [GENERAL_TOAST_CONSTANT.CANCELLING]: {
        header: 'Subscription cancelled successfully',
        content: (plan, date) => `Your ${plan} is valid until ${date}. We’ll keep your data for 30 days after that.`,
      },
      [GENERAL_TOAST_CONSTANT.TRAIL_END]: {
        header: 'Trial period has ended',
        content: 'Enjoy all the features in your plan!',
      },
      [GENERAL_TOAST_CONSTANT.WELCOME]: {
        header: 'Welcome to zeroframe!',
        content: `Get started by pairing a display (TV, tablet, projector) in Frames`,
      },
};

const GeneralToast: React.FC = () => {
  const { generalToast,selectedPlan } = useContext(generalToastContext);
  const toastRef = useRef<ReactText>();

  useEffect(() => {
    if (generalToast in toastMessages) {
      const message = toastMessages[generalToast];
      const toastContent =
        typeof message.content === "function"
          ? message.content(selectedPlan.plan, selectedPlan.currentPeriodEnd)
          : message.content;

      toastRef.current = toast(
        <>
          <span className="toast-header">{message.header}</span>
          <p className="toast-content">{toastContent}</p>
        </>,
        {
          icon: generalToast===GENERAL_TOAST_CONSTANT.WELCOME ?<span className="emoji-hand">👋</span> : <CheckCircleIcon className="toast-icon" />,
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          closeOnClick:true,
          autoClose:false
        }
      );
    }
  }, [generalToast]);

  return null;
};

export default GeneralToast;

import { useQuery, gql, useMutation } from "@apollo/client";

interface deleteExhibitionInput {
  variables: {
    input: {
      where: {
        id: string;
      };
    };
  };
}

const DELETE_EXHIBITION = gql`
  mutation DeleteExhibition($input:deleteExhibitionInput) {
    deleteExhibition(input:$input) {
      exhibition {
        id
      }
    }
  }
`;

export const useDeleteExhibition = (): ((
  deleteFrame: deleteExhibitionInput
) => any) => {
  const [deleteExhibitionResult] = useMutation(DELETE_EXHIBITION, {
    update(cache, { data: { deleteExhibition } }) {
      cache.modify({
        fields: {
          exhibitions(existingExhibition = [], { readField }) {
            return existingExhibition.filter(
              (exhibition: any) => deleteExhibition.exhibition.id !== readField('id', exhibition)
            )
          },
        }
      });
    },
  });

  return deleteExhibitionResult;
};

import { useQuery, gql } from "@apollo/client";

export const GET_ARTWORKS = gql`
  query GetArtworks(
    $accountId: ID!
    $limit: Int
    $offset: Int
    $order: String
    $search: String
  ) {
    artworks(
      where: {
        account: $accountId
        _or: [
          { id_contains: $search }
          { title_contains: $search }
          { creatorUsername_contains: $search }
        ]
      }
      limit: $limit
      start: $offset
      sort: $order
    ) {
      creatorAddress
      mediaType
      thumbImageUrl
      display
      bgColor
      id
      title
      qrCodeUrl
      creatorUsername
      source
      mimeType
      fileSize
      mediaUrl_720
      artworkUrl
      assetProviderId
      status
    }
    frames(where: { account: $accountId }) {
      id
      name
      isConnected
      artwork {
        id
        thumbImageUrl
      }
      artworkCollection {
        id
        artworks {
          artwork {
            id
            thumbImageUrl
          }
        }
      }
      updated_at
    }
    artworksCount(
      where: {
        account: $accountId
        # _or: [
        #   { title_contains: $search }
        #   { creatorUsername_contains: $search }
        # ]
      }
    )
  }
`;

export const useGetArtworks = (
  accountId: any,
  limit?: number,
  offset?: number,
  order?: string,
  search?: string,
  onCompleted?: any
) => {
  const { loading, error, data, fetchMore, refetch, startPolling, stopPolling } = useQuery(GET_ARTWORKS, {
    variables: { accountId, limit, offset, order, search },
    fetchPolicy: "cache-and-network",
    // pollInterval: 5000,
    onCompleted: (data) => {
        if (onCompleted) {
            onCompleted(data);
        }
    }
  });
  return {
    artworks: data?.artworks,
    frames: data?.frames,
    artworksCount: data?.artworksCount,
    fetchMore,
    loading,
    error,
    refetchArtworks: refetch,
    startPolling,
    stopPolling
  };
};

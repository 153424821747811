import React from "react";
import DefaultDialog from "../shared/DefaultDialog";
import DefaultButton from "../shared/DefaultButton";

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
}
const texts = {
  zeroframeInfo1:
    "Zeroframe is a platform for collectors, curators and galleries to display digital art on any screen – a smartTV, tablet or projector.",
  zeroframeInfo2:
    "Easily display your collection, manage multiple displays in a gallery or curate artworks for a remote location. It supports images, videos, GIFs and web-based generative artworks.",
  zeroframeInfo3: (
    <>
      Try it for free at{" "}
      <span className="text-[#4F46E5] font-bold">
        zeroframe.art
      </span>
    </>
  ),
};
const KioskModeDetails: React.FC<DialogProps> = ({ isOpen, onClose }) => {
  return (
    <DefaultDialog dialogClass="w-[480px] mx-4" isOpen={isOpen} onClose={onClose}>
      <div className="bg-white rounded-lg shadow-md">
        <div className="rounded-t-lg p-4">
          <div className="flex justify-start pt-4 px-4 ">
            <div className="text-xl">
              <p className="pb-2">{texts.zeroframeInfo1}</p>
              <p className="pb-2">{texts.zeroframeInfo2}</p>
              <p className="pb-2">{texts.zeroframeInfo3}</p>
            </div>
          </div>
        </div>
      </div>
    </DefaultDialog>
  );
};

export default KioskModeDetails;

import { SYNC_NFTS_CONSTANT } from "../../static/Enums";

const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;

// Kick off a new NFT Sync Request
export async function createNftSyncRequest(publicAddresses?: string[]) {
	const body: any = {};
    if (publicAddresses) {
        body['publicAddresses'] = publicAddresses;
    }
    return await fetch(`${strapiUrl}/nft-sync-request`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify(body),
	}).then((response) => response.json());
}

// Get an NFT Sync Request
export async function getNftSyncRequest(id: string) {
	return await fetch(`${strapiUrl}/nft-sync-request/${id}`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'GET',
		credentials: 'include',
	}).then((response) => response.json());
}

export async function getPendingNftSyncRequest() {
	return await fetch(`${strapiUrl}/nft-sync-request?status=pending`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'GET',
		credentials: 'include',
	}).then((response) => response.json());
}

export async function cancelPendingNftSyncRequest(id: string) {
	const body: any = {
        status: SYNC_NFTS_CONSTANT.SYNC_CANCELLED,
        id: id
    };

    return await fetch(`${strapiUrl}/nft-sync-request/${id}`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'PUT',
		credentials: 'include',
		body: JSON.stringify(body),
	}).then((response) => response.json());
}
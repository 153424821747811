/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { CONSTANT } from "../../static/constants";
import PropTypes, { InferProps } from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { XIcon } from "@heroicons/react/outline";

import ModalActions from './ModalActions'
import ModalHeader from "./ModalHeader";

function EmailModal(props: InferProps<typeof EmailModal.propTypes>) {
  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join("");
  };

  const schema = yup.object().shape({
    email: yup.string().required(),
  });

  // validation
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={props.onCloseEmailModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start w-full">
                  <div className=" text-center w-full">
                    <div className="mb-7 flex flex-row-reverse">
                      <a
                        className="cursor-pointer"
                        onClick={() => props.onCloseEmailModal()}
                      >
                        <XIcon className="h-7 w-7 text-gray-400 hover:text-primary"></XIcon>
                      </a>
                    </div>
                    <ModalHeader title="Update email" />
                    <div className="mt-2">
                      <p className="text-sm w-full text-gray-500 text-center font font-medium">
                      Enter your email to receive a verification link
                      </p>
                    </div>
                    <div className="mt-">
                      <form onSubmit={handleSubmit(props.addEmailHandler)} action="#" method="POST">
                        <div className="overflow-visible sm:rounded-md">
                          <div className="px-4 py-5 mb-28 sm:p-6 ">
                            <div className="flex flex-col flex-1 gap-6  w-full">
                              <div className="w-full">
                                <input
                                  id="interval"
                                  {...register("email")}
                                  onChange={(e) =>
                                    props.setAddEmailHandler(e.target.value)
                                  }
                                  placeholder="email "
                                  type="email"
                                  required
                                  className={classNames(
                                    errors.interval?.message == null
                                      ? "focus:ring-primary-500 focus:border-primary-500  border-gray-300 "
                                      : "focus:ring-secondary-500 focus:border-secondary-500  border-secondary-300 ",
                                    " mt-1 block w-full shadow-sm sm:text-sm rounded-md font"
                                  )}
                                />
                                <p className="text-secondary-400 font">
                                  {errors.emial?.message}
                                </p>
                              </div>
                            </div>
                          </div>
                          <ModalActions confirmText={CONSTANT.SEND_EMAIL} onConfirm={() => props.addEmailHandler()} onCancel={() => props.onCloseEmailModal()} />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

EmailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseEmailModal: PropTypes.func.isRequired,
  addEmailHandler: PropTypes.func.isRequired,
  setAddEmailHandler: PropTypes.func.isRequired,
};

export default EmailModal;

const colors = require('tailwindcss/colors');

module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  safelist: [
    'text-img-fg',
    'bg-img-bg',
    'text-gif-fg',
    'bg-gif-bg',
    'text-vid-fg',
    'bg-vid-bg',
    'text-web-fg',
    'bg-web-bg',
  ],
  theme: {
    fontFamily: {
      sans: ['"Source Sans Pro"', 'sans-serif'],
      mono: ['"Source Code Pro"', 'monospace'],
    },
    screens: {
      'sm': '640px',
      // => @media (min-width: 640px) { ... }

      'md': '860px',
      // => @media (min-width: 768px) { ... }

      'lg': '1024px',
      // => @media (min-width: 1024px) { ... }

      'xl': '1280px',
      // => @media (min-width: 1280px) { ... }

      '2xl': '1536px',
      // => @media (min-width: 1536px) { ... }
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: colors.black,
      white: colors.white,
      gray: colors.gray,
      zinc: colors.zinc,
      indigo: colors.indigo,
      green: colors.green,
      red: colors.red,
      bc: '#D2D5DA',
      ic: '#1A1A1A',
      'img-fg': '#2343A9',
      'img-bg': '#DEEAFC',
      'gif-fg': '#86441D',
      'gif-bg': '#FCF2DE',
      'vid-fg': '#90274D',
      'vid-bg': '#F8E8F2',
      'web-fg': '#212936',
      'web-bg': '#F3F4F6',
      'empty-img-bg': '#E3E3E3',
      'chip': '#4D5562',
      'back-nav-color': '#9CA4B7',
      'table-header-bg': '#F9FAFB',
      'table-border-color': '#E5E7EB',
      'table-cell-color': '#6D7380',
      'background': '#E5E5E5',
      'table-header-fg': '#6C727F',
      'active-button': '#4C4CDC',
      'dots': '#C4C4C4',
      'action': '#9DA3AE',
      'select': '#394150',
      'search': '#A7AEBC',
      'search-icon': '#A4A6AA',
      'thumbnail': '#9E9E9E',
      primary: '#212936',
      secondary: '#121826'
    }
  },
  variants: {
    extend: {
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
  ],
}

import { useQuery, gql, useMutation } from "@apollo/client";

interface updateWalletInput {
  variables: {
    input: {
      data: {
        publicAddress: string;
        creator: string;
      };
      where: {
        id: string;
      };
    };
  };
}

export const UPDATE_WALLET = gql`
  mutation UpdateWallet($input: updateWalletInput!) {
    updateWallet(input: $input) {
      wallet {
        id
        publicAddress
        owner {
          id
        }
      }
    }
  }
`;

export const useUpdateWallet = (): ((
  updateWallet: updateWalletInput
) => any) => {
  const [updateWallet] = useMutation(UPDATE_WALLET, {
    update(cache, { data: { updateWallet } }) {
      cache.modify({
        fields: {
          wallets(existingWallets = []) {
            return updateWallet.wallet;
            // const newWalletRef = cache.writeFragment({
            //   data: updateWallet,
            //   fragment: gql`
            //     fragment UpdateWallet on Wallet {
            //       id
            //       display
            //     }
            //   `,
            // });
            // return [...existingWallets, newWalletRef];
          },
        },
      });
    },
  });

  return updateWallet;
};

import { useQuery, gql, useMutation } from "@apollo/client";
import { GET_WALLETS } from "./useGetWallets";

interface createWalletInput {
  variables: {
    input: {
      data: {
        publicAddress: string;
        blockchain: string;
        walletProvider: string;
        owner: string;
      };
    };
  };
}

export const CREATE_WALLET = gql`
  mutation CreateWallet($input: createWalletInput!) {
    createWallet(input: $input) {
      wallet {
        id
        publicAddress
        blockchain
        walletProvider
        owner {
          id
        }
      }
    }
  }
`;

export const useCreateWallet = (): ((
  createWalletInput: createWalletInput
) => any) => {
  const [createWallet] = useMutation(CREATE_WALLET, {
    refetchQueries: [GET_WALLETS, "GetWallets"],
    // update(cache, { data: { createWallet } }) {
    //   const existingWallets = cache.readQuery({
    //     query:GET_ARTWORKS
    //   })
    //   cache.modify({
    //     fields: {
    //       artworks(existingWallets = []) {
    //         const newWalletRef = cache.writeFragment({
    //           data: createWallet,
    //           fragment: gql`
    //             fragment NewWallet on Wallet {
    //               id
    //               title
    //               description
    //               artist
    //               creatorUsername
    //               thumbImageUrl
    //               originalImageUrl
    //               source
    //               mediaType
    //               owner
    //             }
    //           `,
    //         });
    //         return [...existingWallets, newWalletRef];
    //       },
    //     },
    //   });
    // },
  });
  return createWallet;
};

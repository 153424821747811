import React from 'react';

interface HorizontalLineProps {
  color?: string;
  thickness?: number;
  margin?: string;
}

const HorizontalLine: React.FC<HorizontalLineProps> = ({
  color = '#E5E7EB',
  thickness = 1,
  margin = '0',
}) => {
  const lineStyle: React.CSSProperties = {
    borderBottom: `${thickness}px solid ${color}`,
    margin: margin,
  };

  return <div className="w-full" style={lineStyle}></div>;
};

export default HorizontalLine;

import classNames from "classnames";
import React, { ButtonHTMLAttributes, useCallback } from "react";

interface DefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  bgColor?: string;
  text: string;
  isPlain?: boolean;
  classes?: string;
  isLink?: boolean;
}

const DefaultButton: React.FC<DefaultButtonProps> = ({
  bgColor = "#4C4CDC",
  onClick,
  text = "Default",
  isPlain = false,
  classes = "",
  isLink = false,
  ...props
}) => {
  const handleClick = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );

  return (
    <button
      onClick={handleClick}
      className={classNames(
        "font-semibold py-2 px-4 rounded-lg focus:outline-none transition duration-300 ease-in-out",
        isLink
          ? "px-[0px] underline underline-offset-4"
          : isPlain
          ? "text-[#4C4CDC] border-[#D2D5DA] border-2 hover:bg-gray-100"
          : `bg-[${bgColor}] text-white hover:bg-opacity-90 focus:ring-2 focus:ring-opacity-50`,
        classes
      )}
      {...props}
    >
      {text}
    </button>
  );
};

export default DefaultButton;

import { useQuery, gql } from "@apollo/client";

export const GET_WALLETS = gql`
  query GetWallets(
    $onwerId: ID!
    $limit: Int
    $offset: Int
    $order: String
    # $search: String
  ) {
    wallets(
      where: {
        owner: $onwerId
        # _or: [
        #   { title_contains: $search }
        #   { creatorUsername_contains: $search }
        # ]
      }
      limit: $limit
      start: $offset
      sort: $order
    ) {
      id
      publicAddress
      blockchain
      walletProvider
    }
    # walletsCount(
    #   where: {
    #     creator: $onwerId
    #     # _or: [
    #     #   { title_contains: $search }
    #     #   { creatorUsername_contains: $search }
    #     # ]
    #   }
    # )
  }
`;

export const useGetWallets = (
  onwerId: any,
  limit?: number,
  offset?: number,
  order?: string,
  search?: string
) => {
  const { loading, error, data, fetchMore } = useQuery(GET_WALLETS, {
    variables: { onwerId, limit, offset, order, search },
    fetchPolicy: "cache-and-network",
  });
  return {
    wallets: data?.wallets,
    // frames: data?.frames,
    walletsCount: data?.walletsCount,
    fetchMore,
    loading,
    error,
  };
};

import React from "react";

type Props = {
  src?: any;

  // All other props
  [x: string]: any;
};

const ArtworkCollectionThumb: React.FC<Props> = (props) => {
  const { src, ...rest } = props;
  return (
    <div className="relative">
      <div className="flex-shrink-0 h-14 w-14">
        <img
          className="h-9 w-9 border-2 border-white rounded-md"
          src={src[0]?.artwork?.thumbImageUrl || src[0]?.thumbImageUrl  || "/images/Placeholder1.jpg"}
        />
      </div>
      <div className="flex-shrink-0 absolute left-[10px] top-[10px]">
        <img
          className="h-9 w-9 border-2 border-white rounded-md"
          src={src[1]?.artwork?.thumbImageUrl || src[1]?.thumbImageUrl  || "/images/Placeholder1.jpg"}
        />
      </div>
      <div className="flex-shrink-0 absolute left-[20px] top-[20px]">
        <img
          className="h-9 w-9 border-2 border-white rounded-md"
          src={src[2]?.artwork?.thumbImageUrl || src[2]?.thumbImageUrl  || "/images/Placeholder1.jpg"}
        />
      </div>
    </div>
  );
};

export default ArtworkCollectionThumb;

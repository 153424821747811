import PropTypes, { InferProps } from "prop-types";

function TableTitle({ title, count, className }: InferProps<typeof TableTitle.propTypes>) {

    return (
        <div className={className || '' + ''} >
            <div className=" md:pt-1.5 text-3xl text-secondary font-bold">
                 {title} 
                {
                (count || 0) > 0 && (
                    <span className="py-1 ml-3 relative -top-1 px-3 h-8 text-base font-semibold text-chip bg-empty-img-bg rounded-full">
                         {count}
                    </span>
                )
            }
            </div>
            
        </div>
    );
}

TableTitle.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    count: PropTypes.number,
    className: PropTypes.string
};

export default TableTitle;

import { gql, useMutation } from "@apollo/client";
import { GET_COLLECTIONS } from "./useGetCollections";

interface deleteCollectionArtworkInput {
  variables: {
    input: {
      where: {
        id: string;
      };
    };
  };
}

const DELETE_COLLECTION_ARTWORK = gql`
  mutation DeleteCollectionArtwork($input: deleteCollectionArtworkInput) {
    deleteCollectionArtwork(input: $input) {
      collectionArtwork {
        id
      }
    }
  }
`;

export const useDeleteCollectionArtwork = (): ((
  deleteCollection: deleteCollectionArtworkInput
) => any) => {
  const [deleteCollection, { loading: mutationLoading, error: mutationError }] =
    useMutation(DELETE_COLLECTION_ARTWORK, {
      refetchQueries: [GET_COLLECTIONS, "GetCollections"],
    });
  return deleteCollection;
};

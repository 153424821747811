import { useQuery, gql, useMutation } from "@apollo/client";
import { GET_EXHIBITION_DETAILS } from "./useGetExhibitionDetails";

interface deleteExhibitionPairInput {
    variables: {
        input: {
            where: {
                id: string;
            };
        };
    };
}

const DELETE_EXHIBITION_PAIR = gql`
    mutation DeleteExhibitionPair($input:deleteExhibitionPairInput) {
        deleteExhibitionPair(input:$input) {
            exhibitionPair {
                id
      }
    }
  }
`;

export const useDeleteExhibitionPair = (): ((
    deleteFrame: deleteExhibitionPairInput
) => any) => {
    const [deleteExhibitionPairResult] = useMutation(DELETE_EXHIBITION_PAIR, {
        refetchQueries: [GET_EXHIBITION_DETAILS, "GetExhibitionDetails"],
        // update(cache, { data: { deleteExhibitionPair } }) {
        //     cache.modify({
        //         fields: {
        //             exhibitions(existingExhibitionPair = [], { readField }) {
        //                 return existingExhibitionPair
        //             },
        //         }
        //     });
        // },
    });

    return deleteExhibitionPairResult;
};

import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import Web3 from 'web3';
// let web3 = undefined;

declare global {
  interface Window {
    ethereum: any;
  }
}
// ---- login ------------
const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;

// TODO: move to hook
// export async function login(email: string, password: string, token = null,) {
//     return await fetchAPIGraphQl(`
//         mutation
//         {
//           login(input:{identifier:"${email}",password:"${password}"}){
//             jwt
//           }
//         }`, token, null);
// }

const providerOptions = {
  // walletconnect: {
  //   package: WalletConnectProvider, // required
  //   options: {
  //     infuraId: 'INFURA_ID', // required
  //   },
  // },
};

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  network: 'mainnet', // optional
  providerOptions, // required
});

export async function loginWithWallet() {
  const provider = await web3Modal.connect();
  const web3 = new Web3(provider);
  const [publicAddress] = (await web3.eth.getAccounts()).map((a) =>
    a.toLowerCase()
  );

  if (!publicAddress) {
    throw new Error('No address found');
  }
  return await handleWalletConnection(publicAddress);
}

export const handleWalletConnection = async (publicAddress: string) => {
  return await fetch(`${strapiUrl}/authn/find-by-address/${publicAddress}`, {
    method: 'GET',
    // requied in order to store/send auth cookie
    credentials: 'include',
  })
    .then((response) => response.json())
    .then(handleSignIn)
    .then(handleAuthenticate);
};

const handleSignIn = async ({
  publicAddress,
  nonce,
}: {
  publicAddress: string;
  nonce: string;
}) => {
  const web3 = new Web3(window.ethereum);
  try {
    const signature = await web3.eth.personal.sign(
      `I am signing my one-time nonce: ${nonce}`,
      publicAddress,
      '' // MetaMask will ignore the password argument here
    );
    return { publicAddress, signature };
  } catch (err) {
    throw new Error('You need to sign the message to be able to log in.');
  }
};
const handleAuthenticate = async ({
  publicAddress,
  signature,
}: {
  publicAddress: string;
  signature: string;
}) => {
  return await fetch(`${strapiUrl}/authn/verify-signature`, {
    method: 'POST',
    body: JSON.stringify({ publicAddress, signature }),
    headers: {
      'Content-Type': 'application/json',
    },
    // requied in order to store/send auth cookie
    credentials: 'include',
  }).then((response) => response.json());
};

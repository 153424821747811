import { useEffect, useState } from "react";
import { CONSTANT } from "../../static/constants";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import PropTypes, { InferProps } from "prop-types";

import Modal from "./Modal";
import { plaqueDisplay, plaqueSizes, rotationDisplay } from "../../static/Enums";
import InputField from "../shared/InputField";
import SelectField from "../shared/SelectField";
import PairCode from "../shared/PairCode";
import Toggle from "../shared/Toggle";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
function AddFrame(props: InferProps<typeof AddFrame.propTypes>) {
  const schema = yup.object().shape({
    name: yup.string().required(),
    pairingCode: yup.string(),
    plaque: yup.string(),
    plaqueSize: yup.string(),
    rotation:yup.string()
  });

  const [pairingCode, setPairingCode] = useState('');
  const [displayVisible, setDisplayVisible] = useState(false);
  const [error, setError] = useState('');

  // validation
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const createNewFrame = async (data: any) => {


    if (pairingCode?.length === 0 || pairingCode?.length === 6) {
      let frame = {
        name: data.name,
        pairingCode: pairingCode,
        creator: props.user,
        plaque: data.plaque,
        plaqueSize: data.plaqueSize,
        rotation: parseInt(data.rotation),
        optimizeMedia: data.optimizeMedia
      };
      reset();
      props.onCloseModal(frame);
    } else {
      setError(CONSTANT.PAIRING_CODE_ERROR)
    }
  };

  const onToggle = (enabled: boolean) => {
    setValue("optimizeMedia", enabled);
  };

  return (
    <Modal
      title={CONSTANT.ADD_NEW_FRAME}
      onCancel={() => props.onCloseModal(null)}
      formId="create-frame"
      error={props.error}
      isOpen={props.isOpen}
    >
      <form onSubmit={handleSubmit(createNewFrame)} action="#" method="POST" id="create-frame">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 ">
            <InputField
              name={'name'}
              label={CONSTANT.NAME}
              placeholder={CONSTANT.NAME}
              register={register}
              errors={errors?.name}

            />
          </div>
          <div className="col-span-12 ">
            <PairCode
              topMessage={CONSTANT.PAIRING_CODE_DESCRIPTION}
              infoMessage={CONSTANT.PAIRING_CODE_INFO}
              setError={setError} error={error}

              setPairingCode={setPairingCode} />

          </div>
          {/* plaque display setting */}
          <div className="col-span-12">
            <div className="text-indigo-700 block text-sm font-bold flex justify-between border-t pt-5">
              <div>{CONSTANT.DISPLAY_SETTINGS}</div>
              <div className="cursor-pointer" onClick={() => { setDisplayVisible(!displayVisible) }}>
                {
                  displayVisible ? <MinusIcon className="h-4 text-gray-600" /> : <PlusIcon className="h-4 text-gray-600" />
                }

              </div>
            </div>
          </div>
          {
            displayVisible &&
            <>
              <div className="col-span-6 md:col-span-4">
                <SelectField
                  name={'plaque'}
                  label={CONSTANT.DISPLAY_INFORMATION}
                  register={register}
                  errors={errors?.plaque}
                  options={plaqueDisplay}
                  defaultValue={'none'}
                />
              </div>
              <div className="col-span-6 md:col-span-4">
                <SelectField
                  name={'plaqueSize'}
                  label={CONSTANT.DISPLAY_INFORMATION_SIZE}
                  register={register}
                  errors={errors?.plaqueSize}
                  options={plaqueSizes}
                  defaultValue={'md'}

                />
              </div>
              <div className="md:col-span-4">
                <SelectField
                  name={'rotation'}
                  label={CONSTANT.ROTATION}
                  register={register}
                  errors={errors?.rotation}
                  options={rotationDisplay}
                  defaultValue={'0'}

                />
              </div>
              <div className="col-span-12">
                <div className="grid grid-cols-4">
                  <div className="col-span-2">
                    <label
                      htmlFor="optimizeMedia"
                      className="text-sm font-bold text-gray-700"
                    >
                      {CONSTANT.OPTIMIZE_LABEL}
                    </label>
                  </div>
                  <div className="col-span-2 text-right">
                    <Toggle
                      isEnabled={true}
                      onToggle={onToggle}
                    />
                  </div>
                  <div className="col-span-3 text-sm text-gray-500 mt-2">
                    {CONSTANT.OPTIMIZE_MEDIA_DESCRIPTION}
                  </div>
                </div>
                <p className="text-red-500 font-medium">{errors.name?.message}</p>
              </div>
            </>
          }
        </div>
      </form>
    </Modal>
  );
}

AddFrame.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default AddFrame;

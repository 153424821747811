import React from "react";

interface Props {
    children: any;
    className?: string;
}

const Table: React.FC<Props> = (props) => {
    const { children, className } = props;
    return (
        <table className={`min-w-full z-0 rounded-md overflow-hidden ${className}`}>
            {children}
        </table>
    );
};

export default Table
import {  ExclamationCircleIcon } from "@heroicons/react/outline";
import PropTypes, { InferProps } from "prop-types";


function SelectField({
    defaultValue,
    errors,
    register,
    name,
    label,
    isMulti = false,
    options

}: InferProps<typeof SelectField.propTypes>) {
    return (
        <div>
            <label
                htmlFor={name || ''}
                className="block text-sm font-bold text-gray-700"
            >
                {label}
            </label>
            <div className="mt-1 relative ">
                <select
                    className="form-select mt-1 block text-sm rounded-md focus:ring-primary-500 focus:border-primary-500 border-gray-300 capitalize"
                    id="display"
                    multiple={false}
                    defaultValue={defaultValue}
                    {...register(name)}
                >
                    {options.map((item: { title: string, value: string }, i: number) => {
                        return <option key={i} value={item.value || item.title}  className="capitalize">
                            {item.title}
                        </option>
                    })}

                </select>
                {errors?.message &&
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                }
            </div>
            {errors?.message &&
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errors?.message}
                </p>
            }
        </div>

    );
}

SelectField.propTypes = {
    defaultValue: PropTypes.string,
    errors: PropTypes.any,
    register: () => void {},
    name: PropTypes.string,
    label: PropTypes.string,
    isMulti: PropTypes.bool,
    options: PropTypes.any,
};

export default SelectField;


import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";

function modifiedIcons(icon: any) {
    return (React.cloneElement(icon, {
        className: 'h-7 w-7 inline-block mb-1'
    }))
}

function CustomNavLink({ name, to, icon, activeIcon }: InferProps<typeof CustomNavLink.propTypes>) {
    const location = useLocation();
    return (
        <NavLink
            key={name}
            to={to || "/"}
            className="w-full focus:text-teal-500 hover:text-teal-500 justify-center text-center pt-2 pb-1"  >
            {location.pathname === to ? modifiedIcons(activeIcon) : modifiedIcons(icon)}
            <span className="tab tab-home block text-xs text-center">{name}</span>
        </NavLink>
    );
}

CustomNavLink.propTypes = {
    name: PropTypes.string,
    to: PropTypes.string,
    icon: PropTypes.element,
    activeIcon: PropTypes.element

};

export default CustomNavLink;
import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import ModalActions from "./ModalActions";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";

type Props = {
  children: React.ReactNode;
  title?: string;
  subTitle?: string;
  onConfirm?: Function;
  onCancel: any; // TODO: prop type?
  cancelText?: string;
  confirmText?: string;
  isOpen: boolean;
  error?: string | null;
  isConfirm?: boolean;
  // All other props
  [x: string]: any;
};

const Modal: React.FC<Props> = (props) => {
  const { children, ...rest } = props;
  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={props.onCancel}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block overflow-hidden position-relative align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
              <div className="p-6 mb-12">
                {props.title && (
                  <ModalHeader
                    title={props.title}
                    subTitle={props.subTitle}
                  ></ModalHeader>
                )}
                <ModalBody>{children}</ModalBody>
                {props.error && (
                  <div className="text-red-500 text-sm text-center">
                    {props.error}
                  </div>
                )}
                <ModalActions
                  onCancel={props.onCancel}
                  onConfirm={props.onConfirm}
                  cancelText={props.cancelText}
                  confirmText={props.confirmText}
                  isConfirm={props.isConfirm}
                  formId={props.formId}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;

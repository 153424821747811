import { Artwork } from "../../components/model/Artwork";

import Web3Modal, { getProviderInfo } from "web3modal";
import Web3 from "web3";
import { providerOptions } from "../../authn/providerOptions";



export const initSelect = (data: Artwork[], selectedArtwork: any[], isDisabled: boolean = false) => {
    // selectedArtwork is an array of artwork ids
    return data?.map((item) => {
        const art = findArtwork(item?.id, selectedArtwork)
        if (isDisabled) {
            return {
                ...item,
                isSelected: art ? true : false,
                isDisabled: art && isDisabled ? true : false
            }
        } else {
            return {
                ...item,
                isSelected: art ? true : false,
            }
        }

    });
}

const findArtwork = (artworkId: string, selectedArtwork: any[]) => {
    return selectedArtwork?.find((selectedArtwork) => artworkId === selectedArtwork);
}

export const avoidDuplicates = (data: string[], item: string) => {
    if (data.indexOf(item) === -1) {
        data.push(item)
    }
    return data

}

export const removeItem = (arr: Array<string>, value: string): Array<string> => {
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}



/**
 * 
 * @returns connected Puublic address
 */


export const getWallet = async () => {
    const web3Modal = new Web3Modal({
        cacheProvider: true, // optional
        network: "mainnet", // optional
        providerOptions, // required
    });
    await web3Modal.clearCachedProvider();
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    const [publicAddress] = (await web3.eth.getAccounts()).map((a) =>
        a.toLowerCase()
    );
    if (!publicAddress) {
        throw new Error("No address found");
    }

    return { publicAddress, provider: getProviderInfo(provider).name }
};

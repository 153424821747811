/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import PropTypes, { InferProps } from 'prop-types';
import { CONSTANT } from '../../static/constants';
import Button from '../shared/Button';
import Modal from './Modal';
function DeleteModal(props: InferProps<typeof DeleteModal.propTypes>) {
	const confirmText = props.description ? CONSTANT.REMOVE : CONSTANT.DELETE;
	return (
		<Modal
			onCancel={() => props.onCloseDeleteModal(false)}
			onConfirm={() => props.onCloseDeleteModal(true)}
			isOpen={props.isOpen}
			isConfirm
			confirmText={confirmText}
		>
			<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
				<div className="sm:flex sm:items-start">
					<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
						<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
					</div>
					<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
						<Dialog.Title as="h3" className="text-lg leading-6 font font-bold text-gray-900">
							{props.description ? CONSTANT.REMOVE_RECORD : CONSTANT.DELETE_RECORD}
							{props.title?.toLowerCase()}
						</Dialog.Title>
						<div className="mt-2">
							{props.description ? (
								<p className="text-sm text-gray-500 font font-medium">{props.description}</p>
							) : (
								<p className="text-sm text-gray-500 font font-medium">
									{CONSTANT.DELETE_MESSAGE} {props.title?.toLowerCase()}?
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
			{/* <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <Button primaryBlack onClick={() => props.onCloseDeleteModal(true)}>
            {props.description
              ? CONSTANT.REMOVE.toLowerCase()
              : CONSTANT.DELETE.toLowerCase()}
          </Button>
          <span className="mr-2">
            <Button secondary onClick={() => props.onCloseDeleteModal(false)}>
              {CONSTANT.CANCEL}
            </Button>
          </span>
        </div> */}
		</Modal>
	);
}

DeleteModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCloseDeleteModal: PropTypes.func.isRequired,
	title: PropTypes.string,
	description: PropTypes.string,
};

export default DeleteModal;

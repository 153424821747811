import React, { useContext, useState } from "react";

import classNames from "classnames";
import { CONSTANT } from "../../static/constants";
import { Tooltip } from "react-tooltip";
import {
  BanIcon,
  CheckIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { useAuth } from "../../authn/authn";
import { Feature, PricingTier, pricing } from "../../static/Enums";

const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;

const paymentTimeBase = "monthly"

export default function Pricing(): JSX.Element {
  const [frequency, setFrequency] = useState<{
    label: string;
    priceSuffix: string;
  }>(pricing.frequencies[paymentTimeBase]);

  const userAuth = useAuth();
  const { uuid, email } = userAuth.getUser();

  return (
    <div className="w-full sm:w-3/4 round-[10px] m-auto">
      <main>
        {/* Pricing section */}
        <div className="mx-auto mt-16 max-w-6xl  px-6 sm:mt-32 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {CONSTANT.SELECT_YOUR_PLAN}
            </p>
            <h1 className="text-2xl mt-3 text-indigo-600">
              {CONSTANT.TRIAL_PLAN}
            </h1>
          </div>

          <div className="bg-white rounded-[16px] p-8 isolate mx-auto mt-10 grid max-w-md grid-cols-1  gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
            {pricing.tiers.map((tier: PricingTier) => {
              return (
                tier.display && (
                  <div
                    key={tier.id}
                    className={classNames(
                      "bg-white border-[#DFDFDF] border rounded-[8px] p-6 flex flex-col justify-around"
                    )}
                  >
                    <h2
                      id={tier.id}
                      className={classNames(
                        "text-[18px] leading-5 text-[#000] font-bold"
                      )}
                    >
                      {tier.name}
                    </h2>
                    <p className="mt-3 flex items-baseline gap-x-1">
                      <span className="text-4xl font-bold tracking-tight text-[#000]">
                        {tier.price[paymentTimeBase].priceLabel}
                      </span>
                      <span className="text-lg font-bold leading-5 text-[#000]">
                        {frequency.priceSuffix}
                      </span>
                    </p>
                    <p className="mt-4 text-base leading-5 font-[400] text-[#6F7481]">
                      {tier.description}
                    </p>
                    <div>
                      <ul
                        role="list"
                        className="mt-5 space-y-3 text-sm h-[200px] leading-6 text-gray-600"
                      >
                        {tier.features.map(
                          (feature: Feature, index: number) => (
                            <li
                              key={`${index}feature`}
                              className="flex gap-x-2 text-base leading-5 font-[400] text-[#6F7481]"
                            >
                              {!feature?.isNotAvailable ? (
                                <CheckIcon
                                  className="h-6 w-5 flex-none text-[#40DB87]"
                                  aria-hidden="true"
                                />
                              ) : (
                                <BanIcon
                                  className="h-6 w-5 flex-none text-[#A6A6A6]"
                                  aria-hidden="true"
                                />
                              )}
                             <span className="mt-[2px]"> {feature.description}</span>
                              {feature?.isInfoAvailable ? (
                                <InformationCircleIcon
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={
                                    CONSTANT.NON_AND_COM_USE
                                  }
                                  data-tooltip-variant="light"
                                  className="h-6 w-5 flex-none text-[#A6A6A6] cursor-pointer"
                                  aria-hidden="true"
                                />
                              ) : (
                                ""
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <div>
                      <a
                        href={`${strapiUrl}/stripe/create-checkout-session/${tier.price[paymentTimeBase].priceId}/${email}`}
                        aria-describedby={tier.id}
                        className={classNames(
                          `bg-[#171717] text-white hover:bg-[#171717]-200
                     mt-6 bottom-0 block rounded-md py-2 px-3 text-center text-base
                      font-semibold leading-5 `
                        )}
                      >
                        {CONSTANT.START_TRAIL_BUTTON}
                      </a>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </main>
      <Tooltip id="my-tooltip"
        border="1px solid black"
        style={{
            width: 200,
            opacity: 1,
            boxShadow: '0px 2px 8px 0px #0000001A',
            borderRadius: 10,
        }}
      />
    </div>
  );
}

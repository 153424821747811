import React from 'react'
import PropTypes, { InferProps } from 'prop-types'

function NotFound(props: InferProps<typeof NotFound.propTypes>) {
    return (
        <div>
            404: Not Found
        </div>
    )
}

NotFound.propTypes = {

}

export default NotFound


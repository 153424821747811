import { ArrowSmUpIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, memo, useEffect, useState } from "react";

import Dropdown from "../../components/shared/Dropdown";
import {
  DropdownOption,
  PairProps,
} from "../../components/model/DropdownOption";
import { CONSTANT } from "../../static/constants";
import ExhibitionPicker from "./exhibition-picker";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Spinner from "../../components/shared/Spinner";
import Button from "../../components/shared/Button";
import { Artwork } from "../../components/model/Artwork";
import { Collection } from "../../components/model/Collection";
import ArtworkThumb from "../../components/shared/thumbnails/ArtworkThumb";
import ArtworkCollectionThumb from "../../components/shared/thumbnails/ArtworkCollectionThumb";

interface Props {
  frames: DropdownOption[];
  disFrame: any;
  artworks: DropdownOption[];
  collections: DropdownOption[];
  pair: PairProps;
  onLoading: boolean;
  pushToFrame: (frameId: number, artworkId: number | null, artworkCollectionId: number | null) => () => void;
  onOpenDeleteModal: (id: string) => () => void;
  onUpdateExhibitionPair: (
    artworkId: number | string | null,
    frameId: string,
    collectionId: number | string | null,
    pairId: string
  ) => {};
}

const ExhibitionTableRow: React.FC<Props> = (props) => {
  const {
    frames,
    disFrame,
    pair,
    onOpenDeleteModal,
    pushToFrame,
    onUpdateExhibitionPair,
  } = props;
  const [selectedFrame, setSelectedFrame] = useState<DropdownOption>();
  const [showPicker, setShowPicker] = useState(false);
  const [defaultArtwork, setDefaultArtwork] = useState<DropdownOption>();
  const [defaultCollection, setDefaultCollection] = useState<DropdownOption>();
  const [selectedId, setSelectedId] = useState("");
  const [selectedCollectionId, setSelectedCollectionId] = useState("");
  const [selectedType, setSelectedType] = useState(0);

  const onFrameSelected = async (selected: DropdownOption) => {
    setSelectedFrame(selected);
    onUpdateExhibitionPair(
      pair?.artwork?.id || null,
      selected.id + "",
      pair?.artworkCollection?.id || null,
      pair.id + ""
    );
  };

  const onPickerClose = async (selectedArtwork = [], type: string) => {
    // return false;
    if (type === "artwork") {
      if (selectedArtwork !== null) {
        const tempArtwork = selectedArtwork[0] as Artwork;
        const tempCollection = null;
        setSelectedId(tempArtwork.id);
        setDefaultArtwork({
          id: Number(tempArtwork.id),
          title: tempArtwork.title,
          imageUrl: tempArtwork.thumbImageUrl,
        } as DropdownOption);
        setDefaultCollection({
          id: null,
          title: "",
        } as DropdownOption);
        onUpdateExhibitionPair(
          tempArtwork.id,
          selectedFrame ? selectedFrame.id + "" : pair.frame.id + "",
          tempCollection,
          pair.id + ""
        );
      }
    }
    if (type === "collection") {
      if (selectedArtwork !== null) {
        const tempArtwork = null;
        const tempCollection = selectedArtwork[0] as Collection;
        setSelectedCollectionId(tempCollection?.id);
        setDefaultArtwork({
          id: null,
          title: "",
          imageUrl: "",
        } as DropdownOption);
        setDefaultCollection({
          id: Number(tempCollection.id),
          title: tempCollection.name,
        } as DropdownOption);
        onUpdateExhibitionPair(
          tempArtwork,
          selectedFrame ? selectedFrame.id + "" : pair.frame.id + "",
          tempCollection.id,
          pair.id + ""
        );
      }
    }
    setShowPicker(false);
  };

  const defaultFrame = {
    id: pair?.frame?.id,
    title: pair?.frame?.name,
  } as DropdownOption;

  useEffect(() => {
    if (pair?.artwork) {
      setSelectedId(pair?.artwork?.id + "");
      setSelectedType(1);
    }
    if (pair?.artworkCollection) {
      setSelectedCollectionId(pair?.artworkCollection?.id + "");
      setSelectedType(2);
    }
    setDefaultArtwork({
      id: pair?.artwork?.id,
      title: pair?.artwork?.title,
      imageUrl: pair?.artwork?.thumbImageUrl,
    } as DropdownOption);
    setDefaultCollection({
      id: pair?.artworkCollection?.id,
      title: pair?.artworkCollection?.name,
    } as DropdownOption);

    setSelectedFrame({
      id: pair?.frame?.id,
      title: pair?.frame?.name,
    } as DropdownOption);
  }, [pair]);

  return (
    <tr className="h-16">
      <td className="px-4   whitespace-nowrap">
        <div className="flex items-center">
          {pair?.artworkCollection ? (
            <ArtworkCollectionThumb src={pair?.artworkCollection?.artworks} />
          ) : (
            <div className="flex-shrink-0">
              <ArtworkThumb
                artwork={pair.artwork}
              />
              {/* TODO: handle web and art w/o thumb url */}
              {/* <ArtworkThumb artwork={data} /> */}
            </div>
          )}
          {/* </div> */}
        </div>
      </td>
      <td className="px-2 whitespace-nowrap">
        <Dropdown
          width="w-full sm:w-40 font-extrabold"
          onOptionSelect={(select: DropdownOption) => onFrameSelected(select)}
          default={defaultFrame}
          disFrame={disFrame}
          options={frames}
        ></Dropdown>
      </td>
      <td className="px-2  whitespace-nowrap">
        <button
          onClick={() => {
            setShowPicker(true);
          }}
          className="font-extrabold inline-flex w-full justify-between rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        >
          {defaultCollection && defaultCollection?.id !== 0
            ? defaultCollection?.title
            : ""}
          {defaultArtwork && defaultArtwork?.id !== 0
            ? defaultArtwork?.title
            : ""}
          <ChevronDownIcon className=" ml-2 h-5 " aria-hidden="true" />
        </button>
        <ExhibitionPicker
          isOpen={showPicker}
          isMultiSelect={false}
          onCloseModal={onPickerClose}
          selectedArtworksIds={[selectedId]}
          selectedCollectionIds={[selectedCollectionId]}
          selectedType={selectedType}
        ></ExhibitionPicker>
      </td>
      <td className="sm:w-0 md:w-40 lg:w-60"></td>
      <td>
        <Button
          onClick={pushToFrame(
            selectedFrame?.id || 0,
            defaultArtwork?.id || null,
            defaultCollection?.id || null
          )}
          push
        >
          {props.onLoading ? (
            <Spinner className="w-5 h-5 text-black" />
          ) : (
            <ArrowSmUpIcon className="h-5 w-5 text-black" />
          )}
        </Button>
      </td>
      <td className="text-right">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="justify-center w-full px-4 py-2 bg-white text-sm font-medium  hover:bg-gray-50">
              <DotsVerticalIcon
                className=" h-5 text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <div className="absolute -right-8 z-50 ">
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 md:mr-8 rounded-md z-10 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="w-24 min-w-fit">
                  <Menu.Item>
                    <button
                      onClick={onOpenDeleteModal(pair.id + "")}
                      className="px-3 py-2 block bg-white text-red-600 rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
                    >
                      {CONSTANT.DELETE}
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </Menu>
      </td>
    </tr>
  );
};

export default memo(ExhibitionTableRow);

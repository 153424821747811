import { useQuery, gql } from "@apollo/client";

export type GetFrames = {
  accountId: string;
  limit: number;
  offset: number;
  sort: string;
};

export const GET_FRAMES = gql`
  query GetFrames($accountId: ID!, $limit: Int, $offset: Int, $sort: String) {
    frames(
      where: { account: $accountId }
      limit: $limit
      start: $offset
      sort: $sort
    ) {
      id
      name
      isConnected
      url
      rotation
      currentConnectionCount
      pairingCode
      plaque
      plaqueSize
      slug
      optimizeMedia
      creator {
        id
        username
      }
      artwork {
        id
        title
        thumbImageUrl
        mimeType
        mediaType
        creatorUsername
      }
      artworkCollection {
        id
        name
        artworks {
          id
          artwork {
            id
            thumbImageUrl
          }
        }
      }
      updated_at
    }
    framesCount(where: { account: $accountId })
  }
`;

export const useGetFrames = ({ accountId, limit, offset, sort='name:ASC' }: GetFrames) => {
  const { loading, error, data, fetchMore, refetch } = useQuery(GET_FRAMES, {
    variables: { accountId, limit, offset, sort },
    fetchPolicy: "cache-and-network",
  });
  return {
    frames: data?.frames,
    framesCount: data?.framesCount,
    loading,
    error,
    fetchMore,
    refetch,
  };
};

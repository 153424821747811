import { useQuery, gql, useMutation } from "@apollo/client";

interface deleteFrameInput {
  variables: {
    input: {
      where: {
        id: string;
      };
    };
  };
}

const DELETE_FRAME = gql`
  mutation DeleteFrame($input:deleteFrameInput) {
    deleteFrame(input:$input) {
      frame {
        id
      }
    }
  }
`;

export const useDeleteFrame = (): ((
  deleteFrame: deleteFrameInput
) => any) => {
  const [deleteFrameResult] = useMutation(DELETE_FRAME, {
    update(cache, { data: { deleteFrame } }) {
      cache.modify({
        fields: {
          frames(existingFrames = [], { readField }) {
            return existingFrames.filter(
              (frame: any) => deleteFrame.frame.id !== readField('id', frame)
            )
          },
        }
      });
    },
  });

  return deleteFrameResult;
};

import { useQuery, gql } from "@apollo/client";

export type GetCollections = {
  accountId: string;
  limit?: number;
  offset?: number;
  sort?: string;
  id?: string;
  search?: string;
};

export const GET_COLLECTIONS = gql`
  query GetCollections(
    $accountId: ID!
    $limit: Int
    $offset: Int
    $sort: String
    $id: String
    $search: String
  ) {
    collections(
      where: {
        account: $accountId,
        id: $id,
        _or: [
          { name_contains: $search }
        ]
      }
      limit: $limit
      start: $offset
      sort: $sort
    ) {
      id
      name
      interval
      transition
      playFullVideos
      artworks {
        id
        position
        artwork {
          creatorAddress
          mediaType
          thumbImageUrl
          display
          id
          title
          creatorUsername
          source
          mimeType
          fileSize
        }
      }
      creator {
        id
        username
      }
      frames {
        id
      }
      updated_at
    }

    frames(where: { account: $accountId }) {
      id
      name
      isConnected
      artwork {
        id
        thumbImageUrl
      }
      artworkCollection {
        id
        artworks {
          artwork {
            id
            thumbImageUrl
          }
        }
      }
      updated_at
    }
    collectionsCount(
      where: {
        account: $accountId
      }
    )
  }
`;

export const useGetCollections = ({
  accountId,
  limit,
  offset,
  sort,
  id,
  search,
}: GetCollections) => {
  const { loading, error, data, fetchMore } = useQuery(GET_COLLECTIONS, {
    variables: { accountId, limit, offset, sort, id, search },
    fetchPolicy: "cache-and-network",
  });
  // console.log(error);
  return {
    collections: data?.collections,
    frames: data?.frames,
    collectionsCount: data?.collectionsCount,
    fetchMore,
    loading,
    error,
  };
};

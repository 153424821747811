import React from "react";

interface Props {
  children: any;
  // isDisabled?: boolean;
}

// ${isDisabled ? "pointer-events-none opacity-50" : ""}

const TableRow: React.FC<Props> = ({
  children,
  // , isDisabled = false
}) => {
  return <tr className={`w-full h-16 bg-white `}>{children}</tr>;
};

export default TableRow;

import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { RefreshIcon } from "@heroicons/react/solid";

import { CONSTANT } from "../../static/constants";
import { Frame } from "../model/Frame";

import Modal from "./Modal";
import Toggle from "../shared/Toggle";
import Button from "../shared/Button";
import DebugMode from "../shared/DebugMode";
import { plaqueDisplay, plaqueSizes, rotationDisplay } from "../../static/Enums";
import FrameDropDown from "../shared/FrameDropDown";
import InputField from "../shared/InputField";

const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;

interface Props {
  frame: Frame | undefined;
  isOpen: boolean;
  onCloseModal: any;
}

function EditFrame(props: Props) {
  const schema = yup.object().shape({
    name: yup.string().required(),
  });
  const [plaque, setPlaque] = useState(props.frame?.plaque);
  const [plaqueSize, setPlaqueSize] = useState(props.frame?.plaqueSize);
  const [rotation, setRotation] = useState(0);
  // validation
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.frame?.name,
      plaque: props.frame?.plaque,
      plaqueSize: props.frame?.plaqueSize,
      rotation: props.frame?.rotation,
      optimizeMedia: props.frame?.optimizeMedia,
    },
  });

  useEffect(() => {
    reset({
      name: props.frame?.name,
      // plaque: props.frame?.plaque,
      // rotation: props.frame?.rotation,
    });
    setPlaque(props.frame?.plaque);
    setPlaqueSize(props.frame?.plaqueSize);
    setRotation(props.frame?.rotation || 0);
  }, [props.frame?.name, props.isOpen, reset]);

  const updateFrame = async (data: any) => {
    let frame = {
      name: data.name,
      plaque: plaque,
      plaqueSize: plaqueSize,
      rotation: Number(rotation),
      optimizeMedia: data.optimizeMedia,
    };
    props.onCloseModal(frame);
  };

  const onToggle = (enabled: boolean) => {
    setValue("optimizeMedia", enabled);
  };

  const onReload = async () => {
    await fetch(
      `${strapiUrl}/frames/${props?.frame?.id}/reload`,
      {
        method: "POST",
        credentials: "include",
      }
    );
  };

  const onPlaqueSelect = (option: any) => {
    setPlaque(option);
  };

  const onPlaqueSizeSelect = (option: any) => {
    setPlaqueSize(option);
  };

  const onRotationSelect = (option: any) => {
    setRotation(option);
  };

  return (
    <Modal
      title={CONSTANT.EDIT_FRAME}
      onCancel={() => props.onCloseModal(null)}
      formId="edit-frame"
      isOpen={props.isOpen}
    >
      <form
        onSubmit={handleSubmit(updateFrame)}
        action="#"
        method="POST"
        id="edit-frame"
      >
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 ">

            <InputField
              name={'name'}
              label={CONSTANT.NAME}
              placeholder={CONSTANT.NAME}
              register={register}
              errors={errors?.name}

            />

          </div>
          <div className="col-span-12">
            <span className="text-indigo-700 mt-2 block text-sm font-bold">
              {CONSTANT.DISPLAY_SETTINGS}
            </span>
          </div>
          <div className="md:col-span-4 col-span-6">
            <label
              htmlFor="plaque"
              className="block text-sm font-bold text-gray-700 mb-1"
            >
              {CONSTANT.DISPLAY_INFORMATION}
            </label>
            <FrameDropDown
              width="w-[140px]"
              default={props?.frame?.plaque}
              options={plaqueDisplay}
              type="plaque"
              onSelect={(option: number) => onPlaqueSelect(option)}
            ></FrameDropDown>
          </div>
          <div className="md:col-span-4 col-span-6">
            <label
              htmlFor="plaqueSize"
              className="block text-sm font-bold text-gray-700 mb-1"
            >
              {CONSTANT.DISPLAY_INFORMATION_SIZE}
            </label>
            <FrameDropDown
              width="w-[140px]"
              default={props?.frame?.plaqueSize || 'md'}
              options={plaqueSizes}
              type="plaqueSize"
              onSelect={(option: number) => onPlaqueSizeSelect(option)}
            ></FrameDropDown>
          </div>
          <div className="md:col-span-4 col-span-12">
            <label
              htmlFor="rotation"
              className="block text-sm font-bold text-gray-700 mb-1"
            >
              {CONSTANT.ROTATION}
            </label>
            <FrameDropDown
              width="w-[72px]"
              default={props?.frame?.rotation}
              type="rotation"
              options={rotationDisplay}
              onSelect={(option: number) => onRotationSelect(option)}
            ></FrameDropDown>
          </div>
          <div className="col-span-12">
            <div className="grid grid-cols-4">
              <div className="col-span-3">
                <label
                  htmlFor="optimizeMedia"
                  className="text-sm font-bold text-gray-700"
                >
                  {CONSTANT.OPTIMIZE_LABEL}
                </label>
                <div className="text-sm text-gray-500">
                    Use high-quality compression and limit maximum resolution of streamed media.
                    Recommended for best performance in most cases.
                </div>
              </div>
              <div className="col-span-1 text-right">
                <Toggle
                  isEnabled={props.frame?.optimizeMedia || false}
                  onToggle={onToggle}
                />
              </div>
            </div>
            <p className="text-red-500 font-medium">{errors.name?.message}</p>
          </div>
          <DebugMode>
            <div className="col-span-12">
              <hr />
              <div className="grid grid-cols-4 mt-4">
                <div className="col-span-2">
                  <div className="text-sm text-gray-500 mt-2">
                    Force reload this frame
                  </div>
                </div>
                <div className="col-span-2 text-right">
                  <Button secondary onClick={onReload} className="px-1 py-1">
                    <RefreshIcon className="h-5 w-5 text-gray-400 cursor-pointer" />
                  </Button>
                </div>
              </div>
              <p className="text-red-500 font-medium">{errors.name?.message}</p>
            </div>
          </DebugMode>
        </div>
      </form>
    </Modal>
  );
}

export default EditFrame;

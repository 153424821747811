import { useQuery, gql } from "@apollo/client";

export const GET_EXHIBITION_DETAILS = gql`
  query GetExhibitionDetails(
    $exhibitionId: Int
    $limit: Int
    $offset: Int
    $order: String
  ) {
    exhibitions(
      where: { id: $exhibitionId }
      sort: $order
    ) {
      id
      name
      pairs (
        limit: $limit
        start: $offset
        ){
        id
        position
        frame {
          id
          url
          name
        }
        artwork {
          id
          title
          thumbImageUrl
          mimeType
        }
        artworkCollection {
          id
          name
          artworks {
            id
            position
            artwork {
              id
              title
              creatorUsername
              thumbImageUrl
            }
          }
        }
      }
    }
  
  }
`;

export const useGetExhibitionPairs = (
    exhibitionId: any,
    limit?: number,
    offset?: number,
    order?: string
) => {
    const { fetchMore } = useQuery(GET_EXHIBITION_DETAILS, {
        variables: { exhibitionId, limit, offset, order },
        fetchPolicy: "cache-and-network",
    });
    return {
        fetchMore,
    };
};

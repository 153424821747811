import { useQuery, gql } from "@apollo/client";

export const GET_ME = gql`
  query GetMe {
    me {
      id
      email
      username
      uuid
      role {
        id
        name
        description
      }
      wallets {
        id
        publicAddress
      }
      account {
        id
        subscription {
          id
          stripeSubscriptionId
          plan{
            id
            name
            stripeProductId
          }
          stripeCurrentPeriodEnd
          status
          stripeCancelAtPeriodEnd
        }
        account_options {
          id
          limit
          consumed
          option {
            id
            name
          }
          plan_option{
            limit
            option{
              id
              name
            }
          }
        }
      }
      isKioskModeOn

      
    }
  }
`;

export const useGetMe = () => {
  const { loading, error, data, refetch } = useQuery(GET_ME, {
    fetchPolicy: "cache-and-network",
  });
  return {
    loading,
    error,
    user: data?.me,
    refetch,
  };
};

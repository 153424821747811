import logo from "../../assets/logo-black.svg";
import Button from "../../components/shared/Button";

function Maintenance() {
  return (
    <>
      {/*
		  This example requires updating your template:

		  ```
		  <html class="h-full bg-gray-50">
		  <body class="h-full">
		  ```
	    */}
      <div className="h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
          <img className="block h-6 m-auto" src={logo} alt="Zeroframe" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Scheduled Maintenance
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow text-center sm:rounded-lg sm:px-10">
            Your Zeroframe dashboard will be back shortly!
            <div className="mt-6">
              <Button
                primary
                onClick={() => {
                  window.location.pathname = "/";
                }}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Maintenance.propTypes = {};

export default Maintenance;

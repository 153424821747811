import { useMediaQuery } from 'react-responsive';
import { theme } from '../tailwind.config.js'; // Your tailwind config

const bps = theme.screens;

type BreakpointKey = keyof typeof bps;

export const breakpoints = theme.screens

export function useBreakpoint<K extends BreakpointKey>(breakpointKey: K) {
  const bool = useMediaQuery({
    query: `(min-width: ${bps[breakpointKey]})`,
  });
  const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
  type Key = `is${Capitalize<K>}`;
  return {
    [`is${capitalizedKey}`]: bool,
  } as Record<Key, boolean>;
}
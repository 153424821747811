import { gql, useMutation } from "@apollo/client";
import { GET_ME } from "./useGetMe";
interface updateUserInput {
  variables: {
    input: {
      data: {
        email: string;
        username: string;
      };
      where: {
        id: string;
      };
    };
  };
}

export const UPDATE_USER = gql`
  mutation UpdateUser($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        email
        username
      }
    }
  }
`;

export const useUpdateUser = (): ((
  updateUser: updateUserInput
) => any) => {
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [GET_ME],
    // update(cache, { data: { updateUser } }) {
    //   cache.modify({
    //     fields: {
    //       accounts(existingEmails = []) {
    //         return updateUser.user;
    //         // const newArtworkRef = cache.writeFragment({
    //         //   data: updateArtwork,
    //         //   fragment: gql`
    //         //     fragment UpdateArtwork on Artwork {
    //         //       id
    //         //       display
    //         //     }
    //         //   `,
    //         // });
    //         // return [...existingArtworks, newArtworkRef];
    //       },
    //     },
    //   });
    // },
  });

  return updateUser;
};

import { Fragment, useEffect, useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

/* This example requires Tailwind CSS v2.0+ */
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { isAddress, shortenAddress, shortenUsername } from "../../util/strings";
import { getAccountOption } from "../../util/account";
import { useAuth } from "../../authn/authn";
import logo from "../../assets/logo.svg";

import FrameSelector from "./frame-selector";

const userNavigation = [
  { name: "Account", to: "/account" },
  // { name: "Settings", to: "#" },
  { name: "Sign out", to: "/logout" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function TopNav(props: InferProps<typeof TopNav.propTypes>) {
  const location = useLocation();
  const authn = useAuth()
  const user = authn.getUser()

  const showExhibitions = getAccountOption(user?.account, "exhibitions")

  const navigation = [
    { name: "Artworks", to: "/", show: true },
    { name: "Collections", to: "collections", show: true },
    { name: "Exhibitions", to: "exhibitions", show: showExhibitions },
    { name: "Frames", to: "frames", show: true },
  ];

  return (
    <Disclosure as="nav" className="bg-gray-800 relative top-0 z-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-20">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/">
                    <img
                      className="block hidden md:block lg:hidden h-6 w-auto"
                      src={logo}
                      alt="Zeroframe"
                    />
                  </Link>
                  <Link to="/">
                    <img
                      className="hidden lg:block h-6 w-auto"
                      src={logo}
                      alt="Zeroframe"
                    />
                  </Link>
                </div>
                <div className="hidden md:ml-5 lg:ml-24 md:flex md:items-center md:space-x-4">
                  {navigation.map((item) => {
                    return item.show ? (
                      <NavLink
                        key={item.name}
                        to={item.to}
                        className={({ isActive }) => {
                          return classNames(
                            isActive
                              ? "bg-gray-900 text-white "
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "md:px-2 lg:px-3 py-2 rounded-md font-semibold text-lg"
                          );
                        }}
                        //   aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ) : null;
                  })}
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {/* <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-bold rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                  >
                    <PlusSmIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span>New Job</span>
                  </button> */}
                  {!location.pathname.includes("exhibitions") ? (
                    <FrameSelector />
                  ) : (
                    <div className="bg-gray-900 md:px-2 lg:px-3 py-2 rounded-md text-sm font-normal w-52 text-center ">
                      <p className="text-[#9DA5B7] ">
                        Pushing an exhibition will update all frames involved
                      </p>
                    </div>
                  )}
                </div>
                <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                  {/* <button
                    type="button"
                    className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="h-9 bg-secondary text-bc text-center flex px-4 py-2 text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 ">
                        <span className="sr-only">Open user menu</span>
                        {/* <img
                          className="h-8 w-8 rounded-full"
                          src={user.imageUrl}
                          alt=""
                        /> */}
                        {isAddress(authn?.getUser()?.username) ? shortenAddress(authn?.getUser()?.username) : shortenUsername(authn?.getUser()?.username)}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.to}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 text-right"
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            {/* <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.to}
                  //   className={classNames(
                  //     item.current
                  //       ? "bg-gray-900 text-white"
                  //       : "text-gray-300 hover:bg-gray-700 hover:text-white",
                  //     "block px-3 py-2 rounded-md text-base font-bold"
                  //   )}
                  //   aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div> */}
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5 sm:px-6">
                <div className="flex-shrink-0">
                  {/* <img
                    className="h-10 w-10 rounded-full"
                    src={user.imageUrl}
                    alt=""
                  /> */}
                </div>
                <div className="">
                  <div className="text-base font-bold text-white">
                  {isAddress(authn?.getUser()?.username) ? shortenAddress(authn?.getUser()?.username) : authn?.getUser()?.username}
                  </div>
                  <div className="text-sm font-bold text-gray-400">
                    {authn?.getUser()?.email}
                  </div>
                </div>
                {/* <button
                  type="button"
                  className="ml-auto flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
              </div>
              <div className="mt-3 px-2 space-y-1 sm:px-3">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.to}
                    className="block px-3 py-2 rounded-md text-base font-semibold text-gray-400 hover:text-white hover:bg-gray-700"
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

TopNav.propTypes = {};

export default TopNav;

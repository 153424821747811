import { useQuery, gql } from "@apollo/client";

export const GET_EXHIBITION_DETAILS = gql`
  query GetExhibitionDetails(
    $accountId: ID!
    $exhibitionId: Int
    $limit: Int
    $offset: Int
    $order: String
  ) {
    exhibitions(
      where: { id: $exhibitionId }
      sort: $order
    ) {
      id
      name
      pairs (
        limit: $limit
        start: $offset
        ){
        id
        position
        frame {
          id
          url
          name
        }
        artwork {
          id
          title
          thumbImageUrl
          mimeType
        }
        artworkCollection {
          id
          name
          artworks {
            id
            position
            artwork {
              id
              title
              creatorUsername
              thumbImageUrl
            }
          }
        }
      }
    }
    collections(where: { account: $accountId }, sort: "name:asc", limit: 100) {
      id
      title: name
    }
    frames(where: { account: $accountId }, sort: "name:asc") {
      id
      title: name
    }
    artworks(where: { account: $accountId },, sort: "title:asc") {
      id
      title
      type: thumbImageUrl
    }
    exhibitionPairCount(
      where: {
        exhibition: $exhibitionId
      }
    )
  
  }
`;

export const useGetExhibitionDetails = (
  accountId: any,
  exhibitionId: any,
  limit?: number,
  offset?: number,
  order?: string
) => {
  const { loading, error, data, fetchMore } = useQuery(GET_EXHIBITION_DETAILS, {
    variables: { accountId, exhibitionId, limit, offset, order },
    fetchPolicy: "cache-and-network",
  });
  return {
    exhibition: data?.exhibitions[0],
    artworks: data?.artworks,
    frames: data?.frames,
    collections: data?.collections,
    pairCount: data?.exhibitionPairCount,
    fetchMore,
    loading,
    error,
    // detailsCount:data?.exhibitionDetailsCount
  };
};

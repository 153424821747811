import React, { useContext } from "react";
import { Link, Outlet } from "react-router-dom";

import TopNav from "../header/top-nav";
import BottomNav from "../mobile/bottom-nav";
import TopBar from "../shared/Topbar";
import { generalToastContext } from "../../contexts/general-toast-context-provider";

function Layout() {
  const { selectedPlan } = useContext(generalToastContext);

  return (
    <div className="bg-gray-100">
      <div className="fixed top-0 w-full z-50">
        {selectedPlan.subsCancelAtEnd && <TopBar daysLeft={selectedPlan.daysLeft} isCancelTopbarType={true} />}
        {!selectedPlan.subsCancelAtEnd && selectedPlan.subscriptionStatus === "trialing" && <TopBar daysLeft={selectedPlan.daysLeft} />}
        {/* {selectedPlan.subsCancelAtEnd ? (
          <TopBar daysLeft={selectedPlan.daysLeft} isCancelTopbarType={true} />
        ) : (
          selectedPlan.subscriptionStatus === "trialing" && (
            <TopBar daysLeft={selectedPlan.daysLeft} />
          )
        )} */}
        <TopNav  />
      </div>
      <div className="content z-0 mt-[20vh]">
        <Outlet />
      </div>
      <BottomNav />
    </div>
  );
}

Layout.propTypes = {};

export default Layout;

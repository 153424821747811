import { gql, useMutation } from "@apollo/client";
import { GET_EXHIBITION } from "../exhibitions/useGetExhibitions";
// import {GET_FRAMES } from "./useGetFrames";

interface pushInput {
  variables: {
    id: number | string;
  };
}

export const PUSH_EXHIBITION = gql`
  mutation PushExhibition($id: ID!) {
    pushExhibition(id: $id) {
      name
    }
  }
`;

export const usePushExhibition = (): ((
  pushExhibition: pushInput
) => any) => {
  const [pushExhibition] = useMutation(PUSH_EXHIBITION, {
    refetchQueries: [GET_EXHIBITION, "GetExhibitions"],
  });
  return pushExhibition;
};

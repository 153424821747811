export const getDateDifferenceIndays = (periodEndDate: Date) => {
  const todayDate = new Date();
  periodEndDate.setHours(0, 0, 0, 0);
  todayDate.setHours(0, 0, 0, 0);
  const difference = periodEndDate.getTime() - todayDate.getTime();
  return Math.round(difference / (1000 * 60 * 60 * 24));
};

export const getFormattedDate = (date: Date | string) => {
    // format date like Monday, January 1 2021
    const formattedDate = new Date(date);
    const options: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return formattedDate.toLocaleDateString('en-US', options);
}

import React from 'react';
import { toast } from 'react-toastify';
import { InferProps } from 'prop-types';

import analytics from '../../services/analytics';

import { useGetCollections } from '../../hooks/collections/useGetCollections';
import { useUpdateCollection } from '../../hooks/collections/useUpdateCollection';
import { useCreateCollection } from '../../hooks/collections/useCreateCollection';
import { useDeleteCollection } from '../../hooks/collections/useDeleteCollection';
import EditCollectionModal from '../../components/modal/EditCollectionModal';

import { useEffect, useState } from 'react';
import { ArrowCircleRightIcon } from '@heroicons/react/outline';
import Loader from '../../components/shared/loader';
import TableTitleBar from '../../components/tables/table-title-bar';
import Button from '../../components/shared/Button';

import { CONSTANT, limit } from '../../static/constants';
import { SelectorIcon } from '@heroicons/react/outline';
import AddEditCollection from '../../components/modal/AddEditCollection';
import { Collection, CreateCollection } from '../../components/model/Collection';
import CollectionDetails from './collection-details';
import DeleteModal from '../../components/modal/DeleteModal';
import { ChevronRightIcon, ArrowSmUpIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import { ViewGridIcon as ViewGridIconSolid, ViewListIcon } from '@heroicons/react/solid';
import TableRow from '../../components/tables/table-row';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Sort } from '../../components/model/Artwork';
import { useAuth } from '../../authn/authn';
import { selectedFrameIdVar } from '../../states/topNavFrames';
import { useReactiveVar } from '@apollo/client';
import { useUpdateFrame } from '../../hooks/frames/useUpdateFrame';
import CollectionsHead from './collections-head';
import TableBanner from '../../components/shared/TableBanner';
import MainContainer from '../../components/shared/MainContainer';
import TableContainer from '../../components/shared/TableContainer';
import TableHead from '../../components/shared/TableHead';
import ArtworkCollectionThumb from '../../components/shared/thumbnails/ArtworkCollectionThumb';
import Table from '../../components/shared/Table';
import TableEmptyState from '../../components/tables/table-empty-state';
import { useNavigate } from 'react-router-dom';
const sortFields = { key: 'name', sort: 'ASC' };

function Collections(props: InferProps<typeof Collections.propTypes>) {
	const navigation = useNavigate();
	const updateCollection = useUpdateCollection();
	const createCollection = useCreateCollection();
	const deleteCollection = useDeleteCollection();
	const updateFrame = useUpdateFrame();

	const authn = useAuth();

	const selectedFrameId = useReactiveVar(selectedFrameIdVar);
	const [order, setOrder] = useState('name:ASC');

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [collectionEditData, setCollectionEditData] = useState<Collection>();
	const [showModal, setShowModal] = useState(false);
	const [collectionDetailsClicked, setCollectionDetailsClicked] = useState(false);
	const [collectionId, setCollectionId] = useState('0');
	const [loader, setLoader] = useState(false);
	const [selectedCollection, setSelectedCollection] = useState<Collection>();
	const [selectedInput, setSelectedInput] = useState('0');
	const [sortedColumn, setSortedColumn] = useState<Sort>();

	const { loading, error, collections, fetchMore, collectionsCount } = useGetCollections({
		accountId: authn?.getAccountId(),
		limit: limit,
		offset: 0,
		sort: order,
	});
	const [allCollections, setAllCollections] = useState<Collection[]>();
	useEffect(() => {
		setAllCollections(collections);
	}, [collections]);

	const getCollectionDetails = async (collection: any) => {
		// setCollectionId(collection.id);
		// setSelectedCollection(collection);
		// setCollectionDetailsClicked(true);
		navigation('/collections/' + collection.id);
	};

	const onOpenDeleteModal = (id: string) => {
		setShowDeleteModal(true);
		setCollectionId(id);
	};

	const onCreateClick = () => {
		setCollectionEditData(undefined);
		setShowModal(true);
	};

	const editCollectionInterval = async (event: any) => {
		event.preventDefault();
		setLoader(true);
		const collectionId = event.target.interval.id;
		const interval = event.target.interval.value;
		await updateCollection({
			variables: {
				input: {
					data: {
						interval: Number(interval),
					},
					where: {
						id: collectionId,
					},
				},
			},
		});
		analytics.track('collection: updated', {
			id: collectionId
		});
		setLoader(false);
		setSelectedInput('0');
	};

	const onCloseDeleteModal = async (state: boolean) => {
		if (state) {
			setLoader(true);
			const result = await deleteCollection({
				variables: {
					input: {
						where: {
							id: collectionId + '',
						},
					},
				},
			});
			analytics.track('collection: deleted', {
				id: collectionId,
			});
		}
		setLoader(false);
		setShowDeleteModal(false);
	};

	const closeCollectionDetails = () => {
		setCollectionDetailsClicked(false);
	};

	const onSortColumn = async (column: string) => {
		if (sortFields.sort === 'ASC') {
			setOrder(`${column}:DESC`);
			setSortedColumn({ column: sortFields.key, type: sortFields.sort });
			return (sortFields.sort = 'DESC');
		} else {
			setOrder(`${column}:ASC`);
			setSortedColumn({ column: sortFields.key, type: sortFields.sort });
			return (sortFields.sort = 'ASC');
		}
	};

	const fetchMoreData = async () => {
		setLoader(true);

		const { data } = await fetchMore({
			variables: {
				offset: allCollections?.length,
				limit: limit,
			},
		});

		setAllCollections((collections) => [...collections!, ...data.collections]);
		setLoader(false);
	};

	// const onCloseModal = async (collection: CreateCollection) => {
	//   setLoader(true);
	//   if (collection) {
	//     await createCollection({
	//       variables: { input: { data: collection } },
	//     });
	//   }
	//   setShowModal(false);
	//   setLoader(false);
	// };

	const onCloseAddEditModal = async (collection: Collection) => {
		setShowModal(false);
		if (collection) {
			setLoader(true);
			if (collectionEditData) {
				await updateCollection({
					variables: {
						input: {
							// data: { ...collection },
							data: {
								name: collection?.name,
								interval: Number(collection?.interval),
								transition: collection?.transition,
								playFullVideos: collection?.playFullVideos,
							},
							where: {
								id: Number(collection?.id),
							},
						},
					},
				});
                analytics.track('collection: updated', {
                    id: collectionId
                });
				toast.success('Collection updated', {
					position: toast.POSITION.TOP_RIGHT,
				});
			} else {
				const res = await createCollection({
					variables: { input: { data: collection } },
				});
                analytics.track('collection: created', {
                    id: res?.data?.createCollection?.collection?.id
                });
				toast.success('Collection created', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
			setLoader(false);
		}
	};

	const onEditHandler = (data: any) => () => {
		setCollectionEditData(data);
		setShowModal(true);
	};

	const classNames = (...classes: any) => {
		return classes.filter(Boolean).join('');
	};

	const calcTotal = (collection: Collection) => {
		const totalSeconds = collection.artworks.length * collection.interval;
		const hours = Math.floor(totalSeconds / 60 / 60);
		const minutes = Math.floor(totalSeconds / 60) - hours * 60;
		const seconds = totalSeconds % 60;
		if (hours > 0) {
			return `${hours}h ${minutes}m ${seconds}s`;
		} else if (minutes > 0) {
			return `${minutes}m ${seconds}s`;
		}
		return `${seconds}s`;
	};

	const pushCollectionToFrame = async (collectionId: string) => {
		if (selectedFrameId) {
			await updateFrame({
				variables: {
					input: {
						data: {
							artwork: null,
							artworkCollection: collectionId,
						},
						where: {
							id: selectedFrameId,
						},
					},
				},
			});
            analytics.track('collection: pushed', {
                id: collectionId,
                frameId: selectedFrameId
            });
            analytics.add('push_count')
		}
	};

	return (
		<MainContainer>
			{!collectionDetailsClicked ? (
				<div className="sm:text-center lg:text-left">
					<TableTitleBar>
						<TableBanner
							title={CONSTANT.COLLECTIONS}
							count={collectionsCount}
							addHandler={onCreateClick}
							buttonTitle={CONSTANT.NEW_COLLECTION}
							buttonTitleSmallSize={CONSTANT.CREATE}
						/>
					</TableTitleBar>
					{<Loader isLoading={loading} />}
					<TableContainer>
						<InfiniteScroll
							dataLength={Number(allCollections?.length) || 0}
							next={fetchMoreData}
							hasMore={(Number(collectionsCount) || 0) > (Number(allCollections?.length) || 0)}
							loader={<h4></h4>}
						>
							{!loading && (!allCollections || allCollections?.length === 0) ? (
								<TableEmptyState
									title="There are no collections yet."
									subtitle="Collections allow you to create a list of artworks that rotate with a set interval time."
								/>
							) : (
								<Table>
									<TableHead>
										<tr>
											<th></th>
											<th scope="col" className="px-6 py-3 font-medium">
												<button
													onClick={() => onSortColumn('name')}
													className={classNames(
														sortedColumn?.column === 'name'
															? 'text-black '
															: ' text-grey-darkest ',
														' bg-grey-light hover:bg-grey  hover:text-black rounded inline-flex items-start uppercase '
													)}
												>
													<span className="font font-semibold">{CONSTANT.NAME}</span>
													<SelectorIcon className="h-4 w-4 mt-[2px] inline" />
												</button>
											</th>
											<th className="pr-6 pl-6"></th>
											<th className="sm:w-0 md:w-36 lg:w-48"></th>
											<th scope="col" className="pr-6 pl-6 py-3  font-semibold">
												#{CONSTANT.ARTWORKS}
											</th>
											<th scope="col" className="px-6 py-3 font-semibold">
												{CONSTANT.TIME}
											</th>
											<th scope="col" className="px-6 py-3 font-semibold">
												{CONSTANT.TOTAL}
											</th>
											<th scope="col" className="px-6 py-3 font-semibold"></th>
											<th className="w-8 px-6 py-3 font-semibold"></th>
										</tr>
									</TableHead>
									<tbody className="bg-white divide-y divide-gray-200">
										{allCollections?.map((collection) => (
											<TableRow key={collection.id}>
												<td className="px-2 py-0">
													<ArtworkCollectionThumb src={collection?.artworks} />
												</td>
												<td className="text-sm pl-3 text-center sm:text-left md:px-6 text-primary font-semibold  md:w-15">
													{collection?.name}
												</td>
												<td className="pr-6 md:pl-6">
													<a onClick={() => getCollectionDetails(collection)}>
														<div className="cursor-pointer">
															<ChevronRightIcon className="h-6 bg-gray-100 hover:bg-gray-300 text-gray-600 hover:text-gray-900 rounded-full p-1"></ChevronRightIcon>
														</div>
													</a>
												</td>
												<td className="sm:w-0 hidden md:table-cell md:w-36 lg:w-48"></td>
												<td className="pr-6 hidden md:table-cell pl-6 text-sm text-table-cell-color">
													{collection?.artworks?.length}
												</td>

												<td className="px-6 w-28 hidden md:table-cell whitespace-nowrap ">
													{selectedInput === collection?.id ? (
														<form
															onSubmit={editCollectionInterval}
															action="#"
															method="POST"
															className="blur:bg-black"
														>
															<div className="flex py-0 content-start w-28">
																<input
																	id={collection.id + ''}
																	name="interval"
																	autoFocus
																	type="number"
																	placeholder="interval"
																	defaultValue={collection?.interval}
																	required
																	className=" bg-gray-200 text-gray-700 w-28 border-none rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
																/>
																<button
																	type="submit"
																	className="relative -left-10 top-0 bg-white flex-shrink-0 border-transparent border-none text-primary-500 hover:text-primary-800 text-sm py-1 px-2 rounded"
																>
																	<ArrowCircleRightIcon className="h-6 text-gray-400 hover:text-black"></ArrowCircleRightIcon>
																</button>
															</div>
														</form>
													) : (
														<div
															onClick={() => setSelectedInput(collection.id)}
															className={
																' flex content-start text-sm text-primary cursor-pointer py-2 px-4 rounded border border-bc hover:bg-gray-100 w-28'
															}
														>
															{collection?.interval}
														</div>
													)}
												</td>
												<td className=" hidden md:table-cell px-6 text-sm text-table-cell-color">
													{calcTotal(collection)}
												</td>
												<td>
													<button
														onClick={() => pushCollectionToFrame(collection.id)}
														type="button"
														className="justify-center  rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white
                                                     text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2
                                                      focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
													>
														<ArrowSmUpIcon className="h-5"></ArrowSmUpIcon>
													</button>
												</td>
												<td className='text-right py-3'>
													<Menu as="div" className="inline-block text-right bg-green-500">
														<div>
															<Menu.Button className="justify-center w-full px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50">
																<DotsVerticalIcon
																	className=" h-5 text-gray-500"
																	aria-hidden="true"
																/>
															</Menu.Button>
														</div>
														<div className="absolute right-8 z-50 ">
															<Transition
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items className=" rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																	<div className="w-24 min-w-fit text-left ">
																		<Menu.Item>
																			<button
																				onClick={onEditHandler(collection)}
																				className="px-3 py-2 block text-left bg-white rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
																			>
																				{CONSTANT.EDIT}
																			</button>
																		</Menu.Item>
																		<Menu.Item>
																			<button
																				onClick={() =>
																					onOpenDeleteModal(collection.id)
																				}
																				className="px-3 text-left text-red-600 py-2 block bg-white rounded-md hover:bg-table-header-bg cursor-pointer text-sm w-full "
																			>
																				{CONSTANT.DELETE}
																			</button>
																		</Menu.Item>
																	</div>
																</Menu.Items>
															</Transition>
														</div>
													</Menu>
												</td>
											</TableRow>
										))}
									</tbody>
								</Table>
							)}
						</InfiniteScroll>
					</TableContainer>
					<AddEditCollection
						isOpen={showModal}
						onCloseDialog={onCloseAddEditModal}
						user={authn.getUser().id}
						collection={collectionEditData}
					></AddEditCollection>
					{/* <EditCollectionModal
            onCloseEditDialog={onCloseEditDialog}
            user={authn?.getUser()?.id}
            isOpen={showEditModal}
          /> */}
					<DeleteModal
						title={CONSTANT.COLLECTION}
						isOpen={showDeleteModal}
						onCloseDeleteModal={(value: boolean) => onCloseDeleteModal(value)}
					></DeleteModal>
				</div>
			) : (
				<div>
					{/* <CollectionDetails
            closeCollectionDetails={closeCollectionDetails}
            selectedCollection={selectedCollection}
            user={authn.getUser().id}
          ></CollectionDetails> */}
				</div>
			)}
		</MainContainer>
	);
}

Collections.propTypes = {};

export default Collections;

import {gql, useMutation } from "@apollo/client";
import { GET_COLLECTION_DETAILS } from "./useGetCollectionDetails";

interface setCollectionArtworkInput {
  variables: {
    artworkIds?: number[];
    collectionId?: number;
  };
}

export const CREATE_COLLECTION_ARTOWRK = gql`
  mutation SetCollectionArtowrks($collectionId: ID, $artworkIds: [ID]) {
    setCollectionArtworks(
      collectionId: $collectionId
      artworkIds: $artworkIds
    ) {
      name
      interval
      artworks {
        id
        position
        artwork {
          creatorAddress
          mediaType
          thumbImageUrl
          display
          id
          title
          creatorUsername
          source
          mimeType
          fileSize
        }
      }
    }
  }
`;

export const useSetCollectionArtworks = (): ((
  createCollectionArtwork: setCollectionArtworkInput
) => any) => {
  const [createCollectionArtwork] = useMutation(CREATE_COLLECTION_ARTOWRK, {
    refetchQueries: [GET_COLLECTION_DETAILS, "GetCollectionDetails"],
    // update(cache, { data: { createCollection } }) {
    //   cache.modify({
    //     fields: {
    //       collections(existingCollections = []) {
    //         console.log(existingCollections, "eeeeeeeeee");
    //         console.log(createCollection, "ccccccccccc");
    //         const newCollectionRef = cache.writeFragment({
    //           data: createCollection,
    //           fragment: gql`
    //             fragment NewCollection on Collection {
    //               id
    //               interval
    //               name
    //               artworks
    //             }
    //           `,
    //         });
    //         console.log(newCollectionRef, "nnnnnnnnnnnn");
    //         return [...existingCollections, newCollectionRef];
    //       },
    //     },
    //   });
    // },
  });

  return createCollectionArtwork;
};

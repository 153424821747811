import { gql, useMutation } from "@apollo/client";

interface updateCollectionInput {
  variables: {
    input: {
      data: {
        id?: string;
        interval?: number;
        name?: string;
        transition?: string;
        playFullVideos?: boolean;
      };
      where: {
        id: number;
      };
    };
  };
}

export const UPDATE_COLLECTION = gql`
  mutation UpdateCollection($input: updateCollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
        interval
        name
        transition
        playFullVideos
      }
    }
  }
`;

export const useUpdateCollection = (): ((
  updateCollection: updateCollectionInput
) => any) => {
  const [updateCollection, { loading, error }] = useMutation(
    UPDATE_COLLECTION,
    {
      update(cache, { data: { updateCollection } }) {
        cache.modify({
          fields: {
            collections(existingCollections = []) {
              return updateCollection.collection;
              // const newCollectionRef = cache.writeFragment({
              //   data: updateCollection,
              //   fragment: gql`
              //     fragment UpdateCollection on Collection {
              //       id
              //       interval
              //     }
              //   `,
              // });
              // return [...existingCollections, newCollectionRef];
            },
          },
        });
      },
    }
  );

  return updateCollection;
};

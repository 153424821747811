import { gql, useMutation } from "@apollo/client";

interface deleteArtworkInput {
  variables: {
    input: {
      where: {
        id: string;
      };
    };
  };
}

const DELETE_ARTWORK = gql`
  mutation DeleteArtwork($input: deleteArtworkInput) {
    deleteArtwork(input: $input) {
      artwork {
        id
      }
    }
  }
`;

export const useDeleteArtwork = (): ((
  deleteArtwork: deleteArtworkInput
) => any) => {
  const [deleteArtwork, { loading: mutationLoading, error: mutationError }] =
    useMutation(DELETE_ARTWORK, {
      update(cache, { data: { deleteArtwork } }) {
        cache.modify({
          fields: {
            artworks(existingArtworks = [], { readField }) {
              return existingArtworks.filter(
                (artwork: any) =>
                  deleteArtwork.artwork.id !== readField("id", artwork)
              );
            },
          },
        });
      },
    });
  return deleteArtwork;
};

import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Torus from "@toruslabs/torus-embed";

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "INFURA_ID", // required
    },
  },
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "Web 3 Modal Demo", // Required
      infuraId: process.env.INFURA_KEY, // Required unless you provide a JSON RPC url; see `rpc` below
    },
  },
  // torus: {
  //   package: Torus, // required
  //   options: {
  //     networkParams: {
  //       host: "https://localhost:8545", // optional
  //       chainId: 1337, // optional
  //       networkId: 1337, // optional
  //     },
  //     config: {
  //       buildEnv: "development", // optional
  //     },
  //   },
  // },
};